import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import moment from "moment";
import { FETCH_PRODUCTION_CALENDAR_PLANS } from "./graphql";
import { parseError } from "apollo";

const ProductionPlans = ({ productionCalendarId, year }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_CALENDAR_PLANS, {
    variables: { id: productionCalendarId },
  });

  if (loading)
    return (
      <div className="relative p-20">
        <Spinner />
      </div>
    );
  if (error) return <Errors error={parseError(error)} />;

  const plans = data.productionCalendar.usages
    .filter((i) => i.date && i.productionPlan)
    .filter((i) => new Date(i.date).getFullYear() === parseInt(year))
    .filter((i) => i.productionPlan.state !== "CANCELED")
    .reduce((res, i) => {
      if (res[i.productionPlan.id]) {
        res[i.productionPlan.id].dates.push(i.date);
      } else {
        res[i.productionPlan.id] = {
          ...i.productionPlan,
          dates: [i.date],
        };
      }
      return res;
    }, {});

  const stateColor = (state) => {
    if (state === "PLANNING") return "text-teal-600";
    if (state === "HANDLED") return "text-gray-500";
    return "";
  };

  return (
    <div className="text-xs">
      {Object.values(plans)
        .sort((a, b) => new Date(b.joinQueueAt) - new Date(a.joinQueueAt))
        .map((plan, index) => (
          <div
            key={plan.id}
            className={`py-2
                ${index === 0 ? "" : "border-t dark:border-gray-700"}
                `}
          >
            <div className="flex justify-between">
              <div className="font-semibold">
                {plan.name}{" "}
                <span className={`lowercase ${stateColor(plan.state)}`}>
                  {plan.state}
                </span>
              </div>
            </div>
            <div className="mt-1 opacity-70">
              {[...plan.dates]
                .sort((a, b) => new Date(a) - new Date(b))
                .map((i) => moment(i).format("MM-DD"))
                .join(", ")}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProductionPlans;
