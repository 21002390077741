import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ProductImage from "./ProductImage";
import { Button } from "components/base";
import { Field, Input } from "components/Form.js";
import ShipmentContext from "../ShipmentContext";
import { GoPackage } from "react-icons/go";
import CharlesButton from "components/charles/base";
import ShipmentInvoiceRowQcReport from "./ShipmentInvoiceRowQcReports";
import { Alert } from "components/Toast";
import { CubeSizeInput } from "components/ItemSizeInput";

const DELETE_SHIPMENT_INVOICE_ROW = gql`
  mutation DELETE_SHIPMENT_INVOICE_ROW($id: ID!) {
    deleteShipmentInvoiceRow(id: $id) {
      id
    }
  }
`;

function ShipmentInvoiceRow({ shipmentInvoice, shipmentInvoiceId, row }) {
  const {
    state,
    dispatch,
    prepareShippingMark,
    changeShipmentInvoiceRowProduct,
  } = useContext(ShipmentContext);
  const [showProductEditableData, setShowProductEditableData] = useState(false);
  const [rowCbm, setRowCbm] = useState(row.newProductCbm);
  const [rowSpecialItemName, setRowSpecialItemName] = useState(
    row.product.productType === "special" ? row.product.name : "",
  );
  const [rowOuterCartonX, setRowOuterCartonX] = useState(
    parseFloat(row.product.outerCartonX) || 0,
  );
  const [rowOuterCartonY, setRowOuterCartonY] = useState(
    parseFloat(row.product.outerCartonY) || 0,
  );
  const [rowOuterCartonZ, setRowOuterCartonZ] = useState(
    parseFloat(row.product.outerCartonZ) || 0,
  );
  const [rowCustomName, setRowCustomName] = useState(row.product.customsName);
  const [rowCustomBrand, setRowCustomBrand] = useState(
    row.product.customsBrand,
  );
  const [rowCustomUsage, setRowCustomUsage] = useState(
    row.product.customsUsage,
  );
  const [rowCustomValue, setRowCustomValue] = useState(
    row.product.customsValue,
  );
  const [rowCustomSize, setRowCustomSize] = useState(row.product.customsSize);
  const [rowCustomMaterials, setRowCustomMaterials] = useState(
    row.product.customsMaterials,
  );

  const [deleteShipmentInvoiceRow, deleteShipmentInvoiceRowRes] = useMutation(
    DELETE_SHIPMENT_INVOICE_ROW,
    {
      variables: { id: state.id },
      onCompleted: () => {
        Alert("success", "Product deleted");
        dispatch({
          type: "deleteShipmentInvoiceRow",
          payload: { shipmentInvoiceId, shipmentInvoiceRowId: row.id },
        });
      },
    },
  );

  const onChangeShipmentInvoiceRow = (row, key, value) => {
    dispatch({
      type: "onChangeShipmentInvoiceRow",
      payload: { shipmentInvoiceId, shipmentInvoiceRowId: row.id, key, value },
    });
  };

  let itemsPerSet = 1;
  let validQty = true;
  if (row.product.productType === "normal") {
    itemsPerSet = row.product.itemsPerSet;
    validQty = row.qty % itemsPerSet === 0;
  } else if (row.product.productType === "special") {
    itemsPerSet = row.newProductQtyPerCarton; // for special item, use newProductQtyPerCarton
    validQty = row.qty % itemsPerSet === 0;
  }

  useEffect(() => {
    onChangeShipmentInvoiceRow(row, "specialItemName", rowSpecialItemName);
    onChangeShipmentInvoiceRow(row, "specialItemOuterCartonX", rowOuterCartonX);
    onChangeShipmentInvoiceRow(row, "specialItemOuterCartonY", rowOuterCartonY);
    onChangeShipmentInvoiceRow(row, "specialItemOuterCartonZ", rowOuterCartonZ);

    onChangeShipmentInvoiceRow(row, "specialItemCustomName", rowCustomName);
    onChangeShipmentInvoiceRow(row, "specialItemCustomBrand", rowCustomBrand);
    onChangeShipmentInvoiceRow(row, "specialItemCustomUsage", rowCustomUsage);
    onChangeShipmentInvoiceRow(row, "specialItemCustomValue", rowCustomValue);
    onChangeShipmentInvoiceRow(row, "specialItemCustomSize", rowCustomSize);
    onChangeShipmentInvoiceRow(
      row,
      "specialItemCustomMaterials",
      rowCustomMaterials,
    );
  }, []);

  return (
    <div
      className={`
         mx-4 lg:mx-6 border-b py-2 lg:py-4 border-gray-200 dark:border-gray-700 lg:whitespace-no-wrap relative transition-all duration-200
            ${showProductEditableData ? "rounded-lg px-4 lg:px-6 border-0 mt-6 bg-gray-100 dark:bg-gray-800" : ""}`}
    >
      <div className="flex justify-between items-start">
        <div className="lg:flex flex-1 items-center">
          {row.product.images.length > 0 && (
            <div className="flex mr-4 lg:mr-6">
              {row.product.images.map((image, index) => (
                <ProductImage key={index} src={image.url} />
              ))}
            </div>
          )}
          <div className="flex-1">
            <div className="flex items-center space-x-3">
              <div className="font-bold">
                #{row.id} [{row.product.number}] {row.product.name}
              </div>
              <Button
                leftIcon={<GoPackage size={18} />}
                onClick={() => prepareShippingMark(state.customer, row)}
              />
            </div>
            {row.product.productType === "normal" && (
              <div className="text-xs">{row.packingName}</div>
            )}
          </div>
        </div>

        <div className="flex lg:justify-end space-x-4">
          {row.product.productType === "normal" && (
            <CharlesButton
              onClick={() => changeShipmentInvoiceRowProduct(row.id)}
            >
              change
            </CharlesButton>
          )}

          <CharlesButton
            onClick={() => setShowProductEditableData((prev) => !prev)}
          >
            {showProductEditableData ? "done" : "edit"}
          </CharlesButton>

          {showProductEditableData ? null : (
            <CharlesButton
              danger
              loading={deleteShipmentInvoiceRowRes.loading}
              onClick={(_) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete this product?`,
                  )
                ) {
                  deleteShipmentInvoiceRow({ variables: { id: row.id } });
                }
              }}
            >
              delete
            </CharlesButton>
          )}
        </div>
      </div>

      <div
        className={`
          transition-all duration-200
          ${showProductEditableData ? "show mt-4" : "hidden"}
        `}
      >
        <div className="text-orange-600 font-semibold">
          Note that when you change the data here, it will only change the data
          for this shipment, not the product itself.
        </div>
        {row.product.productType === "special" && (
          <div className="flex items-center mt-2">
            <label>Special Item Name:</label>
            <Input
              className="ml-2 w-full"
              type="text"
              placeholder="Packing Name"
              value={rowSpecialItemName}
              onChange={(e) => {
                setRowSpecialItemName(e.target.value);
                onChangeShipmentInvoiceRow(
                  row,
                  "specialItemName",
                  e.target.value,
                );
              }}
            />
          </div>
        )}
        <div className="flex items-center mt-2">
          <label>Packing Name:</label>
          <Input
            className="ml-2 w-full"
            type="text"
            placeholder="Packing Name"
            value={row.packingName}
            onChange={(e) => {
              onChangeShipmentInvoiceRow(row, "packingName", e.target.value);
            }}
          />
        </div>
        <div className="lg:flex mt-4">
          <div className="flex items-center">
            <label>HS Code:</label>
            <Input
              className="text-center mr-3 ml-2"
              type="text"
              value={row.hsCode || ""}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(row, "hsCode", e.target.value)
              }
            />
          </div>

          <div className="flex items-center">
            <label>HS Code for Destination:</label>
            <Input
              className="text-center mr-3 ml-2"
              type="text"
              value={row.hsCodeForDestination || ""}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(
                  row,
                  "hsCodeForDestination",
                  e.target.value,
                )
              }
            />
          </div>

          <div className="flex items-center mt-4 lg:mt-0 lg:ml-4">
            <label className="whitespace-no-wrap">Item Number:</label>
            <Input
              className="text-center mr-3 ml-2"
              type="text"
              value={row.newProductItemNumber}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(
                  row,
                  "newProductItemNumber",
                  e.target.value,
                )
              }
            />
          </div>

          <div className="flex items-center mt-4 lg:mt-0 lg:ml-4">
            <label className="whitespace-no-wrap">Order No:</label>
            <Input
              className="text-center mr-3 ml-2"
              type="text"
              value={row.orderNo}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(row, "orderNo", e.target.value)
              }
            />
          </div>
        </div>

        <div className="lg:flex mt-4">
          <div className="flex items-center mr-4">
            <label>Qty/CTN:</label>
            <Input
              className="text-center ml-2 w-1/2"
              type="number"
              value={row.newProductQtyPerCarton}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(
                  row,
                  "newProductQtyPerCarton",
                  parseInt(e.target.value, 10),
                )
              }
            />
          </div>
          <div className="flex items-center mr-4">
            <label>N.W.:</label>
            <Input
              className="text-center ml-2 w-1/2"
              type="number"
              value={row.newProductNetWeight}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(
                  row,
                  "newProductNetWeight",
                  parseFloat(e.target.value),
                )
              }
            />
          </div>
          <div className="flex items-center mr-4">
            <label>G.W.:</label>
            <Input
              className="text-center ml-2 w-1/2"
              type="number"
              value={row.newProductGrossWeight}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(
                  row,
                  "newProductGrossWeight",
                  parseFloat(e.target.value),
                )
              }
            />
          </div>

          {row.product.productType === "special" && (
            <div className="flex items-center mr-4">
              <div className="mr-2">
                <label>Outer Carton Size: </label>
                <div className="text-xs text-gray-600">
                  Size 长(x) x 宽(z) x 高(y):
                </div>
              </div>

              <CubeSizeInput
                x={rowOuterCartonX}
                y={rowOuterCartonY}
                z={rowOuterCartonZ}
                unit="cm"
                onChange={({ x, y, z }) => {
                  setRowOuterCartonX(parseFloat(x));
                  setRowOuterCartonY(parseFloat(y));
                  setRowOuterCartonZ(parseFloat(z));
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemOuterCartonX",
                    parseFloat(x),
                  );
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemOuterCartonY",
                    parseFloat(y),
                  );
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemOuterCartonZ",
                    parseFloat(z),
                  );

                  const calculatedCbm = (
                    ((parseFloat(x) + 0.5) *
                      (parseFloat(y) + 0.5) *
                      (parseFloat(z) + 0.5)) /
                    (100 * 100 * 100)
                  ).toFixed(3);

                  setRowCbm(calculatedCbm);
                  onChangeShipmentInvoiceRow(
                    row,
                    "newProductCbm",
                    parseFloat(calculatedCbm),
                  );
                }}
              />
            </div>
          )}

          <div className="flex items-center ml-4">
            <label>CBM: </label>
            <Input
              className="text-center ml-2 w-1/2"
              type="number"
              value={rowCbm}
              onChange={(e) => {
                setRowCbm(parseFloat(e.target.value));
                onChangeShipmentInvoiceRow(
                  row,
                  "newProductCbm",
                  parseFloat(e.target.value),
                );
              }}
            />
          </div>
        </div>

        {row.product.productType === "special" && (
          <section className="space-y-4 border-t border-b border-gray-300 border-dashed my-6 py-4">
            <p className=" font-bold">Special Item Custom Info</p>

            <div className="flex space-x-4">
              <Field
                label="Name"
                value={rowCustomName}
                onChange={(customName) => {
                  setRowCustomName(customName);
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemCustomName",
                    customName,
                  );
                }}
              />
              <Field
                label="Brand"
                value={rowCustomBrand}
                onChange={(customBrand) => {
                  setRowCustomBrand(customBrand);
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemCustomBrand",
                    customBrand,
                  );
                }}
              />

              <Field
                label="Usage"
                value={rowCustomUsage}
                onChange={(customUsage) => {
                  setRowCustomUsage(customUsage);
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemCustomUsage",
                    customUsage,
                  );
                }}
              />
              <Field
                label="Value"
                value={rowCustomValue}
                onChange={(customValue) => {
                  setRowCustomValue(customValue);
                  onChangeShipmentInvoiceRow(
                    row,
                    "specialItemCustomValue",
                    customValue,
                  );
                }}
              />
            </div>

            <Field
              label="Size"
              value={rowCustomSize}
              onChange={(customSize) => {
                setRowCustomSize(customSize);
                onChangeShipmentInvoiceRow(
                  row,
                  "specialItemCustomSize",
                  customSize,
                );
              }}
            />

            <Field
              label="Material"
              value={rowCustomMaterials}
              onChange={(customMaterials) => {
                setRowCustomMaterials(customMaterials);
                onChangeShipmentInvoiceRow(
                  row,
                  "specialItemCustomMaterials",
                  customMaterials,
                );
              }}
            />
          </section>
        )}
      </div>

      <div className="mt-2 lg:flex flex-1 items-center justify-between">
        <div className="w-48">
          <div className="flex items-center justify-center">
            <label htmlFor="">Qty: </label>
            <Input
              className="text-center w-full ml-2"
              type="number"
              value={row.qty}
              onChange={(e) =>
                onChangeShipmentInvoiceRow(
                  row,
                  "qty",
                  parseInt(e.target.value, 10) || 0,
                )
              }
            />
          </div>
        </div>
        <div className="flex flex-1 mt-2 lg:mt-0">
          <div className="flex-1 lg:text-right">
            <label htmlFor="">CTN</label>: {row.totalCarton}
          </div>
          <div className="flex-1 text-right">
            <label htmlFor="">N.W.</label>:{" "}
            {parseFloat(row.totalNetWeight).toFixed(2)}
          </div>
          <div className="flex-1 text-right">
            <label htmlFor="">G.W.</label>:{" "}
            {parseFloat(row.totalGrossWeight).toFixed(2)}
          </div>
          <div className="flex-1 text-right">
            <label htmlFor="">CBM</label>: {parseFloat(row.totalCbm).toFixed(3)}
          </div>
        </div>
      </div>

      {validQty ? null : (
        <div className="mt-2 text-red-600 font-semibold">
          This product has {itemsPerSet} items / set, {row.qty} is not a valid
          number for whole package. Please make sure the qty is correct.
        </div>
      )}

      <div className="mt-4 flex items-center space-x-4">
        <ShipmentInvoiceRowQcReport
          row={row}
          invoiceReports={shipmentInvoice.invoice.qcReports}
        />
      </div>
    </div>
  );
}

export default ShipmentInvoiceRow;
