import { gql } from "@apollo/client";

export const INC_PRODUCT_CATEGORIES = gql`
  query INC_PRODUCT_CATEGORIES {
    incProductCategories {
      id
      name
      year
      warehouse
    }
  }
`;

export const FETCH_COMPARE_SALES_FORECAST = gql`
  query FETCH_COMPARE_SALES_FORECAST($groupBy: String!, $forecast: Boolean) {
    incCompareSalesAndForecast(groupBy: $groupBy, forecast: $forecast)
  }
`;

export const INC_INV_OVERVIEW = gql`
  query INC_INV_OVERVIEW($categoryId: ID) {
    incInventoryOverview(categoryId: $categoryId)
  }
`;

export const INC_INVENTORY_ITEMS = gql`
  query INC_INVENTORY_ITEMS(
    $itemType: String!
    $startDate: String!
    $endDate: String!
  ) {
    incInventoryItems(
      itemType: $itemType
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      product {
        id
        sku
      }
      date
      qty
      remark
    }
  }
`;

export const INC_INVENTORY_ITEM = gql`
  query INC_INVENTORY_ITEM(
    $warehouse: String!
    $sku: String!
    $year: Int!
    $month: Int!
    $itemType: String!
  ) {
    incInventoryItemBySkuAndMonth(
      warehouse: $warehouse
      sku: $sku
      year: $year
      month: $month
      itemType: $itemType
    ) {
      id
      qty
      remark
    }
  }
`;

export const INC_ITEM_SHIPMENTS = gql`
  query INC_ITEM_SHIPMENTS($sku: String!) {
    incItemShipments(sku: $sku)
    incFsiInventories
  }
`;

export const INC_SHIPMENT = gql`
  query INC_SHIPMENT($id: ID!) {
    shipment(id: $id) {
      id
      containerNumber
      loadingPort
      portOfDischarge
      portOfDestination
      shipmentInvoices {
        id
        invoice {
          id
          number
          pos {
            id
            number
          }
        }
        shipmentInvoiceRows {
          id
          product {
            id
            number
            name
          }
          qty
        }
      }
    }
  }
`;

export const INC_SHIPMENTS_ITEMS = gql`
  query INC_SHIPMENTS_ITEMS {
    incShipmentsItems
  }
`;

export const INC_SAVE_INVENTORY_ITEMS = gql`
  mutation INC_SAVE_INVENTORY_ITEMS(
    $warehouse: String!
    $date: Date!
    $items: [IncActualSalesItemInputType]!
    $type: String!
  ) {
    incSaveInventoryItems(
      warehouse: $warehouse
      date: $date
      items: $items
      type: $type
    ) {
      success
    }
  }
`;

export const CREATE_OR_UPDATE_INC_RECEIVED_SHIPMENTS = gql`
  mutation CREATE_OR_UPDATE_INC_RECEIVED_SHIPMENTS(
    $receivedShipmentList: [IncReceivedShipmentInputType]
  ) {
    createOrUpdateIncReceivedShipments(
      receivedShipmentList: $receivedShipmentList
    ) {
      success
    }
  }
`;

export const INC_SAVE_INVENTORY_ITEM = gql`
  mutation INC_SAVE_INVENTORY_ITEM(
    $warehouse: String!
    $sku: String!
    $date: Date!
    $itemType: String!
    $qty: Int
    $remark: String
  ) {
    incSaveInventoryItem(
      warehouse: $warehouse
      sku: $sku
      date: $date
      itemType: $itemType
      qty: $qty
      remark: $remark
    ) {
      inventoryItem {
        id
      }
    }
  }
`;
