import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Button } from "components/base";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import http from "utils/http";
import { FETCH_DOC } from "./graphql";
import Page from "components/Page";

function PackingListPreview() {
  const { id } = useParams();
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const { loading, error, data } = useQuery(FETCH_DOC, { variables: { id } });

  const exportPdf = (id, filename) => {
    setDownloadingPdf(true);
    http
      .get(`/doc/${id}/`, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingPdf(false);
      })
      .catch((err) => {
        alert(err);
        setDownloadingPdf(false);
      });
  };

  const exportExcel = (id, filename) => {
    setDownloadingExcel(true);
    http
      .get(`/doc/${id}/?excel=1`, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingExcel(false);
      })
      .catch((err) => {
        alert(err);
        setDownloadingExcel(false);
      });
  };

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  const { shipment, html } = data.document;
  return (
    <Page
      title={`#${shipment.invoiceNumber} - ${shipment.customer.name}`}
      backTo="/shipment/doc/packinglist"
      rightButtons={
        <div className="flex space-x-4">
          <Button
            title="Edit Shipment"
            link={`/shipment/shipments/${shipment.id}`}
          />
          <Button
            title={downloadingPdf ? "Exporting..." : "Export PDF"}
            disabled={downloadingPdf}
            loading={downloadingPdf}
            onClick={(_) =>
              exportPdf(id, `PackingList #${shipment.invoiceNumber}.pdf`)
            }
          />
          <Button
            title={downloadingExcel ? "Exporting..." : "Export Excel"}
            disabled={downloadingExcel}
            loading={downloadingExcel}
            onClick={(_) =>
              exportExcel(id, `PackingList #${shipment.invoiceNumber}.xlsx`)
            }
          />
        </div>
      }
    >
      <div className="flex flex-col flex-1">
        <iframe
          title="PackingLists Preview"
          className="flex-1 w-full bg-white overflow-auto"
          srcDoc={html}
          frameBorder="0"
        />
      </div>
    </Page>
  );
}

export default PackingListPreview;
