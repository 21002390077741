import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  useContext,
} from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar.js";
import { Input } from "components/Form.js";
import convertUnits from "utils/converter";
import { searchByProp } from "utils/search.js";
import Summary from "./Summary";
import { AppContext } from "App";

export const PALLET_NUMBER = 17.28;

const FETCH_PRODUCTS_FOR_CALCULATOR = gql`
  query fetchCalculateProducts {
    products: allProducts(
      productType: "normal"
      isActive: true
      isSalable: true
    ) {
      id
      name
      number
      hsCode
      packing
      outerCartonX
      outerCartonY
      outerCartonZ
      quantityPerCarton
      ctnGrossWeight
      outerCartonCbm
    }
  }
`;

const cols = [
  "Quantity",
  {
    field: "name",
    displayName: "Product",
  },
  {
    field: "number",
    displayName: "Item Number",
  },
  {
    field: "hsCode",
    displayName: "HS Code",
  },
  {
    field: "packing",
    displayName: "Packing",
  },
  {
    field: "outerCartonSize",
    displayName: "Outer Carton Size",
    value: (product, metric) =>
      convertUnits(
        [product.outerCartonX, product.outerCartonZ, product.outerCartonY],
        "cm",
        "in",
        metric,
      ),
  },
  {
    field: "quantityPerCarton",
    displayName: "Quantity Per Carton",
  },
  {
    field: "ctnGrossWeight",
    displayName: "CTN Gross Weight",
    value: (product, metric) =>
      convertUnits([product.ctnGrossWeight], "kg", "lb", metric),
  },
  {
    field: "outerCartonCbm",
    displayName: "Outer Carton CBM",
    value: (product, metric) =>
      convertUnits([product.outerCartonCbm], "m3", "ft3", metric),
  },
];

const summaryWidth = window.innerWidth / 3;

function calcCtn(product, qty) {
  return Math.ceil(parseInt(qty, 10) / product.quantityPerCarton);
}

function calcGw(product, qty) {
  return (
    Math.round(calcCtn(product, qty) * product.ctnGrossWeight * 1000) / 1000
  );
}

function calcCbm(product, qty) {
  return (
    Math.round(calcCtn(product, qty) * product.outerCartonCbm * 1000) / 1000
  );
}

function calcPallet(product, qty) {
  return Math.ceil(calcCbm(product, qty) / PALLET_NUMBER);
}

function calculateTotal(calculatingProducts) {
  const totalQty = calculatingProducts.reduce(
    (total, item) => (total += item.qty),
    0,
  );
  const totalCtn = calculatingProducts.reduce(
    (total, item) => (total += item.ctn),
    0,
  );
  const totalGw =
    Math.round(
      calculatingProducts.reduce((total, item) => (total += item.gw), 0) * 1000,
    ) / 1000;
  const totalCbm =
    Math.round(
      calculatingProducts.reduce((total, item) => (total += item.cbm), 0) *
        1000,
    ) / 1000;
  const totalPallet = calculatingProducts.reduce(
    (total, item) => (total += item.pallet),
    0,
  );
  return { totalQty, totalCtn, totalGw, totalCbm, totalPallet };
}

function ProductsTable({
  products,
  calculatingProducts,
  onChangeQty,
  metric,
  setSearchText,
  refs = {},
}) {
  return (
    <table className="sticky-table">
      <thead className="font-bold">
        <tr>
          {cols.map((i, index) => (
            <th
              key={index}
              className={`sticky whitespace-nowrap ${
                index === 0 ? "left-0 z-20" : ""
              } top-0 px-6 py-2 bg-gray-200 dark:bg-gray-800 whitespace-no-wrap`}
            >
              {i === "Quantity" ? (
                "Quantity"
              ) : i.field === "name" ? (
                <div className="flex items-center flex-1">
                  <span>{i.displayName}</span>
                  <div className="ml-4 flex-1">
                    <SearchBar
                      className="py-1"
                      onChange={setSearchText}
                      placeholder="Search by name, number"
                    />
                  </div>
                </div>
              ) : (
                i.displayName
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => {
          let qty = "";
          const calculatingProduct = calculatingProducts.find(
            (p) => p.product.id === product.id,
          );
          if (calculatingProduct) {
            qty = calculatingProduct.qty;
          }
          return (
            <tr
              ref={refs[product.id]}
              key={index}
              className="hover:bg-blue-200 dark:hover:bg-blue-800"
            >
              {cols.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className={`
                              ${colIndex === 0 ? "sticky left-0 z-10" : ""}
                              ${
                                calculatingProduct
                                  ? "bg-orange-400 dark:bg-orange-800"
                                  : "bg-white dark:bg-gray-900"
                              }
                              ${
                                col !== "Quantity" &&
                                [
                                  "quantityPerCarton",
                                  "ctnGrossWeight",
                                  "outerCartonCbm",
                                ].includes(col.field)
                                  ? "text-right"
                                  : ""
                              }
                              whitespace-nowrap border-gray-100 px-6
                           `}
                >
                  {col === "Quantity" ? (
                    <Input
                      style={{ width: 120 }}
                      className="text-center py-1"
                      type="number"
                      value={qty}
                      min={0}
                      onChange={(e) => onChangeQty(product, e)}
                    />
                  ) : col.value ? (
                    col.value(product, metric)
                  ) : (
                    product[col.field]
                  )}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function Calculator({ initialItem = null }) {
  const {
    settings: { metric },
  } = useContext(AppContext);
  const [searchText, setSearchText] = useState("");
  const [calculatingProducts, setCalculatingProducts] = useState(
    initialItem ? initialItem.rows : [],
  );
  const [totalQty, setTotalQty] = useState(
    initialItem ? initialItem.totalQty : 0,
  );
  const [totalCtn, setTotalCtn] = useState(
    initialItem ? initialItem.totalCtn : 0,
  );
  const [totalGw, setTotalGw] = useState(initialItem ? initialItem.totalGw : 0);
  const [totalCbm, setTotalCbm] = useState(
    initialItem ? initialItem.totalCbm : 0,
  );
  const [totalPallet, setTotalPallet] = useState(
    initialItem ? initialItem.totalPallet : 0,
  );
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_CALCULATOR);
  const [refs, setRefs] = useState(useRef({}));

  useEffect(() => {
    if (data && data.products) {
      setRefs(
        data.products.reduce((acc, product) => {
          acc[product.id] = createRef();
          return acc;
        }, {}),
      );
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (!data) return null;

  let { products } = data;

  function onChangeQty(product, e) {
    let qty = parseInt(e.target.value, 10);
    if (isNaN(qty)) qty = 0;
    const calculatingProduct = calculatingProducts.find(
      (i) => i.product.id === product.id,
    );
    let latestCalculatingProducts = [...calculatingProducts];
    if (calculatingProduct) {
      if (qty > 0) {
        latestCalculatingProducts = calculatingProducts.map((i) =>
          i.product.id === product.id
            ? {
                ...i,
                qty,
                ctn: calcCtn(product, qty),
                gw: calcGw(product, qty),
                cbm: calcCbm(product, qty),
                pallet: calcPallet(product, qty),
              }
            : i,
        );
      } else {
        latestCalculatingProducts = calculatingProducts.filter(
          (i) => i.product.id !== product.id,
        );
      }
    } else {
      if (qty > 0) {
        latestCalculatingProducts = [
          ...calculatingProducts,
          {
            product,
            qty,
            ctn: calcCtn(product, qty),
            gw: calcGw(product, qty),
            cbm: calcCbm(product, qty),
            pallet: calcPallet(product, qty),
          },
        ];
      }
    }
    const { totalQty, totalCtn, totalGw, totalCbm, totalPallet } =
      calculateTotal(latestCalculatingProducts);
    setCalculatingProducts(latestCalculatingProducts);
    setTotalQty(totalQty);
    setTotalCtn(totalCtn);
    setTotalGw(totalGw);
    setTotalCbm(totalCbm);
    setTotalPallet(totalPallet);
  }

  let isSearching = searchText !== "";

  if (isSearching) {
    products = products.filter(
      (product) =>
        calculatingProducts.filter((i) => i.product.id === product.id).length >
          0 || searchByProp(product, ["name", "number"], searchText),
    );
  }

  return (
    <div className="flex w-full overflow-auto z-20">
      <div
        style={{
          paddingRight: calculatingProducts.length > -0 ? summaryWidth : 0,
        }}
      >
        <ProductsTable
          products={products}
          calculatingProducts={calculatingProducts}
          onChangeQty={onChangeQty}
          metric={metric}
          searchText={searchText}
          setSearchText={setSearchText}
          refs={refs}
        />
      </div>

      <Summary
        initialItem={initialItem}
        metric={metric}
        calculatingProducts={calculatingProducts}
        onClickProduct={(id) => {
          if (refs[id].current) {
            refs[id].current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }}
        totalQty={totalQty}
        totalCtn={totalCtn}
        totalGw={totalGw}
        totalCbm={totalCbm}
        totalPallet={totalPallet}
        className={`fixed bottom-0 bg-white dark:bg-gray-800 shadow-lg z-40 top-0 overflow-auto`}
        style={{
          width: summaryWidth,
          minWidth: summaryWidth,
          transition: "all 0.65s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
          right: calculatingProducts.length > 0 ? 0 : -summaryWidth,
          // top: 41,
        }}
        onClear={(_) => setCalculatingProducts([])}
      />
    </div>
  );
}

export default Calculator;
