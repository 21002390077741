import { gql, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { FETCH_PRODUCT_FORECASTED_SALES } from "./graphql";
// import ForecastRemarkView from "./ForecastRemarkView";

const ProductForecastView = ({ productId, year, month, onSelectNumber }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_FORECASTED_SALES, {
    variables: { productIds: [productId], year, month },
  });

  if (loading)
    return (
      <div>
        <InlineSpinner size={16} text={null} />
      </div>
    );

  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.incForecastSales);

  return (
    <div className="relative z-50 text-left space-y-2">
      <h5 className="px-2">Sales Forecast</h5>
      <table>
        <tbody>
          <tr className="border-y dark:border-gray-500">
            <td>Same month last year</td>
            <td className="text-right">
              {res.sales_same_month_last_year ? (
                <div className="flex justify-end">
                  <CharlesButton
                    onClick={() =>
                      onSelectNumber(res.sales_same_month_last_year)
                    }
                  >
                    {res.sales_same_month_last_year}
                  </CharlesButton>
                </div>
              ) : (
                "-"
              )}
            </td>
          </tr>
          <tr className="border-y dark:border-gray-500">
            <td>3 months ave last year</td>
            <td className="text-right">
              {res.ave_sales_last_year_3_months ? (
                <div className="flex justify-end">
                  <CharlesButton
                    onClick={() =>
                      onSelectNumber(res.ave_sales_last_year_3_months)
                    }
                  >
                    {res.ave_sales_last_year_3_months}
                  </CharlesButton>
                </div>
              ) : (
                "-"
              )}
            </td>
          </tr>
          <tr className="border-y dark:border-gray-500">
            <td>Ave last 12 months</td>
            <td className="text-right">
              {res.avg_sales_last_12_months ? (
                <div className="flex justify-end">
                  <CharlesButton
                    onClick={() => onSelectNumber(res.avg_sales_last_12_months)}
                  >
                    {res.avg_sales_last_12_months}
                  </CharlesButton>
                </div>
              ) : (
                "-"
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="px-2 opacity-70 text-xs whitespace-normal">
        Click on the number to use it as the forecasted sales.
      </div>

      {/* <div>
        <ForecastRemarkView
          warehouse={warehouse}
          sku={sku}
          year={year}
          month={month}
        />
      </div> */}
    </div>
  );
};

export default ProductForecastView;
