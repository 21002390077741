import { useMutation, useQuery } from "@apollo/client";
import { useModals } from "ModalProvider";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import CharlesButton from "components/charles/base";
import { useContext, useState } from "react";
import {
  FETCH_ALL_PACKAGES,
  CREATE_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  FETCH_ALL_PACKAGE_ADDRESSES,
} from "./graphql";
import { Alert } from "components/Toast";
import { formatDate } from "react-day-picker/moment";
import { FaCheckCircle } from "react-icons/fa";
import Status, { PackageStatusChain } from "components/Status";
import { Input, Select } from "components/Form";
import { AppContext } from "App";
import { Link, useNavigate } from "react-router-dom";
import ImagesPreview from "pages/materials/beautyshots/ImagesPreview";
import { BsPencil, BsTrash } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";

const isPackageRelatedToMe = (pkg, user) =>
  pkg.items?.some((item) => item.cc?.some((cc) => cc.id === user.id)) ||
  pkg.checkItems?.some((checkItem) =>
    checkItem.senders?.some((sender) => sender.id === user.id),
  ) ||
  pkg.checkItems?.some((checkItem) =>
    checkItem.receivers?.some((receiver) => receiver.id === user.id),
  );

const PackageList = () => {
  const previewModal = useModals();
  const editModal = useModals();
  const { loading, error, data } = useQuery(FETCH_ALL_PACKAGES);
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="whitespace-nowrap">
      <table>
        <thead>
          <tr className=" sticky top-0 bg-gray-200 dark:bg-gray-800 z-20">
            <th className="px-6 py-3">Package</th>
            <th className="px-6">Images</th>
            <th className="px-6">Destination</th>
            <th className="px-6">Shipment Date</th>
            <th className="px-6">Tracking No.</th>
            <th className="px-6">Courier</th>
            <th className="px-6">Matched</th>
            <th className="px-6">Status</th>
          </tr>
        </thead>
        <tbody>
          {data.allPackages.map((pkg, index) => (
            <tr
              key={index}
              className="border-y border-gray-100 dark:border-gray-700 cursor-pointer hover:bg-sky-50 dark:hover:bg-gray-900"
              onClick={(e) => {
                console.log("e.target.tagName", e.target.tagName);
                if (e.target.tagName !== "A" && e.target.tagName !== "BUTTON") {
                  navigate(`/tools/package/${pkg.id}`);
                }
              }}
            >
              <td className="px-6">
                <div className="flex items-center space-x-4">
                  <Link to={`/tools/package/${pkg.id}`}>
                    {isPackageRelatedToMe(pkg, user) ? (
                      <span className="bg-yellow-200 bg-opacity-50 rounded py-1">
                        Package #{pkg.id}
                      </span>
                    ) : (
                      <span>Package #{pkg.id}</span>
                    )}
                  </Link>
                  <div className="flex justify-between">
                    <div className="flex space-x-2">
                      {pkg.status !== "DELIVERED" && (
                        <CharlesButton
                          data-testid="package-edit-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            editModal.present({
                              title: "Edit Package",
                              center: true,
                              children: (
                                <PackageForm
                                  hide={editModal.hide}
                                  pkg={pkg}
                                  action={"update"}
                                />
                              ),
                              isBeingPresented: true,
                            });
                          }}
                        >
                          <BsPencil />
                        </CharlesButton>
                      )}
                      {pkg.items.length === 0 && (
                        <CharlesButton
                          data-testid="package-delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            editModal.present({
                              title: "Delete Package",
                              center: true,
                              children: (
                                <PackageForm
                                  hide={editModal.hide}
                                  pkg={pkg}
                                  action={"delete"}
                                />
                              ),
                              isBeingPresented: true,
                            });
                          }}
                          danger
                        >
                          <BsTrash />
                        </CharlesButton>
                      )}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 w-full">
                <div className="flex items-center gap-2 flex-shrink-0">
                  {pkg.images?.length > 0 &&
                    pkg.images.map((image, index) => (
                      <img
                        key={index}
                        src={image.url + "?imageView2/2/w/300"}
                        alt={image.name}
                        className="w-8 h-8 xl:w-12 xl:h-12 cursor-pointer rounded-xl flex-shrink-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          previewModal.present({
                            title: image.name,
                            fullscreen: true,
                            children: (
                              <ImagesPreview
                                images={pkg.images}
                                initialIndex={index}
                              />
                            ),
                          });
                        }}
                      />
                    ))}
                </div>
              </td>
              <td className="px-6">{pkg.destination.shortName}</td>
              <td className="px-6">
                {formatDate(pkg.createdAt, "YYYY-MM-DD")}
              </td>
              <td className="px-6">
                <div className="flex items-center space-x-2">
                  <a
                    href={
                      pkg.method === "FEDEX"
                        ? `https://www.fedex.com/fedextrack/?trknbr=${pkg.trackingNumber}`
                        : pkg.method === "DHL"
                          ? `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${pkg.trackingNumber}`
                          : pkg.method === "UPS"
                            ? `https://www.ups.com/track?tracknum=${pkg.trackingNumber}`
                            : null
                    }
                    target="_blank"
                    className="flex items-center space-x-1"
                  >
                    {pkg.trackingNumber}
                  </a>
                </div>
              </td>
              <td className="px-6">{pkg.method}</td>
              <td className="px-6">
                <FaCheckCircle
                  className={`text-base ${pkg.isMatched ? "text-green-500" : "text-gray-300"}`}
                />
              </td>
              <td className="px-6">
                <Status status={pkg.status} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PackageForm = ({ hide, pkg, action }) => {
  const packageId = pkg ? pkg.id : "";
  const [destinationId, setDestinationId] = useState(
    pkg ? pkg.destination.id : "",
  );
  const [method, setMethod] = useState(pkg ? pkg.method.toLowerCase() : "");
  const [trackingNumber, setTrackingNumber] = useState(
    pkg ? pkg.trackingNumber : "",
  );

  const { loading, error, data } = useQuery(FETCH_ALL_PACKAGE_ADDRESSES);

  const [createPackage] = useMutation(CREATE_PACKAGE, {
    variables: { destinationId, method, trackingNumber },
    refetchQueries: [{ query: FETCH_ALL_PACKAGES }],
    onCompleted: (data) => {
      hide();
      Alert(
        "success",
        "The package #" + data.createPackage.package.id + " has been created.",
      );
    },
  });

  const [updatePackage] = useMutation(UPDATE_PACKAGE, {
    variables: { id: packageId, destinationId, method, trackingNumber },
    refetchQueries: [{ query: FETCH_ALL_PACKAGES }],
    onCompleted: () => {
      hide();
      Alert("success", "The package #" + packageId + " has been updated.");
    },
  });

  const [deletePackage] = useMutation(DELETE_PACKAGE, {
    variables: { id: packageId },
    refetchQueries: [{ query: FETCH_ALL_PACKAGES }],
    onCompleted: () => {
      hide();
      Alert("success", "The package #" + packageId + " has been deleted.");
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const handleSubmit = () => {
    if (!destinationId || !method || !trackingNumber) {
      Alert("error", "Please fill in all fields.");
      return;
    }
    if (packageId) {
      updatePackage();
    } else {
      createPackage();
    }
  };

  return (
    <div data-testid="package-form">
      {action === "delete" ? (
        <>
          <p className="opacity-80 mb-4">Delete this package?</p>
          <div className="text-sm space-y-2 card bg-gray-100">
            <div className="flex">
              <label className="w-40">Package: </label># {pkg.id}
            </div>
            <div className="flex">
              <label className="w-40">Destination: </label>
              {pkg.destination.addressDetail}
            </div>
            <div className="flex">
              <label className="w-40">Status: </label>
              {pkg.status}
            </div>
            <div className="flex">
              <label className="w-40">Tracking Number: </label>
              {pkg.trackingNumber}
            </div>
            <div className="flex">
              <label className="w-40">Courier: </label>
              {pkg.method}
            </div>
            <div className="flex">
              <label className="w-40">Shipment Date: </label>
              {formatDate(pkg.createdAt, "YYYY-MM-DD")}
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="opacity-80 mb-4">
            WIS will check the tracking number is matched with the courier.
          </p>

          <div className="text-sm space-y-2 card bg-gray-100">
            {packageId ? (
              <div className="flex">
                <label className="w-40">Package: </label># {pkg.id}
              </div>
            ) : null}
            <div className="flex items-center">
              <label className="w-40">Destination: </label>
              <Select
                data-testid="package-destination"
                value={destinationId}
                onChange={(e) => setDestinationId(e.target.value)}
              >
                <option value="">Select a destination</option>
                {data.allPackageAddresses.map((pkgAddress, index) => (
                  <option key={index} value={pkgAddress.id}>
                    {pkgAddress.shortName}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex items-center">
              <label className="w-40">Tracking Number: </label>
              <Input
                type="text"
                value={trackingNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/\s/.test(value)) {
                    Alert(
                      "error",
                      "Tracking number should not contain spaces!",
                    );
                  } else {
                    setTrackingNumber(value);
                  }
                }}
              />
            </div>
            <div className="flex items-center">
              <label className="w-40">Courier: </label>
              <Select
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                className=" rounded-sm"
              >
                <option value="">Select a courier</option>
                <option value="fedex">FedEx</option>
                <option value="dhl">DHL</option>
                <option value="ups">UPS</option>
              </Select>
            </div>
          </div>
        </>
      )}

      <div className="flex space-x-4 mt-4">
        {action === "delete" ? (
          <CharlesButton onClick={deletePackage} danger={true}>
            Delete
          </CharlesButton>
        ) : (
          <CharlesButton onClick={handleSubmit}>
            {pkg ? "Update" : "Create"}
          </CharlesButton>
        )}
        <CharlesButton
          onClick={hide}
          className=" text-gray-500 hover:text-gray-800"
        >
          Cancel
        </CharlesButton>
      </div>
    </div>
  );
};

const PackageTracking = () => {
  const { present, hide } = useModals();

  return (
    <Page
      title="Package Tracking"
      className="h-screen"
      leftButtons={
        <CharlesButton
          data-testid="create-package-btn"
          onClick={() =>
            present({
              title: "Create Package",
              center: true,
              children: <PackageForm hide={hide} action={"create"} />,
              isBeingPresented: true,
            })
          }
        >
          + Create Package
        </CharlesButton>
      }
    >
      <div className="p-6 flex flex-col flex-1 overflow-auto">
        <div
          data-testid="package-list"
          className="card p-0 flex-1 overflow-auto"
        >
          <PackageList />
        </div>

        <div
          data-testid="package-status-note"
          className="mt-6 opacity-70 text-xs space-y-2"
        >
          <p>A package will go through the following statuses:</p>
          <PackageStatusChain status={"DELIVERED"} />
          <div className="space-y-1">
            <div className="flex">
              <p className="w-20">Shipped: </p>
              <p>
                Now the package is on the way to the destination. You can add,
                edit, delete the sample information.
              </p>
            </div>
            <div className="flex">
              <p className="w-20">Delivered: </p>
              <p>
                Now the package has been delivered. WIS will send you a
                notification and you cannot change anything now.
              </p>
            </div>
          </div>
          <div className="font-bold">
            This highlight{" "}
            <span className="bg-yellow-200 bg-opacity-50 rounded p-1">#</span>{" "}
            means the package is related to you!
          </div>
        </div>
      </div>
    </Page>
  );
};

export default PackageTracking;
