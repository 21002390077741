import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import PreviewQCReport from "components/PreviewQCReport";
import { ProductImagesV2 } from "components/ProductImages";
import QcStatus from "components/QcStatus";
import { InlineSpinner } from "components/Spinner";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import moment from "moment";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { timeSince } from "utils/date";
import { DELETE_QC_REPORT } from "./graphql";

const FETCH_RECENT_QC_REPORTS = gql`
  query FETCH_RECENT_QC_REPORTS {
    qcReports: allQcReports(limit: 60) {
      id
      product {
        id
        number
        name
        images: computedImages(size: "300x300") {
          id
          url
        }
      }
      qty
      factory {
        id
        name
      }
      status
      createdAt
      deletedAt
      sharedWithCustomerAt
    }
  }
`;

const RecentQCReports = () => {
  const { loading, error, data } = useQuery(FETCH_RECENT_QC_REPORTS);
  const modal = useModals();

  if (loading) return <InlineSpinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-2xl">
      <h4>Recent Final Reports</h4>
      <div className="mt-2">
        {data.qcReports
          .filter((i) => !i.deletedAt)
          .map((report) => (
            <div key={report.id} className="border-b dark:border-gray-700 py-2">
              <div className="float-right pl-2 pt-2">
                <QcStatus status={report.status} />
              </div>
              <div className="flex flex-wrap mt-2">
                <ProductImagesV2 images={report.product.images} size="w-8" />
              </div>

              <div className="mt-1">
                {report.product.name} &times; {report.qty}
              </div>

              {report.sharedWithCustomerAt ? (
                <div className="mt-2 text-purple-500 flex items-center space-x-2">
                  <label>Shared with Customer</label>
                  <IoMdCheckmarkCircle size={21} />
                </div>
              ) : null}

              <div className="flex mt-1 justify-between items-baseline">
                <div className="opacity-60">
                  {timeSince(moment.utc(report.createdAt))} Ago
                </div>

                <div className="flex space-x-4 text-sm">
                  <DeleteButton id={report.id} />
                  <Button title="编辑" link={`/qc/final/${report.id}`} />
                  <Button
                    title="预览"
                    onClick={() =>
                      modal.present({
                        fullscreen: true,
                        children: <PreviewQCReport id={report.id} />,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const DeleteButton = ({ id }) => {
  const [deleteReport, deleteReportRes] = useMutation(DELETE_QC_REPORT, {
    variables: { id },
    onCompleted() {
      Alert("success", "Report deleted.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  return (
    <Button
      loading={deleteReportRes.loading}
      disabled={deleteReportRes.loading}
      title="删除"
      color="red"
      onClick={() => {
        if (window.confirm(`Are you sure to delete this qc report?`))
          deleteReport();
      }}
    />
  );
};

export default RecentQCReports;
