import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Select } from "components/Form";
import { INC_FC_IMPORT_WAREHOUSE_PRODUCTS } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { useState } from "react";

const ImportFromWarehouseView = ({ forecastDocId, onImportedProducts }) => {
  const [warehouse, setWarehouse] = useState("AWD");
  const [confirm, confirmRes] = useMutation(INC_FC_IMPORT_WAREHOUSE_PRODUCTS, {
    variables: { forecastDocId, warehouse },
    onCompleted(res) {
      onImportedProducts(res);
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div>
        <label htmlFor="">Choose Warehouse: </label>
        <Select
          value={warehouse}
          onChange={(e) => setWarehouse(e.target.value)}
        >
          <option value="AWD">AWD</option>
          <option value="INC">FSI</option>
        </Select>
      </div>

      <hr />

      <div>
        <CharlesButton onClick={confirm} loading={confirmRes.loading}>
          Confirm Import
        </CharlesButton>
      </div>
    </div>
  );
};

export default ImportFromWarehouseView;
