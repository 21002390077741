import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import CapacityBar, { transformData } from "../../../components/CapacityBar.js";
import { NavigationBar } from "../../../components/PageContainer.js";
import { Button } from "../../../components/base.js";
import { Alert } from "../../../components/Toast.js";
import { conversions } from "../../../utils/converter.js";
import http from "../../../utils/http.js";

const SAVE_CALCULATE = gql`
  mutation SAVE_CALCULDATE(
    $id: ID
    $name: String!
    $products: [SaveCalculateHistoryInput]!
    $totalQty: Int!
    $totalCtn: Int!
    $totalGw: Float!
    $totalCbm: Float!
    $totalPallet: Int!
  ) {
    saveCalculateHistory(
      id: $id
      name: $name
      products: $products
      totalQty: $totalQty
      totalCtn: $totalCtn
      totalGw: $totalGw
      totalCbm: $totalCbm
      totalPallet: $totalPallet
    ) {
      id
    }
  }
`;

function Summary({
  initialItem = null,
  className,
  style,
  metric,
  calculatingProducts,
  totalQty = 0,
  totalCtn = 0,
  totalGw = 0,
  totalCbm = 0,
  totalPallet = 0,
  onClear,
  onClickProduct,
  withTitle = true,
}) {
  const navigate = useNavigate();
  const [exporting, setExporting] = useState(false);
  const [saveCalculate, { loading }] = useMutation(SAVE_CALCULATE, {
    onError: (error) => Alert("error", error.message),
  });
  const totalGwWithMetric = metric
    ? totalGw.toFixed(3)
    : (totalGw * conversions.oneKgInPounds).toFixed(3);
  const totalCbmWithMetric = metric
    ? totalCbm.toFixed(3)
    : (totalCbm * conversions.oneCubicMetreInCubicFeet).toFixed(3);

  function exportSummary(filename) {
    setExporting(true);
    const data = {
      headerRow: [
        "Product",
        "QTY",
        "CTN",
        `G.W.(${metric ? "kg" : "lb"})`,
        `CBM(${metric ? "m³" : "ft³"})`,
      ],
      bodyRows: calculatingProducts.map((row) => [
        row.product.name,
        row.qty,
        row.ctn,
        metric ? row.gw : (row.gw * conversions.oneKgInPounds).toFixed(3),
        metric
          ? row.cbm
          : (row.cbm * conversions.oneCubicMetreInCubicFeet).toFixed(3),
      ]),
      footRow: [
        "Total",
        totalQty,
        totalCtn,
        totalGwWithMetric,
        totalCbmWithMetric,
      ],
      capacity: transformData(totalCbm),
    };
    http
      .post("/export/calculate/pdf/", data, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setExporting(false);
      })
      .catch((error) => {
        setExporting(false);
        Alert("error", "Could not export. Please try again later.");
      });
  }

  function doSave(name, id = null, alert = true) {
    saveCalculate({
      variables: {
        id,
        name,
        products: calculatingProducts.map((i) => ({
          productId: i.product.id,
          qty: i.qty,
          ctn: i.ctn,
          gw: i.gw,
          cbm: i.cbm,
          pallet: i.pallet,
        })),
        totalQty,
        totalCtn,
        totalGw,
        totalCbm,
        totalPallet,
      },
      update: (_, { data }) => {
        if (alert) Alert("success", "Saved.");
        if (id === null) navigate(`/calculate/${data.saveCalculateHistory.id}`);
      },
    });
  }

  return (
    <div className={`${className} flex flex-col`} style={style}>
      {withTitle ? (
        <NavigationBar
          barStyle="light"
          title="Summary"
          subtitle={initialItem ? initialItem.name : null}
          rightButtons={
            <div className="flex">
              {loading ? (
                <Button loading={true} size="sm" title="saving..." />
              ) : (
                <Button
                  className="mr-4"
                  size="sm"
                  title={loading ? "saving..." : "save"}
                  disabled={loading}
                  onClick={(_) => {
                    let name = initialItem ? initialItem.name : null;
                    if (name === null) {
                      name = window.prompt(
                        "Give this record a name. #SO, #PO or customer name?",
                      );
                    }
                    if (name) doSave(name, initialItem ? initialItem.id : null);
                  }}
                />
              )}

              {!loading && !!initialItem && (
                <Button
                  className="mr-4"
                  size="sm"
                  title={loading ? "saving..." : "save as"}
                  disabled={loading}
                  loading={loading}
                  onClick={(_) => {
                    const name = window.prompt(
                      "Give this record a name. #SO, #PO or customer name?",
                    );
                    if (name) doSave(name);
                  }}
                />
              )}

              <Button
                className="mr-4"
                size="sm"
                loading={exporting}
                disabled={exporting}
                title={exporting ? "exporting..." : "export"}
                onClick={(_) => {
                  let name = initialItem ? initialItem.name : null;
                  if (name === null) {
                    name = window.prompt(
                      "Give this record a name. #SO, #PO or customer name?",
                    );
                  }
                  if (name) {
                    doSave(name, initialItem ? initialItem.id : null, false);
                    exportSummary(`${name}.pdf`);
                  }
                }}
              />
              <Button
                size="sm"
                color="red"
                title="clear"
                onClick={(_) => {
                  if (
                    window.confirm("Are you sure you want to clear the input?")
                  )
                    onClear();
                }}
              />
            </div>
          }
        />
      ) : null}
      <div className="flex-1 overflow-auto">
        <table className="">
          <thead className="sticky top-0 bg-gray-300 dark:bg-gray-800 whitespace-nowrap">
            <tr>
              <th className="px-4 text-left">Product</th>
              <th className="px-4 text-right">Qty</th>
              <th className="px-4 text-right">CTN</th>
              <th className="px-4 text-right">Pallet</th>
              <th className="px-4 text-right">G.W.({metric ? "kg" : "lb"})</th>
              <th className="px-4 text-right">CBM({metric ? "m³" : "ft³"})</th>
            </tr>
          </thead>
          <tbody className="text-right">
            {calculatingProducts.map((i, index) => (
              <tr
                key={index}
                className="cursor-pointer hover:bg-blue-200 dark:hover:bg-blue-800"
                onClick={(_) => {
                  onClickProduct(i.product.id);
                }}
              >
                <td className="px-4 py-1 border-b border-gray-200 dark:border-gray-700 text-left text-xs">
                  {i.product.name}
                </td>
                <td className="px-4 py-1 border-b border-gray-200 dark:border-gray-700">
                  {i.qty}
                </td>
                <td className="px-4 py-1 border-b border-gray-200 dark:border-gray-700">
                  {i.ctn}
                </td>
                <td className="px-4 py-1 border-b border-gray-200 dark:border-gray-700">
                  {i.pallet}
                </td>
                <td className="px-4 py-1 border-b border-gray-200 dark:border-gray-700">
                  {metric
                    ? i.gw
                    : (i.gw * conversions.oneKgInPounds).toFixed(3)}
                </td>
                <td className="px-4 py-1 border-b border-gray-200 dark:border-gray-700">
                  {metric
                    ? i.cbm
                    : (i.cbm * conversions.oneCubicMetreInCubicFeet).toFixed(3)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="sticky bottom-0 text-right bg-red-500 text-white">
            <tr>
              <td className="px-4 py-2 text-left">Total</td>
              <td className="px-4 py-2">{totalQty}</td>
              <td className="px-4 py-2">{totalCtn}</td>
              <td className="px-4 py-2">{totalPallet}</td>
              <td className="px-4 py-2">{totalGwWithMetric}</td>
              <td className="px-4 py-2">{totalCbmWithMetric}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="p-4 border-t border-gray-200 dark:border-gray-700">
        <h4>Capacity</h4>
        <CapacityBar className="mt-4" value={totalCbm} />
      </div>
    </div>
  );
}

export default Summary;
