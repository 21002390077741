import { useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { VALIDATE_PRODUCTION_ROUTES } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";

const ConfirmPlanView = ({ plan, confirmTimeHandler }) => {
  const { loading, error, data } = useQuery(VALIDATE_PRODUCTION_ROUTES, {
    variables: { planId: plan.id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner text="Validating Production Routes..." />;
  if (error) return <Errors error={error} />;

  const errors = JSON.parse(data.validateProductionRoutes);

  console.log("errors", errors);

  return (
    <div>
      {errors.length > 0 ? (
        <div>
          <h5 className="text-red-600">Production Route Errors Found!</h5>
          <div className="text-sm">
            Wrong Production Routes will cause incorrect stock usage. Please
            make sure they are correct before you confirm the time. WIS
            automatically checks the products in China Stock if they are in the
            correct production route.
          </div>

          <div className="mt-4 space-y-3 text-sm">
            {errors.map((i, index) => (
              <div key={index} className="card px-6 py-4">
                <h4>{i.product.number}</h4>
                <div>{i.product.name}</div>

                <div className="mt-4">
                  <div className="flex items-center space-x-2">
                    <label htmlFor="">Production Route: </label>
                    <div>{i.production.name}</div>
                  </div>
                  <div>
                    <div>
                      {i.production.categories.map((c, cIndex) => (
                        <div key={cIndex}> - {c}</div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <h6>Missing Categories</h6>
                  <div className="mt-1 space-y-1">
                    {i.missing.map((j, jIndex) => (
                      <div key={jIndex}>
                        <div>
                          [{j.product.number}] {j.product.name} -{" "}
                          <span className=" text-red-600">
                            {j.production_category?.name ??
                              "No Production Category"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4 text-sm">
            To fix these issues, you can either remove the item from China
            Stock, or update the bom, production routes, categories.
          </div>
        </div>
      ) : null}

      <hr />

      <div>
        <div>
          You are going to confirm the time for plan: <b>{plan.name}</b>, the
          estimate ready date is :{" "}
          <b>{moment(plan.ready_date).format("YYYY-MM-DD")}</b>.
        </div>

        <div>Are you sure to confirm the time for this plan?</div>

        <hr />

        <div>
          <CharlesButton primary onClick={confirmTimeHandler}>
            Confirm Time
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPlanView;
