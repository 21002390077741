import { useQuery } from "@apollo/client";
import ShipmentForm from "./ShipmentForm";
import { FETCH_INC_SHIPMENT } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";

const EditShipmentView = ({ id, warehouse, products, hide }) => {
  const { loading, error, data } = useQuery(FETCH_INC_SHIPMENT, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <ShipmentForm
      originShipment={data.incShipment}
      warehouse={warehouse}
      products={products}
      hide={hide}
    />
  );
};

export default EditShipmentView;
