import { useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import Status from "components/Status";
import { useModals } from "ModalProvider";
import moment from "moment";
import { useContext, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { searchByProp } from "utils/search";
import CreatePlan from "./CreatePlan";
import { FETCH_PREV_PLANS } from "./graphql";
import ReorderView from "./Reorderview";
import { OdooContext } from "OdooProvider";
import { RiExternalLinkFill } from "react-icons/ri";
import OrderSchedulerDescription from "./docs/OrderSchedulerDescription";

const PlanList = ({ handled }) => {
  const [searchText, setSearchText] = useState("");
  let variables = { statesIn: ["DRAFT", "WAITING_TO_CONFIRM", "PLANNING"] };
  if (handled) {
    variables["statesIn"] = ["HANDLED"];
    variables["options"] = { orderBy: "-handle_at" };
    if (handled) variables["options"]["limit"] = 100;
    variables["rootPlans"] = true;
  }
  const { loading, error, data } = useQuery(FETCH_PREV_PLANS, {
    variables,
    fetchPolicy: "network-only",
  });
  const navigate = useNavigate();
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let orderingNumber = 0;

  const draftPlans = data.allProductionPlans.results.filter(
    (i) => i.state === "DRAFT",
  );
  const otherPlans = data.allProductionPlans.results
    .filter((i) => i.state !== "DRAFT")
    .map((i) => {
      if (i.state === "PLANNING" || i.state === "WAITING_TO_CONFIRM") {
        orderingNumber += 1;
        return { ...i, orderingNumber };
      }
      return i;
    });
  const plans = [...otherPlans, ...draftPlans]
    .map((i) => ({
      ...i,
      searchString: `${i.name} ${i.wisCustomer?.name} ${i.odooObject?.objectName}`,
    }))
    .filter((i) => searchByProp(i, ["searchString"], searchText));

  function createPlan() {
    modal.present({
      title: "Create a Plan",
      center: true,
      maxWidth: "max-w-lg",
      children: <CreatePlan onCreatedPlan={(plan) => navigate(plan.id)} />,
    });
  }

  function reorderPlans() {
    modal.present({
      title: "Sort Plans",
      center: false,
      maxWidth: "max-w-5xl",
      children: (
        <ReorderView
          initialPlans={plans.filter((i) =>
            ["WAITING_TO_CONFIRM", "PLANNING"].includes(i.state),
          )}
          onCompleted={modal.hide}
        />
      ),
    });
  }

  return (
    <div className="p-6 grid gap-6 grid-cols-12">
      <div className="col-span-full">
        <div className="card animate-fadeInDown">
          <div className="flex justify-between space-x-8">
            {handled ? null : (
              <CharlesButton onClick={createPlan}>+ Create Plan</CharlesButton>
            )}

            <div className="flex-1">
              <SearchBar
                value={searchText}
                onChange={setSearchText}
                className="bg-gray-100 dark:bg-gray-700 dark:bg-opacity-30"
                placeholder="Search by plan name, customer, or Odoo order / transfer name."
              />
            </div>

            {handled ? null : (
              <div className="flex space-x-6">
                <CharlesButton onClick={reorderPlans}>Sort Plans</CharlesButton>
              </div>
            )}
          </div>

          <div className="mt-2 -mx-2">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Customer</th>
                  <th>Production Date</th>
                  <th>Odoo</th>
                  <th className="text-right">Created</th>
                  {handled ? (
                    <th className="text-right">Handled By</th>
                  ) : (
                    <th className="text-right pr-4">State</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {plans.map((plan, index) => (
                  <Plan
                    key={plan.id}
                    index={index}
                    plan={plan}
                    handled={handled}
                  />
                ))}
              </tbody>
              {data.allProductionPlans.total > 100 && (
                <tfoot>
                  <tr>
                    <td colSpan="6">
                      <div className="opacity-70 text-xs">
                        Showing the latest handled 100 of{" "}
                        {data.allProductionPlans.total} plans. To view all
                        plans, please go to admin.
                      </div>
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>

        <div
          className="animate-fadeInDown opacity-0"
          style={{ animationDelay: "0.5s" }}
        >
          <OrderSchedulerDescription />
        </div>
      </div>
    </div>
  );
};

const Plan = ({ plan, handled, index }) => {
  const { odooUrl } = useContext(OdooContext);
  return (
    <tr
      className="py-2 border-t border-gray-100 dark:border-gray-700 animate-fadeInDown opacity-0"
      style={{ animationDelay: `${Math.min(index * 0.035, 1)}s` }}
    >
      <td className="w-8">
        <div className="flex items-center space-x-1">
          {!handled && plan.orderingNumber ? (
            <span className="rounded-full bg-teal-600 text-white w-5 h-5 flex items-center justify-center text-xs scale-90">
              {plan.orderingNumber}
            </span>
          ) : null}
          <Link to={plan.id} className="whitespace-nowrap">
            {plan.name}
          </Link>
        </div>
      </td>
      <td>{plan.wisCustomer ? plan.wisCustomer.name : " - "}</td>
      <td>
        <div className="flex items-center space-x-2 mt-px whitespace-nowrap">
          <div>{moment(plan.startDate).format("YYYY-MM-DD")}</div>
          <BsArrowRight />
          <div>
            {plan.readyDate
              ? moment(plan.readyDate).format("YYYY-MM-DD")
              : "..."}
          </div>
        </div>
      </td>
      <td>
        {plan.odooObject ? (
          <a
            target="_blank"
            className="text-purple-600 flex items-center space-x-1"
            rel="noreferrer"
            href={`${odooUrl}/web#id=${plan.odooObject.objectId}&cids=1&menu_id=408&action=312&model=sale.order&view_type=form`}
          >
            <span>{plan.odooObject.objectName}</span>
            <span>
              <RiExternalLinkFill />
            </span>
          </a>
        ) : (
          "-"
        )}
      </td>
      <td className="text-right">{plan.createdBy.firstName}</td>
      {handled ? (
        <td className="text-right">{plan.handledBy?.firstName}</td>
      ) : (
        <td>
          <div className="flex justify-end">
            <Status status={plan.state} />
          </div>
        </td>
      )}
    </tr>
  );
};

export default PlanList;
