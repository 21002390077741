import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Page from "components/Page";
import { NavigationLink } from "components/base";
import InventoryPage from "./Inventory";
import ForecastPage from "./forecast";
import ForecastDocPage from "./forecast/ForecastDocPage";

const IncPages = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path="inventory/*" element={<InventoryPage />} />
      <Route path="*" element={<Navigate to="forecast" />} />
    </Route>
    <Route path="forecast/*">
      <Route index element={<ForecastPage />} />
      <Route path=":id" element={<ForecastDocPage />} />
    </Route>
  </Routes>
);

const Layout = () => (
  <Page
    prompt={
      <div className="font-semibold text-red-600 rounded-xl px-6 text-sm">
        Note that this page is being worked on, the data might be not accurrated
        at the moment.
      </div>
    }
    className="h-screen"
    leftButtons={
      <div className="flex space-x-6">
        <NavigationLink to="/inc/forecast">Forecasting</NavigationLink>
        <NavigationLink to="/inc/inventory">Inventory Report</NavigationLink>
      </div>
    }
  >
    <Outlet />
  </Page>
);

export default IncPages;
