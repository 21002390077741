import { useContext, useEffect, useState } from "react";
import { ProductListContext } from "./Form";
import CharlesButton from "components/charles/base";
import InputSelect from "components/InputSelect";
import { Input } from "components/Form";
import { BsTrash, BsSearch } from "react-icons/bs";

const MaterialInputSelect = ({
  initialName,
  allMaterials,
  onChangeMaterial,
  index,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedName, setSelectedName] = useState(initialName);

  // dispaly the new material name when deleting the old material
  useEffect(() => {
    setSelectedName(initialName);
  }, [initialName]);

  return (
    <div className="flex items-center space-x-2">
      <div className="w-52">{selectedName}</div>

      <div className="relative">
        <InputSelect
          inputClasses={"w-56"}
          options={allMaterials}
          value={searchText}
          onChange={(value) => setSearchText(value)}
          onSelect={(material) => {
            setSelectedName(material.name);
            onChangeMaterial(index, "name", material.name);
          }}
          onBlur={() => {
            setTimeout(() => {
              setSearchText("");
            }, 300);
          }}
        />
        <BsSearch className="opacity-50 absolute right-2 top-1/2 transform -translate-y-1/2 text-base" />
      </div>
    </div>
  );
};

const MaterialsView = () => {
  const { data, setData, allMaterials } = useContext(ProductListContext);

  function addMaterial() {
    setData((prev) => ({
      ...prev,
      materials: [...prev.materials, { name: "", weight: 0, remark: "" }],
    }));
  }

  function onChangeMaterial(index, field, value) {
    setData((prev) => ({
      ...prev,
      materials: prev.materials.map((m, mIndex) =>
        mIndex === index ? { ...m, [field]: value } : m,
      ),
    }));
  }

  function removeMaterial(index) {
    setData((prev) => ({
      ...prev,
      materials: prev.materials.filter((_, mIndex) => mIndex !== index),
    }));
  }

  return (
    <div className="card pt-4">
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>
                <h5>Materials</h5>
              </th>
              <th>Remark(Position)</th>
              <th className="text-right">Weight(g)</th>
              <th className="text-right">Percentage(item weight)</th>
            </tr>
          </thead>
          <tbody>
            {data.materials.map((material, index) => (
              <tr key={index}>
                <td className=" w-1/2">
                  <div className="flex space-x-2 items-center">
                    <CharlesButton danger onClick={() => removeMaterial(index)}>
                      <BsTrash />
                    </CharlesButton>
                    <div>
                      <MaterialInputSelect
                        initialName={material.name}
                        allMaterials={allMaterials}
                        onChangeMaterial={onChangeMaterial}
                        index={index}
                      />
                    </div>
                  </div>
                </td>

                <td>
                  <Input
                    className="w-full"
                    placeholder="Remark"
                    value={material.remark}
                    onChange={(e) =>
                      onChangeMaterial(index, "remark", e.target.value)
                    }
                  />
                </td>

                <td>
                  <div className="flex justify-end">
                    <Input
                      className="text-right w-16"
                      value={material.weight}
                      onChange={(e) =>
                        onChangeMaterial(index, "weight", e.target.value)
                      }
                    />
                  </div>
                </td>
                <td className="text-right w-48">
                  {data.weight > 0
                    ? ((material.weight * 100) / data.weight).toFixed(2)
                    : 0}
                  %
                </td>
              </tr>
            ))}
          </tbody>
          {data.materials.length > 1 ? (
            <tfoot>
              <tr className="font-semibold text-base">
                <td>Total</td>
                <td></td>
                <td className="text-right">
                  {data.materials
                    .reduce((acc, m) => acc + Number(m.weight), 0)
                    .toFixed(2)}
                </td>
                <td className="text-right">
                  {data.weight > 0
                    ? data.materials
                        .reduce(
                          (acc, m) => acc + (m.weight * 100) / data.weight,
                          0,
                        )
                        .toFixed(2)
                    : 0}
                  %
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
      <div className="mt-4">
        <CharlesButton onClick={addMaterial}>+ Material</CharlesButton>
      </div>
    </div>
  );
};

export default MaterialsView;
