import React, { useContext, useReducer, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import InputSelect from "components/InputSelect";
import { Button, Tag } from "components/base";
import { Input, Text } from "components/Form";
import { Alert } from "components/Toast";
import QcStatus from "components/QcStatus";
import CustomerInfo from "./CustomerInfo";
import Logistics from "./logistics";
import ShipmentStatus from "./status";
import StatusForm from "./StatusForm";
import Attachments from "./Attachments";
import GenerateDocButton from "./GenerateDocButton";
import ShipmentInvoices from "./invoices";
import {
  UPDATE_SHIPMENT,
  NOTIFY_CUSTOMER,
  UPDATE_PRODUCT_PRICE_WITH_ODOO,
  UPDATE_SHIPMENT_INVOICE_ROW_PRODUCT,
  UPDATE_PRODUCT_PRICE,
} from "../graphql";
import ShipmentContext from "./ShipmentContext";
import reducer from "./reducer";
import ShipmentLeadtimeTracker from "./ShipmentLeadtimeTracker";
import Modal from "components/Modal";
import BomSelector from "./BomSelector";
import { useModals } from "ModalProvider";
import GenerateShippingMark from "./GenerateShippingMark";
import CharlesButton from "components/charles/base";
import ChooseCustomerAddressView from "./ChooseCustomerAddressView";
import ContainerizationView, {
  shippingZones,
} from "components/ContainerizationView";
import ProductSelector from "./ProductSelector";
import { parseError } from "apollo";
import SpecialItemForm from "./invoices/SpecialItemForm";
import CustomFormView from "./customform";
import NotifyCustomerView from "./NotifyCustomerView";
import NotifyCustomerTemplateList from "./NotifyCustomerTemplateList";

const stampOptions = ["AB", "LIMITED"];

function ShipmentContainer({ shipment, shipmentMeta }) {
  const [state, dispatch] = useReducer(reducer, {
    ...shipment,
    forwarderName: shipment.forwarder ? shipment.forwarder.name : "",
  });
  const [showBOMSelector, setShowBOMSelector] = useState(false);

  return (
    <ShipmentContext.Provider
      value={{
        state,
        dispatch,
        showBOMSelector,
        setShowBOMSelector,
      }}
    >
      <ShipmentForm shipmentMeta={shipmentMeta} />
    </ShipmentContext.Provider>
  );
}

function ShipmentForm({ shipmentMeta }) {
  const { state, dispatch, showBOMSelector, setShowBOMSelector } =
    useContext(ShipmentContext);
  const [updateShipment, updateShipmentRes] = useMutation(UPDATE_SHIPMENT, {
    onError: (err) => Alert("error", err.message),
  });
  const [notifyCustomer, notifyCustomerRes] = useMutation(NOTIFY_CUSTOMER, {
    onCompleted: (_) => Alert("success", "Email sent."),
    onError: (error) => Alert("error", error.message),
  });
  const [updateProductPriceWithOdoo, updateProductPriceWithOdooRes] =
    useMutation(UPDATE_PRODUCT_PRICE_WITH_ODOO, {
      variables: { shipmentId: state.id },
      onCompleted: () => {
        navigate(`/shipment/create-commercial-invoice/${state.id}`);
      },
      onError: (error) => Alert("error", error.message),
    });
  const [updateProductPrice, updateProductPriceRes] = useMutation(
    UPDATE_PRODUCT_PRICE,
    {
      variables: { shipmentId: state.id },
      onCompleted: () => {
        navigate(`/shipment/create-commercial-invoice/${state.id}`);
      },
      onError: (error) => Alert("error", error.message),
    },
  );
  const [updateShipmentInvoiceRowProduct, updateShipmentInvoiceRowProductRes] =
    useMutation(UPDATE_SHIPMENT_INVOICE_ROW_PRODUCT, {
      onCompleted: ({ updateShipmentInvoiceRowProduct }) => {
        dispatch({
          type: "updateShipmentInvoiceRowProduct",
          payload: {
            shipmentInvoiceRow:
              updateShipmentInvoiceRowProduct.shipmentInvoiceRow,
          },
        });
      },
    });

  const navigate = useNavigate();
  const shippingMarkModal = useModals();
  const chooseCustomerAddressModal = useModals();
  const changeProductModal = useModals();
  const editSpecialItemModal = useModals();
  const customFormModal = useModals();
  const notifyCustomerModal = useModals();
  const showTemplatesModal = useModals();

  function onChangeShipment(payload) {
    dispatch({ type: "changeShipment", payload });
  }

  const totalProductRows = state.shipmentInvoices.reduce(
    (prev, shipmentInvoice) =>
      prev + shipmentInvoice.shipmentInvoiceRows.length,
    0,
  );

  function saveShipment(withAlert = true, success) {
    const shipmentInput = {
      id: state.id,
      paymentTerms: state.paymentTerms,
      consigneeName: state.consigneeName,
      deliveryName: state.deliveryName,
      notifyPartyName: state.notifyPartyName,
      addr: state.addr,
      deliveryAddr: state.deliveryAddr,
      notifyParty: state.notifyParty,
      shippingMark: state.shippingMark,
      forwarderName: state.forwarderName,
      stamp: state.stamp,
      extraEmails: state.extraEmails,
      vv: state.vv,
      blNumber: state.blNumber,
      shipContainer: state.shipContainer,
      countryOfOrigin: state.countryOfOrigin,
      containerNumber: state.containerNumber,
      shippingTerm: state.shippingTerm,
      loadingPort: state.loadingPort,
      requestCargoReadyDate: state.requestCargoReadyDate,
      actualCargoReadyDate: state.actualCargoReadyDate,
      dateOfDeliveryToPort: state.dateOfDeliveryToPort,
      etd: state.etd,
      eta: state.eta,
      pickupLocation: state.pickupLocation,
      shipBy: state.shipBy,
      portOfDischarge: state.portOfDischarge,
      portOfDestination: state.portOfDestination,
      notes: state.notes,
      notesForCustomform: state.notesForCustomform,
      stampForPackingList: state.stampForPackingList,
    };
    const shipmentInvoiceRows = state.shipmentInvoices
      .flatMap((i) => i.shipmentInvoiceRows)
      .map((i) => ({
        id: i.id,
        orderNo: i.orderNo,
        packingName: i.packingName,
        specialItemName: i.specialItemName,
        newProductItemNumber: i.newProductItemNumber,
        newProductQtyPerCarton: i.newProductQtyPerCarton,
        newProductNetWeight: i.newProductNetWeight,
        newProductGrossWeight: i.newProductGrossWeight,
        newProductCbm: i.newProductCbm,
        newProductValue: i.newProductValue,
        hsCode: i.hsCode || "",
        hsCodeForDestination: i.hsCodeForDestination,
        qty: i.qty,
        totalCarton: i.totalCarton,
        totalNetWeight: i.totalNetWeight,
        totalGrossWeight: i.totalGrossWeight,
        totalCbm: i.totalCbm,
        totalValue: i.totalValue,
        productId: i.product.id,
        productType: i.product.productType,
        specialItemOuterCartonX: i.specialItemOuterCartonX,
        specialItemOuterCartonY: i.specialItemOuterCartonY,
        specialItemOuterCartonZ: i.specialItemOuterCartonZ,
        specialItemCustomName: i.specialItemCustomName,
        specialItemCustomBrand: i.specialItemCustomBrand,
        specialItemCustomUsage: i.specialItemCustomUsage,
        specialItemCustomValue: i.specialItemCustomValue,
        specialItemCustomSize: i.specialItemCustomSize,
        specialItemCustomMaterials: i.specialItemCustomMaterials,
      }));
    updateShipment({
      variables: {
        shipmentInput,
        shipmentInvoiceRows,
        forwarderName: state.forwarder ? state.forwarder.name : "",
      },
    }).then((res) => {
      if (res) {
        if (withAlert) Alert("success", "Shipment Saved.");
        if (success) success(res);
      }
    });
  }

  function prepareShippingMark(customer, shipmentInvoiceRow) {
    shippingMarkModal.present({
      title: "Shipping Mark",
      maxWidth: "w-full",
      children: (
        <GenerateShippingMark customer={customer} row={shipmentInvoiceRow} />
      ),
    });
  }

  function changeShipmentInvoiceRowProduct(shipmentInvoiceRowId) {
    changeProductModal.present({
      title: "Change Product",
      children: (
        <ProductSelector
          onSelectProduct={(product) => {
            changeProductModal.hide();
            updateShipmentInvoiceRowProduct({
              variables: { shipmentInvoiceRowId, productId: product.id },
              onCompleted({ updateShipmentInvoiceRowProduct }) {
                dispatch({
                  type: "changeShipmentInvoiceRowProduct",
                  payload: {
                    shipmentInvoiceRow:
                      updateShipmentInvoiceRowProduct.shipmentInvoiceRow,
                  },
                });
              },
              onError(error) {
                Alert("error", parseError(error));
              },
            });
          }}
        />
      ),
    });
  }

  function editSpecialItem(shipmentInvoiceId, row) {
    editSpecialItemModal.present({
      title: "Edit Special Item",
      maxWidth: "max-w-full",
      children: (
        <SpecialItemForm
          id={row.product.id}
          initialProduct={{
            name: row.product.name,
            number: row.product.number,
            hsCode: row.product.hsCode,
            quantityPerCarton: row.product.quantityPerCarton,
            ctnNetWeight: row.product.ctnNetWeight,
            ctnGrossWeight: row.product.ctnGrossWeight,
            outerCartonX: row.product.outerCartonX,
            outerCartonY: row.product.outerCartonY,
            outerCartonZ: row.product.outerCartonZ,
            customsName: row.product.customsName,
            customsBrand: row.product.customsBrand,
            customsUsage: row.product.customsUsage,
            customsValue: row.product.customsValue,
            customsSize: row.product.customsSize,
            customsMaterials: row.product.customsMaterials,
          }}
          onSaveProduct={(product) => {
            editSpecialItemModal.hide();
            dispatch({
              type: "updateShipmentInvoiceRowProductData",
              payload: {
                shipmentInvoiceId,
                shipmentInvoiceRowId: row.id,
                product,
              },
            });
          }}
        />
      ),
    });
  }

  function chooseCustomerAddress(forType) {
    chooseCustomerAddressModal.present({
      title: "Choose Address",
      subtitle: state.customer.name,
      children: (
        <ChooseCustomerAddressView
          customer={state.customer}
          onSelect={(address) => {
            didChooseAddress(forType, address);
          }}
        />
      ),
    });
  }

  function didChooseAddress(forType, address) {
    chooseCustomerAddressModal.hide();
    if (forType === "addr") {
      onChangeShipment({
        [forType]: address.address,
        consigneeName: address.name,
      });
    } else if (forType === "deliveryAddr") {
      onChangeShipment({
        [forType]: address.address,
        deliveryName: address.name,
      });
    } else if (forType === "notifyParty") {
      onChangeShipment({
        [forType]: address.address,
        notifyPartyName: address.name,
      });
    }
  }

  function showCustomFormGenerator() {
    customFormModal.present({
      title: "Generate Custom Form",
      children: <CustomFormView shipmentId={state.id} />,
      fullscreen: true,
      // maxWidth: "max-w-full",
    });
  }

  const invoiceNumber = state.shipmentInvoices
    .map((i) => i.invoice.number)
    .join(" / ");

  const templateNotesRef = useRef(state.notes);

  function showNotifyCustomerModal() {
    const emails = [
      state.customer.user.email,
      ...state.customer.subAccounts.map((i) => i.user.email),
      ...state.extraEmails.split(",").filter((i) => !!i),
    ];
    notifyCustomerModal.present({
      title: "Notify Customer",
      subtitle: state.customer.name,
      children: (
        <NotifyCustomerView
          shipmentId={state.id}
          initialNote={templateNotesRef.current}
          customerName={state.customer.name}
          invoiceNumber={invoiceNumber}
          emails={emails}
          hide={notifyCustomerModal.hide}
        />
      ),
    });
  }

  function showTemplates() {
    showTemplatesModal.present({
      title: "Select a template",
      children: (
        <NotifyCustomerTemplateList
          shipmentId={state.id}
          onSelect={(template) => {
            const notes = template
              .replace("{customername}", state.customer.name)
              .replace("{invoicenumber}", invoiceNumber);
            templateNotesRef.current = notes;
            showTemplatesModal.hide();
            showNotifyCustomerModal();
          }}
        />
      ),
    });
  }

  // default to US if no EU area
  const shippingZone =
    state.customer.areas.find((area) => shippingZones.includes(area.name))
      ?.name ?? shippingZones[0];

  return (
    <ShipmentContext.Provider
      value={{
        state,
        dispatch,
        prepareShippingMark,
        changeShipmentInvoiceRowProduct,
        editSpecialItem,
      }}
    >
      <div className="flex-1 relative">
        <div className="p-4 lg:p-8">
          <CustomerInfo
            customer={state.customer}
            invoiceNumber={invoiceNumber}
          />

          <div className="flex space-x-8 items-center mt-4">
            <ShipmentLeadtimeTracker />
          </div>

          <div className="p-4 mt-4 lg:p-6 lg:mt-6 bg-white dark:bg-gray-800 dark:text-gray-300 rounded-lg border border-gray-200 dark:border-gray-700">
            <StatusForm
              shipmentId={state.id}
              initialStatus={state.latestStatus ? state.latestStatus : "0"}
              options={shipmentMeta.shipmentStatusOptions}
            />
            <div className="mt-4">
              <ShipmentStatus errorOptions={shipmentMeta.errors} />
            </div>
          </div>

          <div className="flex items-center mt-8">
            <label className="inline-block mr-2">Payment Terms: </label>
            <div className="flex-1 relative">
              <InputSelect
                value={state.paymentTerms}
                options={shipmentMeta.paymentTerms.map((i) => ({
                  name: i,
                }))}
                onChange={(paymentTerms) => onChangeShipment({ paymentTerms })}
                onSelect={(option) =>
                  onChangeShipment({ paymentTerms: option.name })
                }
              />
            </div>
          </div>

          <div className="lg:flex pt-4 lg:space-x-8">
            <div className="lg:w-1/2 lg:pr-4 card border dark:border-gray-700 p-6 py-4">
              <div className="flex space-x-2 justify-between">
                <h5>Consignee</h5>
                <CharlesButton onClick={() => chooseCustomerAddress("addr")}>
                  Choose
                </CharlesButton>
              </div>
              <div className="flex items-center space-x-3 mt-2">
                <label className="w-16">Name: </label>
                <Input
                  className="flex-1"
                  value={state.consigneeName}
                  onChange={(e) =>
                    onChangeShipment({ consigneeName: e.target.value })
                  }
                  placeholder="Input name if it is different from the customer."
                />
              </div>
              <div className="flex items-baseline space-x-3 mt-3">
                <label className="w-16">Address: </label>
                <Text
                  rows={3}
                  className="h-auto text-sm"
                  value={state.addr}
                  onChange={(e) => onChangeShipment({ addr: e.target.value })}
                />
              </div>
            </div>

            <div className="mt-4 lg:mt-0 lg:w-1/2 card border dark:border-gray-700 p-6 py-4">
              <div className="flex space-x-2 justify-between">
                <h5>Delivery</h5>
                <CharlesButton
                  onClick={() => chooseCustomerAddress("deliveryAddr")}
                >
                  Choose
                </CharlesButton>
              </div>
              <div className="flex items-center space-x-3 mt-2">
                <label className="w-16">Name: </label>
                <Input
                  className="flex-1"
                  value={state.deliveryName}
                  onChange={(e) =>
                    onChangeShipment({ deliveryName: e.target.value })
                  }
                  placeholder="Input name if it is different from the customer."
                />
              </div>
              <div className="flex items-baseline space-x-3 mt-3">
                <label className="w-16">Address: </label>
                <Text
                  rows={3}
                  className="h-auto text-sm"
                  value={state.deliveryAddr}
                  onChange={(e) =>
                    onChangeShipment({ deliveryAddr: e.target.value })
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex pt-6 space-x-8">
            <div className="w-1/2 card border dark:border-gray-700 p-6 py-4">
              <div className="flex space-x-2 justify-between">
                <h5>Notify Party</h5>
                <CharlesButton
                  onClick={() => chooseCustomerAddress("notifyParty")}
                >
                  Choose
                </CharlesButton>
              </div>
              <div className="flex items-center space-x-3 mt-2">
                <label className="w-16">Name: </label>
                <Input
                  className="flex-1"
                  value={state.notifyPartyName}
                  onChange={(e) =>
                    onChangeShipment({ notifyPartyName: e.target.value })
                  }
                  placeholder="Input name if it is different from the customer."
                />
              </div>

              <div className="flex items-baseline space-x-3 mt-3">
                <label className="w-16">Address: </label>
                <Text
                  rows={3}
                  className="h-auto text-sm"
                  value={state.notifyParty}
                  onChange={(e) =>
                    onChangeShipment({ notifyParty: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="mt-0 w-1/2">
              <div className="pb-2">
                <label>Shipping Mark:</label>
              </div>
              <Text
                rows={3}
                className="h-auto text-sm"
                value={state.shippingMark}
                onChange={(e) =>
                  onChangeShipment({ shippingMark: e.target.value })
                }
              />
            </div>
          </div>

          <div className="mt-8">
            <ShipmentInvoices />
          </div>

          <div className="mt-8 p-8 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 dark:text-gray-300 rounded-xl">
            <h3 className="mb-4">QC Reports</h3>
            <div className="flex items-center justify-center text-base text-gray-700 dark:text-gray-400">
              <div className="flex flex-1 items-center">
                <div className="text-gray-600 font-bold ">NO REPORTS</div>
                <div className="ml-4">
                  &times; {totalProductRows - state.qcReports.length}
                </div>
              </div>
              {["pending", "fail", "pass"].map((status, index) => (
                <div key={index} className="flex flex-1 items-center">
                  <QcStatus
                    className="w-30"
                    status={status}
                    textSize="text-base"
                  />
                  <div className="ml-2">
                    &times;{" "}
                    {
                      state.qcReports.filter(
                        (i) => i.status.toLowerCase() === status,
                      ).length
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-8 p-8 bg-white dark:bg-gray-900 border border-gray-200 dark:border-gray-700 dark:text-gray-300 rounded-xl">
            <h3 className="mb-4">Container Capacity</h3>
            <ContainerizationView
              totalCbm={Number(state.totalCbm)}
              shippingZone={shippingZone}
            />
          </div>

          <h2 className="mt-8 dark:text-gray-300">Logistics</h2>

          <Logistics
            shippingTerms={shipmentMeta.shippingTerms}
            shippingZone={shippingZone}
          />

          <div className="flex mt-4">
            <div className="w-full">
              <div className="pb-2">
                <label>Stamp for Packing List:</label>
              </div>
              <div className="flex">
                {stampOptions.map((option, index) => (
                  <Tag
                    className="mr-4"
                    key={index}
                    title={option}
                    selected={state.stampForPackingList === option}
                    onClick={(_) =>
                      onChangeShipment({
                        stampForPackingList: option,
                      })
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="mt-6">
            <Attachments />
          </div>
        </div>

        <div
          id="footerControls"
          className="sticky bottom-0 left-0 right-0 flex py-4 border-t border-gray-200 dark:border-gray-700 overflow-auto scrolling-touch backdrop-blur-xl backdrop-filter bg-opacity-50 bg-gray-100 dark:bg-gray-900 dark:bg-opacity-70 border-b-gray-200 w-full"
        >
          <div className="px-4 flex text-sm whitespace-nowrap space-x-4">
            <Button
              className="ml-4"
              title={updateShipmentRes.loading ? "Saving... " : "Save"}
              color="blue"
              disabled={updateShipmentRes.loading}
              loading={updateShipmentRes.loading}
              onClick={() => {
                saveShipment();
              }}
            />
            <CharlesButton onClick={showTemplates}>
              Notify Customer
            </CharlesButton>
            <div className="ml-4">
              <GenerateDocButton
                saveShipment={saveShipment}
                shipmentId={state.id}
                docType="packinglist"
                text="Packing List"
              />
            </div>
            <div className="ml-4">
              <CharlesButton onClick={showCustomFormGenerator}>
                Custom Form
              </CharlesButton>
            </div>
            <div className="ml-4">
              <GenerateDocButton
                saveShipment={saveShipment}
                shipmentId={state.id}
                docType="bookingform"
                text="Booking Form"
              />
            </div>
            <div className="ml-4">
              <GenerateDocButton
                saveShipment={saveShipment}
                shipmentId={state.id}
                docType="inbound"
                text="InBound Sheet"
              />
            </div>
            <div className="ml-4">
              <GenerateDocButton
                saveShipment={saveShipment}
                shipmentId={state.id}
                docType="inbound2"
                text="InBound Sheet 2"
              />
            </div>
            <div className="ml-4">
              <Button title="BOM" onClick={() => setShowBOMSelector(true)} />
            </div>
            <Button
              className="ml-4"
              disabled={
                updateShipmentRes.loading ||
                updateProductPriceWithOdooRes.loading ||
                updateProductPriceRes.loading
              }
              loading={
                updateProductPriceWithOdooRes.loading ||
                updateProductPriceRes.loading
              }
              title={
                updateProductPriceWithOdooRes.loading ||
                updateProductPriceRes.loading
                  ? "Prepareing..."
                  : "Commercial Invoice"
              }
              onClick={() => {
                // NOTE: show wis cost price for shipment to WABOBA INC Warehouse!
                if (
                  state.deliveryName === "WABOBA INC" &&
                  state.deliveryAddr.includes("875 Douglas Hill Rd")
                ) {
                  saveShipment(false, updateProductPrice);
                } else {
                  saveShipment(false, updateProductPriceWithOdoo);
                }
              }}
            />
          </div>
        </div>

        <Modal
          center={false}
          title="Generate Shipment BOM"
          show={showBOMSelector}
          onHide={() => setShowBOMSelector(false)}
        >
          <BomSelector
            saveShipment={saveShipment}
            hide={() => setShowBOMSelector(false)}
          />
        </Modal>
      </div>
    </ShipmentContext.Provider>
  );
}

export default ShipmentContainer;
