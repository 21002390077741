import { gql, useMutation, useQuery } from "@apollo/client";
import AppSettings from "AppSettings";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useState } from "react";

const FETCH_LATEST_LIFESTYLE_IMAGES = gql`
  query FETCH_LATEST_LIFESTYLE_IMAGES($collectionName: String) {
    images: allPromoImages(collectionName: $collectionName) {
      id
      thumb: url(size: "300x300")
    }
  }
`;

const UPDATE_PRODUCT_LINE_PROMO_MATERIALS = gql`
  mutation UPDATE_PRODUCT_LINE_PROMO_MATERIALS(
    $id: ID!
    $lifestyleImages: [ID]
  ) {
    updateProductLinePromoMaterials(
      id: $id
      lifestyleImages: $lifestyleImages
    ) {
      productLine {
        id
        name
        promoImages {
          id
          name
          thumb: url(size: "300x300")
        }
      }
    }
  }
`;

const AssociateImagesView = ({ productLine }) => {
  const { loading, error, data } = useQuery(FETCH_LATEST_LIFESTYLE_IMAGES, {
    variables: { collectionName: AppSettings.lifestyleImagesCategory },
  });
  const [lifestyleImages, setLifestyleImages] = useState(
    productLine.promoImages,
  );
  const [update, updateRes] = useMutation(UPDATE_PRODUCT_LINE_PROMO_MATERIALS, {
    variables: { id: productLine.id, lifestyleImages },
    onCompleted() {
      Alert("success", "Images updated.");
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  function clickProductImage(image) {
    setLifestyleImages((prev) => prev.filter((i) => i.id !== image.id));
  }

  function clickLibImage(image) {
    setLifestyleImages((prev) => [image, ...prev]);
  }

  function updateHandler() {
    update({
      variables: {
        id: productLine.id,
        lifestyleImages: lifestyleImages.map((i) => i.id),
      },
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="flex-1 px-10 pb-10 grid grid-cols-2 gap-8 overflow-auto mt-6">
      <div className="flex-1 overflow-auto card p-0">
        <div className="flex justify-between items-center sticky top-0 bg-white bg-opacity-95 backdrop-blur z-10 px-6 py-4">
          <h5>Associated Images</h5>
          <CharlesButton onClick={updateHandler} loading={updateRes.loading}>
            Save
          </CharlesButton>
        </div>
        <div className="flex-1 flex content-start flex-wrap overflow-auto px-6">
          {lifestyleImages.map((promoImage, index) => (
            <div
              key={index}
              onClick={() => clickProductImage(promoImage)}
              className="cursor-pointer w-1/2 lg:w-1/3 xl:w-1/4 relative"
            >
              <div
                className="w-full bg-center bg-cover bg-no-repeat bg-gray-50 border border-gray-100"
                style={{
                  paddingBottom: "100%",
                  backgroundImage: `url(${promoImage.thumb})`,
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col flex-1 overflow-auto card px-6 py-4">
        <h5>All Lifestyle Images</h5>
        <div className="flex-1 flex content-start flex-wrap h-full overflow-auto mt-4">
          {data.images
            .filter(
              (i) => lifestyleImages.findIndex((j) => j.id === i.id) === -1,
            )
            .map((promoImage, index) => (
              <div
                key={index}
                onClick={() => clickLibImage(promoImage)}
                className="cursor-pointer w-1/2 lg:w-1/3 xl:w-1/4 relative"
              >
                <div
                  className="w-full bg-center bg-cover bg-no-repeat bg-gray-50 border border-gray-100"
                  style={{
                    paddingBottom: "100%",
                    backgroundImage: `url(${promoImage.thumb})`,
                  }}
                ></div>
              </div>
            ))}
        </div>
      </div>

      <div className="text-xs opacity-70">Choose images by clicking!</div>
    </div>
  );
};

export default AssociateImagesView;
