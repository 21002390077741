import { useQuery } from "@apollo/client";
import { FETCH_INC_SHIPMENTS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import Status from "components/Status";

const ChooseShipmentView = ({ onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_INC_SHIPMENTS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const shipments = data.shipments.results;

  return (
    <div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr>
              <th>Invoice Number / PO</th>
              <th>ETA</th>
              <th className="text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment) => (
              <tr
                key={shipment.id}
                className="border-y hover:bg-blue-100 cursor-pointer"
                onClick={() => onSelect(shipment)}
              >
                <td>{shipment.invoiceNumber}</td>
                <td>{shipment.eta}</td>
                <td>
                  <div className="flex justify-end">
                    <Status status={shipment.status} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChooseShipmentView;
