import SearchBar from "components/SearchBar";
import { useState } from "react";

const ChooseProductView = ({ products, onSelect }) => {
  const [searchText, setSearchText] = useState("");

  const filteredProducts = products.filter((i) =>
    i.sku.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <div>
      <div>
        <SearchBar
          className="dark:bg-black"
          value={searchText}
          onChange={setSearchText}
          placeholder="Search by SKU"
        />
      </div>
      <div className="mt-4">
        <table>
          <tbody>
            {filteredProducts.map((i) => (
              <tr
                key={i.id}
                className="border-y hover:bg-blue-100 dark:hover:bg-blue-900 cursor-pointer dark:border-gray-700"
                onClick={() => onSelect(i)}
              >
                <td>{i.sku}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChooseProductView;
