import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";

const FETCH_PRODUCT_WITH_BOM_COST_HISTORY = gql`
  query FETCH_PRODUCT_WITH_BOM_COST_HISTORY($id: ID!) {
    product(id: $id) {
      id
      defaultQty
      productWithBomCostHistory {
        date
        cost
      }
    }
  }
`;

const ProductWithBomCostHistoryView = ({ id }) => {
  const { loading, error, data } = useQuery(
    FETCH_PRODUCT_WITH_BOM_COST_HISTORY,
    {
      variables: { id },
    },
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  if (!data.product.productWithBomCostHistory.length)
    return (
      <div>
        This product does not have any cost history, it has only the latest
        cost.
      </div>
    );

  return (
    <div>
      <div className="text-sm">
        Below shows the cost history of the product with BOM. The cost is
        calculated based on the BOM cost and the default quantity of the
        product, which is {data.product.defaultQty}. <br />
        Note that this history does not include the changes by exchange rates
        between USD and CNY for each date. If there the product has a cost in
        CNY, but the currency is not updated in our system, the cost for that
        product will be 0. So make sure that the currency is updated for each
        date.
      </div>
      <div className="card px-4 py-2 mt-4">
        <table>
          <tbody>
            {data.product.productWithBomCostHistory.map((i, index) => (
              <tr
                key={index}
                className="border-b last:border-0 dark:border-gray-700"
              >
                <td className="">{i.date}</td>
                <td className=" text-right">{i.cost.toFixed(3)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductWithBomCostHistoryView;
