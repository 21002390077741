import { gql } from "@apollo/client";

export const FETCH_SUPPLIER_STOCKS = gql`
  query FETCH_SUPPLIER_STOCKS {
    products(hasSupplierStocks: true) {
      results {
        id
        name
        number
        supplierStockForecast
        supplierStockPlanningQty
        suppliers: stockSuppliers {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST = gql`
  query FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUEST($id: ID!) {
    supplierStockUpdateRequest(id: $id) {
      id
      stocks {
        updateType
        supplierStockRecordId
        product {
          id
          name
          number
        }
        qty
        readyDate
        remark
      }
      state
    }
  }
`;

export const CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST = gql`
  mutation CONFIRM_SUPPLIER_STOCK_UPDATE_REQUEST(
    $id: ID!
    $state: String!
    $recomputePlans: Boolean
  ) {
    confirmSupplierStockUpdateRequest(
      id: $id
      state: $state
      recomputePlans: $recomputePlans
    ) {
      request {
        id
        state
      }
    }
  }
`;

export const FETCH_PRODUCT_SUPPLIER_STOCK = gql`
  query FETCH_PRODUCT_SUPPLIER_STOCK($id: ID!) {
    product(id: $id) {
      id
      odooId
      number
      name
      supplierStockForecast
      supplierStocks {
        id
        qty
        readyDate
        supplier {
          id
          name
        }
        usages {
          id
          plan {
            id
            name
            readyDate
            wisCustomer {
              name
            }
            odooObject {
              objectName
              objectType
              objectId
            }
            state
          }
          qty
        }
        remark
      }
    }
  }
`;

export const FETCH_RELATED_ODOO_PO_INFO = gql`
  query FETCH_RELATED_ODOO_PO_INFO(
    $productOdooId: Int!
    $productionPlanIds: [ID]
  ) {
    productionPlanRelatedOdooPoInfo(
      productOdooId: $productOdooId
      productionPlanIds: $productionPlanIds
    )
  }
`;

export const CREATE_SUPPLIER_STOCK_RECORD = gql`
  mutation CREATE_SUPPLIER_STOCK_RECORD(
    $productId: ID!
    $supplierId: ID!
    $readyDate: DateTime!
    $qty: Int!
    $remark: String
    $recomputePlans: Boolean
  ) {
    createSupplierStockRecord(
      productId: $productId
      supplierId: $supplierId
      readyDate: $readyDate
      qty: $qty
      remark: $remark
      recomputePlans: $recomputePlans
    ) {
      supplierStockRecord {
        id
        qty
        latestQty
        readyDate
        remark
        product {
          id
          supplierStockForecast
          supplierStocks {
            id
            qty
            readyDate
            supplier {
              id
              name
            }
            usages {
              id
              plan {
                name
                wisCustomer {
                  name
                }
                odooObject {
                  objectName
                  objectType
                }
                state
              }
              qty
            }
            remark
          }
        }
      }
    }
  }
`;

export const FETCH_SUPPLIER_STOCK_RECORD = gql`
  query FETCH_SUPPLIER_STOCK_RECORD($id: ID!) {
    supplierStockRecord(id: $id) {
      id
      supplier {
        id
        name
      }
      product {
        id
        number
        name
      }
      qty
      remark
    }
  }
`;

export const UPDATE_SUPPLIER_STOCK_RECORD = gql`
  mutation UPDATE_SUPPLIER_STOCK_RECORD(
    $id: ID!
    $qty: Int
    $supplierId: ID
    $readyDate: DateTime
    $remark: String
    $recomputePlans: Boolean
  ) {
    updateSupplierStockRecord(
      id: $id
      qty: $qty
      supplierId: $supplierId
      readyDate: $readyDate
      remark: $remark
      recomputePlans: $recomputePlans
    ) {
      supplierStockRecord {
        id
        supplier {
          id
          name
        }
        readyDate
        product {
          id
          supplierStockForecast
          supplierStocks {
            id
            qty
            readyDate
            supplier {
              id
              name
            }
            usages {
              id
              plan {
                name
                wisCustomer {
                  name
                }
                odooObject {
                  objectName
                  objectType
                }
                state
              }
              qty
            }
            remark
          }
        }
        qty
        remark
      }
    }
  }
`;

export const FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS = gql`
  query FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS {
    supplierStockUpdateRequests {
      total
      results {
        id
        supplier {
          name
        }
        createdAt
        state
      }
    }
  }
`;
