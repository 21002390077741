import { useMutation } from "@apollo/client";
import { Button } from "components/base";
import FactorySelector from "components/FactorySelector";
import { DatePicker, Input } from "components/Form";
import { Alert } from "components/Toast";
import ProductSelector from "pages/manage/products/All/ProductSelector";
import { useState } from "react";
import CharlesButton from "components/charles/base";
import {
  CREATE_SUPPLIER_STOCK_RECORD,
  UPDATE_SUPPLIER_STOCK_RECORD,
} from "./graphql";

const SupplierStockDeliveryForm = ({
  id,
  initData = {
    supplierId: null,
    product: null,
    qty: 0,
    readyDate: new Date(),
    remark: "",
  },
  complete,
}) => {
  const [product, setProduct] = useState(initData.product);
  const [supplierId, setSupplierId] = useState(initData.supplierId);
  const [qty, setQty] = useState(initData.qty ?? 0);
  const [readyDate, setReadyDate] = useState(
    initData.readyDate ? new Date(initData.readyDate) : new Date(),
  );
  const [remark, setRemark] = useState(initData.remark ? initData.remark : "");
  const [recomputePlans, setRecomputePlans] = useState(false);

  const [createStockRecord, createStockRecordRes] = useMutation(
    CREATE_SUPPLIER_STOCK_RECORD,
    {
      onCompleted(res) {
        Alert("success", "New Stock Record Created.");
        complete(res);
      },
      onError(error) {
        Alert("error", error.message);
      },
      refetchQueries: ["GET_SUPPLIER_STOCKS"],
    },
  );
  const [updateStock, updateStockRes] = useMutation(
    UPDATE_SUPPLIER_STOCK_RECORD,
    {
      onCompleted(res) {
        Alert("success", "Stock Record Updated.");
        complete(res);
      },
      onError(error) {
        Alert("error", error.message);
      },
    },
  );

  function createHandler() {
    if (isNaN(qty) || qty <= 0) {
      Alert("error", "Invalid QTY");
      return;
    }
    createStockRecord({
      variables: {
        productId: product.id,
        supplierId,
        qty,
        readyDate,
        remark,
        recomputePlans,
      },
    });
  }

  function updateHandler() {
    if (isNaN(qty) || qty <= 0) {
      Alert("error", "Invalid QTY");
      return;
    }
    updateStock({
      variables: { id, qty, readyDate, remark, recomputePlans, supplierId },
    });
  }

  return (
    <div className="space-y-8 text-sm">
      <div className="space-y-6">
        <div className="flex space-x-3 items-center">
          <label className="w-24">Supplier: </label>
          <FactorySelector value={supplierId} onChange={setSupplierId} />
        </div>
        <div className="flex space-x-3 items-center">
          <label className="w-24">Product: </label>
          {product ? (
            <div className="flex items-center space-x-3">
              <div>
                [{product.number}] {product.name}
              </div>
              <Button
                size="sm"
                title="Change Product"
                onClick={() => setProduct(null)}
              />
            </div>
          ) : (
            <ProductSelector onSelect={setProduct} />
          )}
        </div>
        <div className="flex space-x-10">
          <div className="space-x-3 flex items-center">
            <label className="w-24">Delivery QTY: </label>
            <Input
              className="text-center"
              value={qty}
              onChange={(e) => setQty(e.target.value)}
            />
          </div>
          <div className="flex items-center space-x-3">
            <label className="w-24">Ready Date: </label>
            <DatePicker value={readyDate} onDayChange={setReadyDate} />
          </div>
        </div>

        <div className="space-x-3 flex items-center">
          <label className="w-24">Remark: </label>
          <Input
            className="w-full"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
      </div>

      <hr />

      <div>
        <div className="flex items-center space-x-2">
          <label htmlFor="recompute">
            Recompute Plans after Stock Update:{" "}
          </label>
          <input
            type="checkbox"
            name="recompute"
            id="recompute"
            checked={recomputePlans}
            onChange={() => setRecomputePlans(!recomputePlans)}
          />
        </div>
        <div className="mt-2 opacity-70 text-sm">
          Recompute the production plans which are in AWAITING TO CONFIRM and
          PLANNING state. This action cannot be undone.
        </div>
      </div>

      <hr />

      <div className="mt-8">
        {id ? (
          <CharlesButton
            primary
            loading={updateStockRes.loading}
            onClick={updateHandler}
          >
            Save
          </CharlesButton>
        ) : (
          <CharlesButton
            primary
            loading={createStockRecordRes.loading}
            onClick={createHandler}
          >
            Create
          </CharlesButton>
        )}
      </div>
    </div>
  );
};

export default SupplierStockDeliveryForm;
