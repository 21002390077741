import { gql } from "@apollo/client";

export const FETCH_INC_SHIPMENTS = gql`
  query FETCH_INC_SHIPMENTS {
    shipments(
      latestStatusIn: [
        "waiting for deposit payment"
        "in production"
        "delivered to port"
        "shipping"
      ]
      options: { q: "waboba inc" }
    ) {
      total
      results {
        id
        status
        invoiceNumber
        eta: latestEtd
        shipmentInvoices {
          id
          shipmentInvoiceRows {
            id
            product {
              id
              odooId
              number
            }
            qty
          }
        }
      }
    }
  }
`;

export const FETCH_INC_WAREHOUSE_PRODUCTS = gql`
  query FETCH_INC_WAREHOUSE_PRODUCTS($warehouse: String) {
    incProducts(warehouse: $warehouse) {
      id
      sku
    }
  }
`;

export const INC_SAVE_SHIPMENT = gql`
  mutation INC_SAVE_SHIPMENT(
    $id: ID
    $warehouse: String
    $name: String
    $date: Date
    $shipmentType: String
    $lines: [IncShipmentLineInput]
    $state: String
  ) {
    incSaveShipment(
      id: $id
      warehouse: $warehouse
      name: $name
      date: $date
      shipmentType: $shipmentType
      lines: $lines
      state: $state
    ) {
      shipment {
        id
        warehouse
        date
        shipmentType
        state
        lines {
          id
          product {
            id
          }
          qty
        }
      }
    }
  }
`;

export const INC_DELETE_SHIPMENT = gql`
  mutation INC_DELETE_SHIPMENT($id: ID!) {
    incDeleteShipment(id: $id) {
      success
    }
  }
`;

export const FETCH_INC_SHIPMENT = gql`
  query FETCH_INC_SHIPMENT($id: ID!) {
    incShipment(id: $id) {
      id
      name
      date
      state
      lines {
        id
        product {
          id
          sku
        }
        qty
      }
    }
  }
`;
