import { useState } from "react";
import { useEffect, useRef } from "react";
import * as THREE from "three";
import { OBJLoader, OrbitControls, MTLLoader } from "three/examples/jsm/Addons";

const ModelView = () => {
  const ref = useRef();
  const [loadedPercentage, setLoadedPercentage] = useState(0);

  function init() {
    let camera, scene, renderer;

    const width = ref.current.clientWidth;
    const height = ref.current.clientHeight;

    function render() {
      renderer.render(scene, camera);
    }

    camera = new THREE.PerspectiveCamera(30, width / height, 1, 2000);
    camera.position.set(15, 5, 25);

    // scene

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x020617);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Adjust intensity to 0.5
    scene.add(ambientLight);

    // Add a few point lights to the scene
    const lightsPositions = [-2, -1, 0, 1, 2];

    lightsPositions.forEach((x) => {
      const light = new THREE.PointLight(0xffffff, 10);
      light.position.set(x, 1, 1);
      scene.add(light);
    });

    scene.add(camera);

    // 显示坐标轴
    // const axesHelper = new THREE.AxesHelper(5);
    // scene.add(axesHelper);

    // model

    const loadingManager = new THREE.LoadingManager();

    loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
      const progress = Math.round((itemsLoaded / itemsTotal) * 100);
      setLoadedPercentage(Math.round(progress));
    };

    const mtlLoader = new MTLLoader(loadingManager);
    const objLoader = new OBJLoader(loadingManager);

    mtlLoader.load(
      "https://charlie-play.s3.us-west-1.amazonaws.com/3d/Japan.mtl",
      (materials) => {
        materials.preload();
        objLoader.setMaterials(materials);
        objLoader.load(
          "https://charlie-play.s3.us-west-1.amazonaws.com/3d/Japan.obj",
          (object) => {
            object.scale.setScalar(0.02);

            // set the object to the center of zero point
            const box = new THREE.Box3().setFromObject(object);
            const center = box.getCenter(new THREE.Vector3());
            object.position.x += object.position.x - center.x;
            object.position.y += object.position.y - center.y;
            object.position.z += object.position.z - center.z;

            object.traverse((child) => {
              if (child.isMesh) {
                child.material = new THREE.MeshStandardMaterial({
                  map: child.material.map, // Use the original texture map
                  color: 0xffffff, // Ensure the material color is set to white to display original colors
                });
                child.material.needsUpdate = true; // Ensure the material updates properly
              }
            });

            scene.add(object);

            render();
          },
        );
      },
    );

    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(width / height);
    renderer.setSize(width, height);
    ref.current.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", render);

    window.addEventListener("resize", onWindowResize);

    function onWindowResize() {
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
      render();
    }
  }

  useEffect(() => {
    if (ref.current) init();
  }, [ref.current]);

  return (
    <div className="flex-1 h-screen overflow-auto" ref={ref}>
      {loadedPercentage < 100 && (
        <div className="absolute inset-0 flex items-center justify-center text-base p-6 drop-shadow">
          <div>{loadedPercentage}%</div>
        </div>
      )}

      <div className="absolute bottom-0 text-center w-full text-white text-base p-6 drop-shadow">
        This is a test page for 3D model view. We are testing if we could use
        this to display 3D models in the future.
      </div>
    </div>
  );
};

export default ModelView;
