import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_DOCUMENT } from "../graphql";
import { FETCH_ALL_DOCUMENTS } from "../docs/graphql";
import { Button } from "../../../components/base.js";
import http from "../../../utils/http";
import { Alert } from "../../../components/Toast";

function CreateDocumentButton({
  shipmentId,
  invoiceNumber,
  docType,
  text,
  saveShipment,
}) {
  const [preparing, setPreparing] = useState(false);
  const [exporting, setExporting] = useState(false);
  const navigate = useNavigate();
  const [createDocument, { loading }] = useMutation(CREATE_DOCUMENT, {
    refetchQueries: [
      {
        query: FETCH_ALL_DOCUMENTS,
        variables: { docType },
      },
    ],
  });

  const exportExcel = (id, filename) => {
    setExporting(true);
    http
      .get(`/doc/${id}/?excel=1`, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
        withCredentials: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setExporting(false);
      })
      .catch((err) => {
        alert(err);
        setExporting(false);
      });
  };

  async function onClickHandler() {
    setPreparing(true);
    saveShipment(false, () => {
      setPreparing(false);
      createDocument({
        variables: { shipmentId, docType },
        update: (
          _,
          {
            data: {
              createDocument: { document },
            },
          },
        ) => {
          if (docType === "inbound") {
            exportExcel(document.id, "inbound sheet.xlsx");
          } else if (docType === "inbound2") {
            exportExcel(document.id, "inbound sheet 2.xlsx");
          } else if (docType === "bom") {
            exportExcel(document.id, `BOM-SO${invoiceNumber}.xlsx`);
          } else if (docType === "bookingform") {
            exportExcel(
              document.id,
              `BookingForm-${document.shipment.invoiceNumber.replace(
                "/",
                "_",
              )}.xlsx`,
            );
          } else {
            navigate(`/shipment/doc/${docType}/${document.id}`);
          }
        },
      }).catch((err) => {
        Alert("error", String(err));
        setPreparing(false);
      });
    });
  }

  return (
    <Button
      title={
        preparing
          ? "Preparing"
          : loading
            ? "Generating..."
            : exporting
              ? "Exporting..."
              : text
      }
      disabled={preparing || loading || exporting}
      loading={preparing || loading || exporting}
      onClick={onClickHandler}
    />
  );
}

export default CreateDocumentButton;
