export const qtyTypes = [
  "begin_inv",
  "inbound",
  // "outbound",
  "forecast",
  "sales",
  "end_inv",
];

export const itemTypes = {
  begin_inv: "inventory",
  forecast: "forecast",
  sales: "sales",
};
