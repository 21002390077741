import { gql, useQuery } from "@apollo/client";
import { Button } from "components/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import moment from "moment";
import CreateQCReport from "./CreateQCReport";
import RecentQCReports from "./RecentReports";
import { ProductImagesV2 } from "components/ProductImages";

const FETCH_QC_SHIPMENTS = gql`
  query FETCH_QC_SHIPMENTS {
    shipmentList(latestStatusIn: ["in production"], prefetchFor: "qc") {
      id
      invoiceNumber
      requestCargoReadyDate
      shipmentInvoices {
        id
        invoice {
          id
          number
          po
          qcReports {
            id
            name
            product {
              id
              name
            }
            checkQty
            qty
          }
        }
        rows: qcRows {
          id
          product {
            id
            odooId
            name
            images: computedImages(size: "300x300") {
              id
              url
            }
          }
          qty
        }
      }
    }
  }
`;

const QCDashboard = () => {
  const { loading, error, data } = useQuery(FETCH_QC_SHIPMENTS);
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const shipments = data.shipmentList
    .map((shipment) => {
      const shipmentInvoices = shipment.shipmentInvoices
        .map((shipmentInvoice) => {
          const rows = shipmentInvoice.rows
            .map((row) => {
              const qcReports = shipmentInvoice.invoice.qcReports.filter(
                (i) => i.product.id === row.product.id,
              );
              const hasCompleted =
                qcReports.reduce((res, i) => res + i.qty, 0) >= row.qty;
              return { ...row, qcReports, hasCompleted };
            })
            .filter((i) => !i.hasCompleted);
          return { ...shipmentInvoice, rows };
        })
        .filter((i) => i.rows.length > 0);
      return { ...shipment, shipmentInvoices };
    })
    .filter((i) => i.shipmentInvoices.length > 0)
    .sort((a, b) => a.requestCargoReadyDate - b.requestCargoReadyDate);

  function createHandler(props) {
    modal.present({
      title: "创建质检报告",
      center: false,
      children: <CreateQCReport onCreated={modal.hide} {...props} />,
    });
  }

  return (
    <div className="p-6">
      <h2>To do</h2>
      <div className="md:flex md:space-x-6 space-y-6 md:space-y-0">
        <div className="mt-4 space-y-8 md:w-2/3">
          {shipments.map((shipment) => (
            <Shipment
              shipment={shipment}
              key={shipment.id}
              createHandler={createHandler}
            />
          ))}
        </div>

        <div className="flex-1 relative md:w-1/3 flex-shrink-0">
          <RecentQCReports />
        </div>
      </div>
    </div>
  );
};

const Shipment = ({ shipment, createHandler }) => {
  return (
    <div>
      <div className="flex items-end space-x-3">
        <h4>Shipment {shipment.invoiceNumber}</h4>
        <div className="mb-1">
          {moment.unix(shipment.requestCargoReadyDate).format("YYYY-MM-DD")}
        </div>
      </div>

      <div className="mt-4">
        {shipment.shipmentInvoices.map((shipmentInvoice, index) => (
          <div key={index}>
            {shipment.shipmentInvoices.length > 1 ? (
              <div className="font-bold my-4">
                {shipmentInvoice.invoice.number}
              </div>
            ) : null}
            <div className="space-y-4">
              {shipmentInvoice.rows.map((row, index) => (
                <div
                  key={index}
                  className={`bg-white dark:bg-gray-800 p-6 rounded-2xl
                              ${row.product.odooId ? "" : "bg-pink-200"}
                           `}
                >
                  <div className="flex flex-wrap">
                    <ProductImagesV2 images={row.product.images} />
                  </div>
                  <div className="mt-3 font-bold">
                    {row.product.name} &times; {row.qty}
                  </div>
                  <div>
                    {row.product.odooId ? (
                      <Button
                        className="mt-4"
                        title="+ Create QC Report"
                        bold
                        size="sm"
                        onClick={() =>
                          createHandler({
                            product: row.product,
                            qty: row.qty,
                            invoice: shipmentInvoice.invoice,
                          })
                        }
                      />
                    ) : (
                      <div className="text-red-600 text-sm font-bold mt-2">
                        This item is not connected with Odoo.
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QCDashboard;
