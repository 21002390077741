import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FETCH_CONTAINERS, FETCH_LOADING_PLAN } from "graphql/query";
import { CREATE_CONTAINER_COST } from "graphql/mutations";
import Spinner, { InlineSpinner } from "./Spinner";
import Errors from "./Errors";
import CharlesButton, { CharlesButtonWithArrow } from "./charles/base";
import { useEffect, useState } from "react";
import { Input, Select } from "./Form";
import { MdCleaningServices } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { CiCalculator1 } from "react-icons/ci";
import { Alert } from "./Toast";

export const shippingZones = ["Shipping cost(US)", "Shipping cost(EU)"];

const getContainers = (planContainers, totalCbm) => {
  // loadingPlan like: {"20GP": 0, "40GP": 1, "40HQ": 2, "45HQ": 0}
  // need to convert it to [ {name: "40GP", cbm: 58, cost: 10000, emptySpace: 0}, {name: "40GP", cbm: 58, cost: 10000, emptySpace: 0}, ...]
  let remainingCbm = totalCbm;
  let totalCapacity = 0;

  const containers = planContainers.flatMap((container) => {
    const containerArray = Array(container.qty).fill();

    const containerObjects = containerArray.map(() => {
      totalCapacity += container.cbm;

      if (remainingCbm >= container.cbm) {
        remainingCbm -= container.cbm;
        return {
          name: container.name,
          cbm: container.cbm,
          cost: container.cost,
          emptySpace: 0,
        };
      } else {
        const emptySpace = container.cbm - remainingCbm;
        remainingCbm = 0;
        return {
          name: container.name,
          cbm: container.cbm,
          cost: container.cost,
          emptySpace,
        };
      }
    });

    return containerObjects;
  });

  // sort the containers by cbm from small to large
  containers.sort((a, b) => a.cbm - b.cbm);

  return { containers, remainingCapacity: totalCapacity - totalCbm };
};

const ContainerizationView = ({ totalCbm, shippingZone }) => {
  const roundedTotalCbm = totalCbm.toFixed(2);

  const { loading, error, data } = useQuery(FETCH_LOADING_PLAN, {
    variables: {
      totalCbm: roundedTotalCbm,
      specificContainers: null,
      rate: 1,
      zone: shippingZone,
    },
  });
  const [showCalculator, setShowCalculator] = useState(false);

  if (loading)
    return (
      <div className="relative flex">
        <InlineSpinner size={21} text={null} />
      </div>
    );
  if (error) return <Errors errors={error} />;

  const { containers, remainingCapacity } = getContainers(
    data.loadingPlan,
    roundedTotalCbm,
  );

  return (
    <div>
      <div className="flex flex-wrap gap-4">
        {containers.map((c, index) => (
          <ContainerView container={c} key={index} />
        ))}
      </div>

      <LoadingPlanInfo
        planContainers={data.loadingPlan}
        totalCbm={roundedTotalCbm}
        shippingZone={shippingZone}
      />

      <div className="mt-2 opacity-70">
        <p>
          {remainingCapacity > 0 &&
            "You can add more products to fill up the container with empty space."}
        </p>
        <p>
          The default plan displayed above is the best plan with a container
          utilization rate of 100%.
        </p>
        <p>
          The algorithm is trying to find the lowest cost first, then the
          smallest total container capacity (the highest possible loading rate).
        </p>
      </div>

      <div className="mt-4 space-y-2">
        <CharlesButtonWithArrow
          onClick={() => setShowCalculator(!showCalculator)}
          show={showCalculator}
        >
          <div className="flex items-center">
            <CiCalculator1 className="text-xl" />
            Loading Plan Calculator
          </div>
        </CharlesButtonWithArrow>

        {showCalculator && (
          <Calculator
            className="mt-2"
            defaultTotalCbm={roundedTotalCbm}
            shippingZone={shippingZone}
          />
        )}
      </div>
    </div>
  );
};

const LoadingPlanInfo = ({ planContainers, totalCbm, shippingZone, rate }) => {
  const totalCost = planContainers.reduce(
    (acc, planContainer) => acc + planContainer.cost * planContainer.qty,
    0,
  );
  const totalCapacity = planContainers.reduce(
    (acc, planContainer) => acc + planContainer.cbm * planContainer.qty,
    // acc + planContainer.cbm * (rate / 100) * planContainer.qty,
    0,
  );

  return (
    <div className="px-6 py-4 border border-gray-200 w-fit rounded-xl">
      <div className=" text-base font-bold mb-4">Loading Plan</div>

      <div className="text-sm flex">
        <div className="space-y-2">
          {planContainers
            .filter((container) => container.qty > 0)
            .map((container, index) => (
              <div
                key={index}
                className="flex justify-between space-x-4 flex-1"
              >
                <div>
                  <span className="font-bold">{container.name}</span>
                  <span className="ml-2">
                    (CBM: {container.cbm}, Cost: {container.cost} {"USD"})
                  </span>
                </div>
                <div className="flex justify-end">
                  <span className="mx-2">&times;</span>
                  <span className="font-bold">{container.qty}</span>
                </div>
              </div>
            ))}
        </div>

        <div className=" border border-gray-200 mx-8" />

        <div className="space-y-2">
          <div className="flex">
            <label className="w-28">Total CBM: </label>
            <span className="font-bold">{totalCbm} m³</span>
          </div>
          <div className="flex">
            <label className="w-28">Total Cost: </label>
            <span className="font-bold">
              {totalCost} {"USD"}
            </span>
          </div>
          <div className="flex">
            <label className="w-28">Shipping Zone: </label>
            <span className="font-bold">
              {shippingZone === "Shipping cost(EU)" ? "EU" : "US"}
            </span>
          </div>
          <div className="flex">
            <label className="w-28">Empty Space: </label>
            <span className="font-bold">
              {(totalCapacity - totalCbm).toFixed(2)} m³
            </span>
          </div>
          <div className="flex">
            <label className="w-28">Loading Rate: </label>
            <span className="font-bold">
              {((totalCbm / totalCapacity) * 100).toFixed(2)} %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContainerView = ({ container }) => {
  const loadingPercentage = (1 - container.emptySpace / container.cbm) * 100;
  const width = 2 * container.cbm; // width is based on the cbm of the container, 1 cbm = 2px here, just for better visualization

  return (
    <div className="py-4">
      <div
        className="w-16 h-24 relative bg-gray-100 border-2 border-gray-400"
        style={{ width }}
      >
        <div
          className="w-full bg-green-500 absolute bottom-0 transition-all duration-500 ease-in-out"
          style={{
            height: `${loadingPercentage}%`,
          }}
        />
      </div>
      <div className="text-center mt-1">{container.name}</div>
    </div>
  );
};

const Calculator = ({ defaultTotalCbm, shippingZone }) => {
  const [totalCBM, setTotalCBM] = useState(defaultTotalCbm);
  const [specificContainers, setSpecificContainers] = useState({});
  const [rate, setRate] = useState(100);
  const [zone, setZone] = useState(shippingZone);

  const {
    loading: containerLoading,
    error: containerError,
    data: containerData,
  } = useQuery(FETCH_CONTAINERS, { variables: { zone: zone } });

  const [fetchLoadingPlan, { loading, error, data }] =
    useLazyQuery(FETCH_LOADING_PLAN);

  const [finalLoadingPlan, setFinalLoadingPlan] = useState(null);

  useEffect(() => {
    if (data) {
      const result = data.loadingPlan.map((p) => p);

      // add the specific containers to the result
      Object.entries(specificContainers).forEach(([name, qty]) => {
        containerData.containers.forEach((c) => {
          if (c.name === name) {
            result.push({ ...c, qty });
          }
        });
      });

      setFinalLoadingPlan(result);
    }
  }, [data]);

  useEffect(() => {
    // clean plan when calculation conditions change
    setFinalLoadingPlan(null);
  }, [totalCBM, rate, specificContainers, zone]);

  const onChangeQty = (name, value) => {
    setSpecificContainers((prev) => {
      if (value === "") {
        const { [name]: _, ...newSpecificContainers } = prev;
        return newSpecificContainers;
      }

      return { ...prev, [name]: parseInt(value) };
    });
  };

  const handleCalculate = () => {
    fetchLoadingPlan({
      variables: {
        totalCbm: totalCBM,
        specificContainers: JSON.stringify(specificContainers),
        rate: rate / 100,
        zone: zone,
      },
    });
  };

  if (containerLoading)
    return (
      <div className="relative">
        <InlineSpinner size={21} />
      </div>
    );
  if (containerError) return <Errors errors={containerError} />;

  return (
    <div className="flex items-center">
      <div className="text-sm p-4 w-fit rounded-xl shadow-lg bg-gray-50 space-y-4">
        <h5>Loading Plan Calculator</h5>
        <div className="space-y-2">
          <div className="flex space-x-2 items-center">
            <label className="w-64">Total CBM (m³):</label>
            <Input
              type="number"
              className="w-24"
              min={0}
              value={totalCBM}
              onChange={(e) => setTotalCBM(parseFloat(e.target.value))}
            />
            <MdCleaningServices
              className="cursor-pointer text-sky-400"
              onClick={() => setTotalCBM(defaultTotalCbm)}
            />
          </div>

          <div className="flex space-x-2 items-center">
            <label className="w-64">Utilization Rate (%):</label>
            <Input
              className="w-24"
              type="number"
              min={0}
              max={100}
              value={rate}
              onChange={(e) => setRate(parseFloat(e.target.value))}
            />
            <MdCleaningServices
              className="cursor-pointer text-sky-400"
              onClick={() => setRate(100)}
            />
          </div>

          <div className="flex space-x-2 items-center">
            <label className="w-64">Shipping Zone:</label>
            <Select
              value={zone}
              onChange={(e) => setZone(e.target.value)}
              className="w-24 text-xs"
            >
              {shippingZones.map((z, index) => (
                <option key={index} value={z}>
                  {z === "Shipping cost(EU)" ? "EU" : "US"}
                </option>
              ))}
            </Select>

            <MdCleaningServices
              className="cursor-pointer text-sky-400"
              onClick={() => setZone(shippingZone)}
            />
          </div>
        </div>

        <div className="border border-gray-200 border-dashed" />

        <div className=" space-y-2">
          <label>Containers:</label>
          {containerData.containers.map((c, index) => (
            <div key={index} className="flex space-x-2 items-center ">
              <div className="w-64">
                <span className="font-bold">{c.name}</span>
                <span className="ml-2">
                  (CBM: {c.cbm} m³, Cost: {c.cost} {"USD"})
                </span>
              </div>

              <Input
                className="w-24"
                placeholder="unlimited"
                type="number"
                min={0}
                value={
                  specificContainers[c.name] !== undefined &&
                  specificContainers[c.name] !== null
                    ? specificContainers[c.name]
                    : ""
                }
                onChange={(e) => onChangeQty(c.name, e.target.value)}
              />

              <MdCleaningServices
                className="cursor-pointer text-sky-400"
                onClick={() => onChangeQty(c.name, "")}
              />
            </div>
          ))}
        </div>

        <hr />

        <CharlesButton className="mt-4" onClick={handleCalculate}>
          Calculate
        </CharlesButton>
      </div>

      {loading && (
        <div className="flex-1">
          <InlineSpinner size={21} text="Calculating..." />
        </div>
      )}
      {error && <Errors errors={error} />}

      {finalLoadingPlan && (
        <>
          <FaArrowRightLong className="text-2xl mx-6" />

          <LoadingPlanInfo
            planContainers={finalLoadingPlan}
            totalCbm={totalCBM}
            shippingZone={zone}
          />
        </>
      )}
    </div>
  );
};

export const ContainerCostForm = ({ hide }) => {
  const [zone, setZone] = useState("Shipping cost(US)");
  const [containers, setContainers] = useState([]);

  const [createContainerCost] = useMutation(CREATE_CONTAINER_COST, {
    refetchQueries: [{ query: FETCH_CONTAINERS, variables: { zone: zone } }],
    onCompleted() {
      Alert("success", "Successfully created container costs");
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
  });
  const { loading, error, data } = useQuery(FETCH_CONTAINERS, {
    variables: { zone: zone },
  });

  const handleInputChange = (newCost, container) => {
    const newContainers = [...containers];

    if (newCost === "") {
      // ignore the container if the new cost is empty
      const updatedContainers = newContainers.filter(
        (c) => c.name !== container.name,
      );
      setContainers(updatedContainers);
    } else {
      const cost = parseInt(newCost);
      const existingIndex = newContainers.findIndex(
        (c) => c.name === container.name,
      );

      // add the new container or update the cost of the existing container
      if (existingIndex === -1) {
        newContainers.push({
          name: container.name,
          cbm: container.cbm,
          cost: cost,
          zone: container.zone,
        });
      } else {
        newContainers[existingIndex] = {
          ...newContainers[existingIndex],
          cost: cost,
        };
      }

      setContainers(newContainers);
    }
  };

  useEffect(() => {
    setContainers([]);
  }, [zone]);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  return (
    <div className="space-y-4">
      <div className="flex space-x-2 items-center">
        <label>Shipping Zone:</label>
        <Select
          value={zone}
          onChange={(e) => setZone(e.target.value)}
          className="w-24 text-xs"
        >
          {shippingZones.map((z, index) => (
            <option key={index} value={z}>
              {z === "Shipping cost(EU)" ? "EU" : "US"}
            </option>
          ))}
        </Select>
      </div>

      <div className="border border-gray-200 border-dashed" />

      {data.containers.map((c, index) => (
        <div key={index} className="flex items-center ">
          <div className="w-2 h-2 bg-sky-400 rounded-full mr-2" />

          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="font-bold">{c.name}</span>
              <span className="ml-2">({c.cbm} m³)</span>
            </div>

            <div className="flex items-center">
              <div>
                <span className="font-bold">{c.cost}</span>
                <span className="ml-2">USD</span>
              </div>

              <FaArrowRightLong className="text-xs mx-6" />

              <div>
                <Input
                  className="w-24"
                  type="number"
                  min={0}
                  value={
                    containers.find((container) => container.name === c.name)
                      ?.cost || ""
                  }
                  onChange={(e) => handleInputChange(e.target.value, c)}
                />
                <span className="ml-2">USD</span>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="flex items-center space-x-2">
        <CharlesButton
          onClick={() => {
            createContainerCost({
              variables: { containerInputs: containers, zone: zone },
            });
          }}
          className="border border-sky-300 text-sky-500 hover:text-sky-800 hover:bg-sky-500 hover:border-sky-500 active:border-sky-700 text-xs px-2 py-1 rounded-md"
        >
          Save
        </CharlesButton>

        <CharlesButton
          onClick={hide}
          className="text-xs px-2 py-1 rounded-md text-gray-400"
        >
          Cancel
        </CharlesButton>
      </div>
    </div>
  );
};

export default ContainerizationView;
