import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import CreateCommercialInvoice from "./docs/commercialInvoice/create";
import ShipmentOverview from "./overview";
import Reports from "./reports";
import ErrorCodes from "./ErrorCodes";
import ShipmentUpdateErrors from "./shipments/status/ShipmentUpdateErrors";
import ExportShipments from "./ExportShipments";
import ShipmentDetail from "./shipments/Detail";
import ShipmentList from "./shipments/List";
import PackingListPreview from "./docs/PackingListPreview";
import CustomFormPreview from "./docs/CustomFormPreview";
import InBoundSheetPreview from "./docs/InBoundSheetPreview";
import CommercialInvoiceList from "./docs/commercialInvoice/list";
import CommercialInvoicePreview from "./docs/commercialInvoice/preview";
import EditCommercialInvoice from "./docs/commercialInvoice/edit";
import DocList from "./docs/DocList";
import { LeftButtons, RightButtons } from "./Header";
import Page from "components/Page";

function ShipmentRoutes() {
  return (
    <Routes>
      <Route
        element={
          <Page leftButtons={<LeftButtons />} rightButtons={<RightButtons />}>
            <Outlet />
          </Page>
        }
      >
        <Route path="shipments" element={<ShipmentList />} />

        <Route path="doc">
          <Route
            path="commercial-invoice"
            element={<CommercialInvoiceList />}
          />
          <Route path=":docType" element={<DocList />} />
        </Route>

        <Route path="overview" element={<ShipmentOverview />} />
        <Route path="reports" element={<Reports />} />
        <Route path="error-codes" element={<ErrorCodes />} />
        <Route path="update-errors" element={<ShipmentUpdateErrors />} />
        <Route path="export" element={<ExportShipments />} />
        <Route path="*" element={<Navigate to="shipments" />} />
      </Route>

      <Route path="doc">
        <Route path="commercial-invoice">
          <Route path=":id" element={<CommercialInvoicePreview />} />
          <Route path=":id/edit" element={<EditCommercialInvoice />} />
        </Route>
        <Route path="packinglist/:id" element={<PackingListPreview />} />
        <Route path="customform/:id" element={<CustomFormPreview />} />
        <Route path="inbound/:id" element={<InBoundSheetPreview />} />
      </Route>
      <Route
        path="create-commercial-invoice/:id"
        element={<CreateCommercialInvoice />}
      />

      <Route path="shipments/:id" element={<ShipmentDetail />} />
    </Routes>
  );
}

export default ShipmentRoutes;
