import { gql } from "@apollo/client";

export const FETCH_COLLECTION = gql`
  query FETCH_COLLECTION($name: String) {
    anyCollection(name: $name) {
      id
      descendants {
        id
        name
        productLines {
          id
          name
          slug
          activeForBiz
          newImages {
            id
            name
            thumb: url(size: "300x300")
            url
            stage
            isPrimary
            ordering
          }
        }
      }
    }
  }
`;

export const SET_PRODUCT_LINE_IMAGE_PRIMARY = gql`
  mutation SET_PRODUCT_LINE_IMAGE_PRIMARY(
    $productLineId: ID!
    $productLineImageId: ID!
    $stage: String!
  ) {
    setProductLineImagePrimary(
      productLineId: $productLineId
      productLineImageId: $productLineImageId
      stage: $stage
    ) {
      productLine {
        id
        newImages {
          id
          name
          thumb: url(size: "300x300")
          stage
          isPrimary
        }
      }
    }
  }
`;

export const SORT_PRODUCT_LINE_IMAGES = gql`
  mutation SORT_PRODUCT_LINE_IMAGES(
    $ordering: [ProductLineImageSortInputType]!
  ) {
    sortProductLineImages(ordering: $ordering) {
      productLine {
        id
        newImages {
          id
          name
          ordering
          thumb: url(size: "300x300")
        }
      }
    }
  }
`;

export const SYNC_PRODUCT_LINE_IMAGES = gql`
  mutation SYNC_PRODUCT_LINE_IMAGES($slug: String) {
    syncProductLineBeautyshots(slug: $slug) {
      productLine {
        id
        newImages {
          id
          name
          ordering
          thumb: url(size: "300x300")
        }
      }
    }
  }
`;
