import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Button } from "../../../components/base.js";
import BackButton from "../../../components/BackButton.js";
import Spinner from "../../../components/Spinner.js";
import Errors from "../../../components/Errors.js";
import PageContainer from "../../../components/PageContainer.js";
import http from "../../../utils/http";
import { FETCH_DOC } from "./graphql";

function InBoundSheetPreview() {
  const { id } = useParams();
  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const { loading, error, data } = useQuery(FETCH_DOC, { variables: { id } });

  const exportPdf = (id, filename) => {
    setDownloadingPdf(true);
    http
      .get(`/doc/${id}/`, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingPdf(false);
      })
      .catch((err) => {
        alert(err);
        setDownloadingPdf(false);
      });
  };

  const exportExcel = (id, filename) => {
    setDownloadingExcel(true);
    http
      .get(`/doc/${id}/?excel=1`, {
        baseURL: process.env.REACT_APP_SERVER_BASE_URL,
        timeout: 60000,
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadingExcel(false);
      })
      .catch((err) => {
        alert(err);
        setDownloadingExcel(false);
      });
  };

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  const { shipment, html } = data.document;
  return (
    <PageContainer
      backButton={<BackButton color="white" to="/shipment/doc/inbound" />}
      rightButtons={
        <div className="flex">
          <Button
            size="sm"
            color="white"
            className="mr-4"
            title="Edit Shipment"
            link={`/shipment/shipments/${shipment.id}`}
          />
          <Button
            size="sm"
            className="mr-4"
            color="white"
            title={downloadingPdf ? "Exporting..." : "Export PDF"}
            disabled={downloadingPdf}
            loading={downloadingPdf}
            onClick={(_) =>
              exportPdf(id, `PackingList #${shipment.invoiceNumber}.pdf`)
            }
          />
          <Button
            size="sm"
            color="white"
            title={downloadingExcel ? "Exporting..." : "Export Excel"}
            disabled={downloadingExcel}
            loading={downloadingExcel}
            onClick={(_) =>
              exportExcel(id, `Inbound Sheet #${shipment.invoiceNumber}.xlsx`)
            }
          />
        </div>
      }
      title={`#${shipment.invoiceNumber} - ${shipment.customer.name}`}
      content={
        <div className="flex flex-col flex-1">
          <iframe
            title="PackingLists Preview"
            className="flex-1 w-full bg-white overflow-auto"
            srcDoc={html}
            frameBorder="0"
          />
        </div>
      }
    />
  );
}

export default InBoundSheetPreview;
