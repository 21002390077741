import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import moment from "moment";

const FETCH_PRODUCT_LISTS_WITH_TEST_REPORTS = gql`
  query FETCH_PRODUCT_LISTS_WITH_TEST_REPORTS {
    productLists(withTestReportsOnly: true) {
      total
      results {
        id
        name
        version
        testReports {
          id
          standard {
            id
            name
          }
          createdAt
        }
      }
    }
  }
`;

const TestReportsPage = () => {
  const { loading, error, data } = useQuery(
    FETCH_PRODUCT_LISTS_WITH_TEST_REPORTS,
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let standards = [];

  const res = data.productLists.results.map((i) => {
    let newItem = { ...i };
    for (let j = 0; j < i.testReports.length; j++) {
      newItem[i.testReports[j].standard.name] = i.testReports[j].createdAt;
      if (!standards.includes(i.testReports[j].standard.name))
        standards.push(i.testReports[j].standard.name);
    }
    return newItem;
  });

  function isExpired(standard, date) {
    const expireDate = standard === "EN71-1/2/3" ? 1 : 2;
    return moment().diff(moment(date), "years") >= expireDate;
  }

  return (
    <Page
      title="Test Reports"
      subtitle="Test reports for product lists and expire state. Except 1 year for EN71-1/2/3, 2 years for other standards. The expired reports are highlighted in red."
      className="h-screen flex flex-col"
      childrenClassName="flex-1 overflow-auto"
    >
      <table>
        <thead>
          <tr className="sticky top-0 bg-gray-100 dark:bg-gray-900 z-10 whitespace-nowrap">
            <th className="border dark:border-gray-700 border-t-0 px-6 py-2 sticky left-0 bg-gray-100 dark:bg-gray-900">
              Product
            </th>
            {standards.map((i) => (
              <th
                key={i}
                className="border dark:border-gray-700 border-t-0 px-4 py-2"
              >
                {i}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {res.map((i) => (
            <tr key={i.id}>
              <td className="border dark:border-gray-700 px-6 py-2 sticky left-0 bg-gray-50 dark:bg-gray-900 whitespace-nowrap">
                {i.name} <span className="opacity-50">v{i.version}</span>
              </td>
              {standards.map((j) => (
                <td
                  className={`border dark:border-gray-700 px-4 py-2 bg-white dark:bg-gray-800 whitespace-nowrap
                    ${i[j] && isExpired(j, i[j]) ? "text-red-600 font-bold" : ""}
                    `}
                >
                  {i[j] ? moment(i[j]).format("YYYY-MM-DD") : "-"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Page>
  );
};

export default TestReportsPage;
