import { useMutation, useQuery } from "@apollo/client";
import Spinner, { InlineSpinner } from "components/Spinner.js";
import Errors from "components/Errors.js";
import {
  CREATE_STOCK,
  FETCH_CHINA_STOCKS,
  FETCH_CHINA_STOCK_OVERVIEW,
} from "./graphql";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SearchBar from "components/SearchBar";
import { Fragment, useState } from "react";
import { searchByProp } from "utils/search";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

function ChinaStocks() {
  const { loading, error, data } = useQuery(FETCH_CHINA_STOCKS);
  const [createStock, createStockRes] = useMutation(CREATE_STOCK, {
    refetchQueries: [{ query: FETCH_CHINA_STOCKS }],
    onCompleted(res) {
      Alert("success", "Stock is created");
      navigate(`${res.createStock.stock.id}`);
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });
  const fetchChinaStockOverview = useQuery(FETCH_CHINA_STOCK_OVERVIEW);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const params = Object.fromEntries(searchParams.entries());
  const q = searchParams.get("q") || "";

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let filteredData = data.odooStocks
    .map((i) => {
      const combined =
        i.name + " - " + i.products.map((i) => i.number).join(", ");
      return { ...i, combined };
    })
    .filter((i) => searchByProp(i, ["combined"], q));

  function tryAddProductToStock() {
    const name = window.prompt("Input the name of the stock category:");
    if (!name) return;
    createStock({ variables: { name } });
  }

  let chinaStockOverview = fetchChinaStockOverview.data?.chinaStockOverview;
  if (chinaStockOverview) {
    chinaStockOverview = JSON.parse(chinaStockOverview);
    filteredData = filteredData.map((i) => ({
      ...i,
      products: i.products.map((j) => ({
        ...j,
        odooFc: chinaStockOverview[`${j.odooId} ODOO Forecasted`],
        ssFc: chinaStockOverview[`${j.odooId} SS Forecasted`],
        fc: chinaStockOverview[`${j.odooId} Forecasted`],
      })),
    }));
  }
  // console.log("chinaStockOverview", chinaStockOverview);
  // console.log("filteredData", filteredData);

  const rowStyle = (product) => {
    if (product.fc < 0) return "bg-red-100 text-red-600";
    return "";
  };

  return (
    <div className="p-6 flex-1 overflow-auto">
      <div className="card p-4 animate-fadeInDown h-full overflow-auto flex flex-col">
        <div className="flex justify-between place-items-center space-x-6">
          <SearchBar
            containerClassName="flex-1"
            placeholder="Search by name"
            value={q}
            onChange={(text) => setSearchParams({ ...params, q: text })}
          />
          {fetchChinaStockOverview.loading && (
            <div className="relative">
              <InlineSpinner size={14} text={null} />
            </div>
          )}
          <CharlesButton
            onClick={tryAddProductToStock}
            loading={createStockRes.loading}
          >
            + Add New Stock
          </CharlesButton>
        </div>
        <div className="mt-4 -mx-4 flex-1 overflow-auto">
          <table>
            <tbody className="divide-y dark:divide-gray-700">
              {filteredData.map((stock, stockIndex) => (
                <Fragment key={stockIndex}>
                  <tr
                    className="bg-indigo-100 font-semibold sticky top-0 animate-fadeInDown opacity-0 z-10"
                    style={{
                      animationDelay: `${stockIndex * 0.035}s`,
                    }}
                  >
                    <td className="px-6 whitespace-nowrap">
                      <Link to={`${stock.id}?q=${q}`}>{stock.name}</Link>
                    </td>
                    <td className="text-right opacity-70 px-6">
                      Odoo Forecasted
                    </td>
                    <td className="text-right opacity-70 px-6">
                      SS Forecasted
                    </td>
                    <td className="text-right opacity-70 px-6">Forecasted</td>
                  </tr>
                  {stock.products.map((product, index) => (
                    <tr
                      key={index}
                      className={`animate-fadeInDown opacity-0 z-10 ${rowStyle(product)}`}
                      style={{
                        animationDelay: `${(stockIndex + index) * 0.035}s`,
                      }}
                    >
                      <td className="px-6">
                        <Link
                          to={`${stock.id}?productOdooId=${product.odooId}&q=${q}`}
                        >
                          {product.number}
                        </Link>
                      </td>
                      <td className="text-right px-6">{product.odooFc}</td>
                      <td className="text-right px-6">{product.ssFc}</td>
                      <td className="text-right px-6">{product.fc}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ChinaStocks;
