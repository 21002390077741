import React, { createContext, useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { client } from "./apollo.js";

import "react-toastify/dist/ReactToastify.min.css";
import "react-day-picker/lib/style.css";
import "react-medium-image-zoom/dist/styles.css";
import SentryFallbackComponent from "components/SentryFallbackComponent.js";
import { setTheme } from "utils/theme.js";
import { SUPER_USER } from "utils/permissions";

//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
  api_host: "https://x.waboba.com",
  secure_cookie: true,
});

if (!localStorage.getItem("metric")) localStorage.setItem("metric", 1);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2d6290864ed84cfdbf9b7810178a4db6@o4505047899766784.ingest.sentry.io/4505047900815360",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_VERSION,
  });
}

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState({
    metric: localStorage.getItem("metric") === "1",
  });
  const [windowWidth] = useState(window.innerWidth);
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [showSmallMenu, setShowSmallMenu] = useState(false);

  useEffect(() => {
    localStorage.setItem("metric", settings.metric ? "1" : "0");
  }, [settings]);

  useEffect(() => {
    if (modals.length > 0) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [modals]);

  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, []);

  const hasPermission = (permission = SUPER_USER) => {
    if (!user) return false;
    // super user has all permissions
    if (user.groups.some((group) => group.name === SUPER_USER)) return true;

    return user.groups.some((group) => group.name === permission);
  };

  return (
    <AppContext.Provider
      value={{
        modals,
        setModals,
        settings,
        setSettings,
        windowWidth,
        user,
        setUser,
        showMainMenu,
        setShowMainMenu,
        showSmallMenu,
        setShowSmallMenu,
        hasPermission,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

function App() {
  return (
    <AppProvider>
      <ApolloProvider client={client}>
        <Sentry.ErrorBoundary fallback={<SentryFallbackComponent />}>
          <BrowserRouter>
            <AppRoutes />
            <ToastContainer />
          </BrowserRouter>
        </Sentry.ErrorBoundary>
      </ApolloProvider>
    </AppProvider>
  );
}

export default App;
