import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "../../../components/base.js";
import { Text, Select, DatePicker } from "../../../components/Form.js";
import { formatDate, parseDate } from "react-day-picker/moment";
import { CREATE_SHIPMENT_STATUS } from "../graphql";
import ShipmentContext from "./ShipmentContext";

function StatusForm({ initialStatus, options }) {
  const { state, dispatch } = useContext(ShipmentContext);
  const [status, setStatus] = useState(initialStatus);
  const [notes, setNotes] = useState("");
  const [created, setCreated] = useState(+new Date() / 1000);
  const [showForm, setShowForm] = useState(false);

  const [createShipmentStatus, { loading }] = useMutation(
    CREATE_SHIPMENT_STATUS,
    {
      onCompleted: (data) => {
        dispatch({
          type: "didCreateShipmentStatus",
          payload: data.createShipmentStatus.shipmentStatus,
        });
        setShowForm(false);
        setNotes("");
        setCreated(+new Date() / 1000);
      },
    },
  );

  const onCancel = () => {
    setStatus(initialStatus);
    setShowForm(false);
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="text-base font-bold">Status: </div>
        <Select
          className="ml-4 bg-white"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
            setShowForm(true);
          }}
        >
          <option value="0" disabled={true}>
            -- Select Status --
          </option>
          {options.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </Select>
      </div>
      {showForm && (
        <div className="mt-4 rounded-lg p-4 lg:p-6 bg-gray-100 dark:bg-gray-900 border border-gray-200 dark:border-gray-800">
          <div>
            <label className="pr-4">Date: </label>
            <div className="mt-2">
              <DatePicker
                parseDate={parseDate}
                formatDate={formatDate}
                format="YYYY-MM-DD"
                value={formatDate(created * 1000, "YYYY-MM-DD")}
                onDayChange={(selectedDay, modifiers) => {
                  if (selectedDay) setCreated(new Date(selectedDay) / 1000);
                }}
              />
            </div>
          </div>
          <div className="mt-2">
            <Text
              rows={3}
              async={true}
              placeholder="Shipment Status Notes.."
              className="mt-2"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <div className="mt-2 flex space-x-4">
            <Button
              bold
              disabled={loading}
              loading={loading}
              title={loading ? "Saving..." : "Save"}
              onClick={() => {
                createShipmentStatus({
                  variables: {
                    shipmentId: state.id,
                    status,
                    notes,
                    created,
                  },
                });
              }}
            />
            <Button className="ml-2" title="Cancel" onClick={onCancel} />
          </div>
        </div>
      )}
    </div>
  );
}

export default StatusForm;
