import { useContext, useEffect, useState } from "react";
import { Field, Input, Select, Switcher, Text } from "components/Form";
import { gql, useMutation, useQuery } from "@apollo/client";
import CollectionSelector from "../CollectionSelector";
import ProductWarningsSelector from "./ProductWarningsSelector";
import { FRAGMENT_PRODUCT_LINE_DETAIL } from "./graphql";
import { Alert } from "components/Toast";
import ProductListSelector from "./ProductListSelector";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { FETCH_PRODUCT_LINES } from "./ProductLines";
import MaterialsInput from "./MaterialsInput";
import CharlesButton from "components/charles/base";
import { AppContext } from "App";
import { MANAGE_EDITOR_PARTIAL } from "utils/permissions";

const newSimpleFields = {
  name: "",
  nameForOdooTemplate: "",
  number: "",
  hsCode: "",
  hsCodeForEu: "",
  hsCodeForUs: "",
  age: "",
  pantone: "",
  printingMethod: "",
  customsName: "",
  customsBrand: "",
  customsUsage: "",
  customsValue: 0,
  customsSize: "",
  customsMaterials: "",
  activeForWis: true,
  activeForBiz: false,
  fromProductListId: null,
  description: "",
};

const SAVE_PRODUCT_LINE = gql`
  mutation SAVE_PRODUCT_LINE(
    $collectionIds: [ID!]!
    $id: ID
    $simpleFields: ProductLineSimpleFields
    $materials: [ProductLineMaterialInputType]
    $childrenIds: [ID]
    $warningIds: [ID]
    $labTestStandardIds: [ID]
  ) {
    saveProductLine(
      collectionIds: $collectionIds
      id: $id
      simpleFields: $simpleFields
      materials: $materials
      warningIds: $warningIds
      childrenIds: $childrenIds
      labTestStandardIds: $labTestStandardIds
    ) {
      productLine {
        ...productLine
      }
    }
  }
  ${FRAGMENT_PRODUCT_LINE_DETAIL}
`;

const ProductLineForm = ({
  id,
  fetchedProductLine,
  onSave,
  duplicate,
  duplicatingProduct,
}) => {
  const { hasPermission } = useContext(AppContext);

  const [collectionIds, setCollectionIds] = useState([]);
  const [simpleFields, setSimpleFields] = useState(newSimpleFields);
  const [materials, setMaterials] = useState([]);
  const [warningIds, setWarningIds] = useState([]);
  const [childrenIds, setChildrenIds] = useState([]);
  const [labTestStandardIds, setLabTestStandardIds] = useState([]);

  const [isCombo, setIsCombo] = useState(false);

  const [saveProductLine, saveProductLineRes] = useMutation(SAVE_PRODUCT_LINE, {
    onCompleted: (res) => {
      Alert("success", "Saved.");
      if (onSave) onSave(res.saveProductLine.productLine);
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: id === null ? [{ query: FETCH_PRODUCT_LINES }] : [],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (fetchedProductLine) {
      const line = fetchedProductLine;
      setSimpleFields({
        name: line.name,
        number: line.number,
        nameForOdooTemplate: line.nameForOdooTemplate,
        hsCode: line.hsCode,
        hsCodeForEu: line.hsCodeForEu,
        hsCodeForUs: line.hsCodeForUs,
        age: line.age,
        pantone: line.pantone,
        printingMethod: line.printingMethod,
        customsName: line.customsName,
        customsBrand: line.customsBrand,
        customsUsage: line.customsUsage,
        customsValue: line.customsValue,
        customsSize: line.customsSize,
        customsMaterials: line.customsMaterials,
        activeForWis: line.activeForWis,
        activeForBiz: line.activeForBiz,
        fromProductListId: line.fromProductList?.id,
        description: line.description,
      });
      setMaterials(
        line.materials.map((i) => ({
          name: i.material.name,
          percentage: i.percentage,
          remark: i.remark,
        })),
      );
      setWarningIds(line.warnings.map((i) => i.id));
      setLabTestStandardIds(line.labTestStandards.map((i) => i.id));
      setCollectionIds(
        line.anyCollections.length === 0
          ? ["0"]
          : line.anyCollections.map((i) => i.id),
      );
      setIsCombo(line.children.length > 0);
      setChildrenIds(line.children.map((i) => i.id));
    }
  }, [fetchedProductLine]);

  useEffect(() => {
    if (duplicatingProduct) {
      setCollectionIds(duplicatingProduct.collectionIds);
      setSimpleFields(duplicatingProduct.simpleFields);
      setMaterials(duplicatingProduct.materials);
      setWarningIds(duplicatingProduct.warningIds);
      setLabTestStandardIds(duplicatingProduct.labTestStandardIds);
      setIsCombo(duplicatingProduct.isCombo);
      setChildrenIds(duplicatingProduct.childrenIds);
    }
  }, [duplicatingProduct]);

  function onChangeSimpleFields(field, value) {
    setSimpleFields((prev) => ({ ...prev, [field]: value }));
  }

  function validInput() {
    if (simpleFields.name === "") {
      Alert("error", "At least give me a name...");
      return false;
    }
    if (simpleFields.number === "") {
      Alert("error", "Item number is required.");
      return false;
    }
    if (collectionIds.filter((i) => i !== "0").length === 0) {
      Alert("error", "Each product line should be in a collection.");
      return false;
    }
    return true;
  }

  const variables = {
    id,
    simpleFields: {
      ...simpleFields,
      age: simpleFields.age !== "" ? simpleFields.age : 0,
    },
    warningIds,
    materials: materials.map((i) => ({
      ...i,
      percentage: i.percentage ? i.percentage : 0,
      remark: i.remark ? i.remark : "",
    })),
    childrenIds,
    labTestStandardIds,
    collectionIds,
  };

  return (
    <div className="space-y-8 p-10 pb-0 text-sm h-full">
      {hasPermission() ? (
        <>
          <div className="grid grid-cols-12 gap-8">
            <div className="card px-6 py-4 col-span-8">
              <h4>Base Info</h4>
              <div className="mt-4 space-y-4">
                <Field
                  label="name"
                  value={simpleFields.name}
                  onChange={(value) => onChangeSimpleFields("name", value)}
                />
                <Field
                  label="number"
                  value={simpleFields.number}
                  onChange={(value) => onChangeSimpleFields("number", value)}
                  tips="The core number without considering the package part. For example: 101, 102, 301, 302."
                />

                <Field
                  label="Name for Odoo Template"
                  value={simpleFields.nameForOdooTemplate}
                  onChange={(value) =>
                    onChangeSimpleFields("nameForOdooTemplate", value)
                  }
                  tips="This field is used to support have different name between wis and odoo for the same odoo template."
                />

                <section className="space-y-4">
                  <div className="flex space-x-4 items-center">
                    <label>HS Code:</label>
                    <Input
                      placeholder="HS Code"
                      value={simpleFields.hsCode}
                      onChange={(e) =>
                        onChangeSimpleFields("hsCode", e.target.value)
                      }
                    />

                    <label>EU:</label>
                    <Input
                      placeholder="HS Code for EU"
                      value={simpleFields.hsCodeForEu}
                      onChange={(e) =>
                        onChangeSimpleFields("hsCodeForEu", e.target.value)
                      }
                    />

                    <label>US:</label>
                    <Input
                      placeholder="HS Code for US"
                      value={simpleFields.hsCodeForUs}
                      onChange={(e) =>
                        onChangeSimpleFields("hsCodeForUs", e.target.value)
                      }
                    />
                  </div>

                  <div className="flex items-center space-x-2">
                    <label htmlFor="">Age Grading:</label>
                    <Select
                      value={simpleFields.age}
                      onChange={(e) =>
                        onChangeSimpleFields("age", e.target.value)
                      }
                    >
                      {[...Array(61).keys()].map((i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <Field
                    label="pantone"
                    value={simpleFields.pantone}
                    onChange={(value) => onChangeSimpleFields("pantone", value)}
                  />

                  <Field
                    label="Printing Method"
                    value={simpleFields.printingMethod}
                    onChange={(value) =>
                      onChangeSimpleFields("printingMethod", value)
                    }
                  />

                  <div className="flex space-x-4 items-baseline">
                    <label htmlFor="">Materials: </label>
                    <MaterialsInput
                      className="flex-1"
                      materials={materials}
                      setMaterials={setMaterials}
                    />
                  </div>

                  <div className="flex items-baseline space-x-4">
                    <label>Warnings: </label>
                    <ProductWarningsSelector
                      warningIds={warningIds}
                      setWarningIds={setWarningIds}
                    />
                  </div>
                </section>

                <div className="flex space-x-4 items-baseline">
                  <label className="capitalize w-auto">description: </label>
                  <Text
                    placeholder="Input description here..."
                    value={simpleFields.description}
                    onChange={(e) => {
                      onChangeSimpleFields("description", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-4 space-y-8">
              <div className="card px-6 py-4">
                <h4>Collections</h4>
                <div className="mt-1 text-xs opacity-70">
                  Collection helps us to organize our products to match our
                  marketting catelog. We use this catelog in our cost pages too.
                </div>
                <div className="mt-4">
                  <CollectionSelector
                    ids={collectionIds}
                    onChange={setCollectionIds}
                  />
                </div>
              </div>

              <div className="card px-6 py-4">
                <h4>Product List</h4>
                <div className="mt-1 text-xs opacity-70">
                  Product List is currently used for displaying test reports in
                  BIZ.
                </div>
                <div className="mt-4">
                  <ProductListSelector
                    value={simpleFields.fromProductListId}
                    onChange={(id) =>
                      onChangeSimpleFields("fromProductListId", id)
                    }
                  />
                </div>
              </div>

              <div className="card px-6 py-4">
                <h4>Status</h4>
                <div className="mt-4 space-y-4">
                  <div className="flex space-x-4 items-center">
                    <label htmlFor="">Active for WIS: </label>
                    <Switcher
                      isOn={simpleFields.activeForWis}
                      onChange={() =>
                        onChangeSimpleFields(
                          "activeForWis",
                          !simpleFields.activeForWis,
                        )
                      }
                    />
                  </div>

                  <div className="flex space-x-4 items-center">
                    <label htmlFor="">Active for BIZ: </label>
                    <Switcher
                      isOn={simpleFields.activeForBiz}
                      onChange={() =>
                        onChangeSimpleFields(
                          "activeForBiz",
                          !simpleFields.activeForBiz,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card px-6 py-4">
            <h4>Customs Info</h4>
            <div className="space-y-4 mt-4">
              <div className="flex space-x-8 mt-6">
                <Field
                  label="Name"
                  value={simpleFields.customsName}
                  onChange={(value) =>
                    onChangeSimpleFields("customsName", value)
                  }
                />
                <Field
                  label="Brand"
                  value={simpleFields.customsBrand}
                  onChange={(value) =>
                    onChangeSimpleFields("customsBrand", value)
                  }
                />
                <Field
                  label="Usage"
                  value={simpleFields.customsUsage}
                  onChange={(value) =>
                    onChangeSimpleFields("customsUsage", value)
                  }
                />

                <Field
                  label="Value"
                  value={simpleFields.customsValue}
                  onChange={(value) =>
                    onChangeSimpleFields("customsValue", value)
                  }
                />
              </div>

              <Field
                label="Size"
                value={simpleFields.customsSize}
                onChange={(value) => onChangeSimpleFields("customsSize", value)}
              />

              <Field
                label="Materials"
                value={simpleFields.customsMaterials}
                onChange={(value) =>
                  onChangeSimpleFields("customsMaterials", value)
                }
              />

              <div className="opacity-70 text-xs">
                We will use the item size in customs form. For materials we will
                setup cn for it later.
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card px-6 py-4 col-span-8">
            <h4>Base Info</h4>
            <div className="mt-4 space-y-4">
              <Field label="name" value={simpleFields.name} />

              <div className="flex space-x-4 items-baseline">
                <label className="capitalize w-auto">description: </label>
                <Text
                  placeholder="Input description here..."
                  value={simpleFields.description}
                  onChange={(e) => {
                    onChangeSimpleFields("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <section className="flex space-x-8 sticky bottom-0 -mx-10 px-10 py-4 dark:bg-opacity-70 dark:bg-gray-800 backdrop-blur-lg text-base border-t dark:border-gray-700">
        <CharlesButton
          loading={saveProductLineRes.loading}
          onClick={() => {
            if (validInput()) saveProductLine({ variables });
          }}
        >
          Save
        </CharlesButton>

        {id && hasPermission() ? (
          <CharlesButton
            onClick={() =>
              duplicate({
                collectionIds,
                simpleFields: {
                  ...simpleFields,
                  name: `${simpleFields.name} duplicated`,
                  number: simpleFields.number + " duplicated",
                },
                materials,
                warningIds,
                childrenIds,
                labTestStandardIds,
                isCombo,
              })
            }
          >
            Duplicate
          </CharlesButton>
        ) : null}
      </section>
    </div>
  );
};

const FETCH_PRODUCT_LINE_DETAIL = gql`
  query FETCH_PRODUCT_LINE_DETAIL($id: ID!) {
    productLine(id: $id) {
      ...productLine
    }
  }
  ${FRAGMENT_PRODUCT_LINE_DETAIL}
`;

const ProductLineFormLoader = ({ id = null, ...rest }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCT_LINE_DETAIL, {
    variables: { id },
    skip: id === null,
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <ProductLineForm
      id={id}
      fetchedProductLine={data ? data.productLine : null}
      {...rest}
    />
  );
};

export default ProductLineFormLoader;
