import { qtyTypes } from "./consts";

export function prepareData(data) {
  const res = JSON.parse(data);
  const products = Object.entries(res).reduce((acc, [id, product]) => {
    acc[id] = product;
    const month = Array(12)
      .fill()
      .map((_, i) => i + 1);
    month.forEach((m) => {
      acc[id][m] = {};
      qtyTypes.forEach((qtyType) => {
        acc[id][m][qtyType] = { qty: "" };
      });
    });
    return acc;
  }, {});
  console.log("prepareData.products", products);
  return products;
}

export const computedForecastData = (products, shipmentLines) =>
  Object.keys(products).reduce((acc, id) => {
    acc[id] = { sku: products[id].sku };
    Array(12)
      .fill()
      .map((_, i) => {
        const month = i + 1;
        let beginInv = parseInt(products[id][month].begin_inv.qty);
        let prevEndInv = 0;

        if (isNaN(beginInv)) {
          beginInv = 0;
          if (month > 1) {
            prevEndInv = parseInt(acc[id][month - 1].endInv);
            beginInv = prevEndInv;
          }
        }

        let endInv = beginInv;

        const productShipmentLines = shipmentLines.filter((line) => {
          const shipmentMonth = new Date(line.shipment.date).getMonth() + 1;
          return line.product.id === id && shipmentMonth === month;
        });

        const inbounds = productShipmentLines.filter(
          (i) => i.shipment.shipmentType === "INBOUND",
        );
        let inboundState = "PENDING";
        const completedInbounds = inbounds.filter(
          (i) => i.shipment.state === "COMPLETED",
        );
        if (
          completedInbounds.length > 0 &&
          completedInbounds.length === inbounds.length
        ) {
          inboundState = "COMPLETED";
        }

        const inboundQty = inbounds.reduce(
          (acc, line) => acc + parseInt(line.qty),
          0,
        );

        const inbound = {
          qty: inboundQty,
          shipments: inbounds.map((line) => line.shipment),
          state: inboundState,
          description: inbounds.reduce((acc, { shipment }, index) => {
            return (
              acc +
              `${index > 0 ? "\n" : ""}[${shipment.shipmentType}] ${shipment.name} - ${shipment.date} (${shipment.state})`
            );
          }, ""),
        };

        endInv += inboundQty;

        const outbounds = productShipmentLines.filter(
          (i) => i.shipment.shipmentType === "OUTBOUND",
        );
        const outboundQty = outbounds.reduce(
          (acc, line) => acc + parseInt(line.qty),
          0,
        );

        let outboundState = "PENDING";
        const completedOutbounds = outbounds.filter(
          (i) => i.shipment.state === "COMPLETED",
        );
        if (
          completedOutbounds.length > 0 &&
          completedOutbounds.length === outbounds.length
        ) {
          outboundState = "COMPLETED";
        }

        const outbound = {
          qty: outboundQty,
          shipments: outbounds.map((line) => line.shipment),
          state: outboundState,
          description: outbounds.reduce((acc, { shipment }, index) => {
            return (
              acc +
              `${index > 0 ? "\n" : ""}[${shipment.shipmentType}] ${shipment.name} - ${shipment.date} (${shipment.state})`
            );
          }, ""),
        };

        endInv -= outboundQty;

        const sales = parseInt(products[id][month].sales.qty);
        if (!isNaN(sales)) {
          endInv -= sales;
        } else {
          let forecast = parseInt(products[id][month].forecast.qty);
          if (!isNaN(forecast)) endInv -= forecast;
        }

        acc[id][month] = { endInv, prevEndInv, inbound, outbound };
      });
    return acc;
  }, {});
