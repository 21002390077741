import gql from "graphql-tag";
import { PRODUCT_BASE_INFO_FRAGMENT } from "../products/v3/graphql";

export const FRAGMENT_PRODUCT_LIST = gql`
  fragment productList on ProductListType {
    id
    name
    parent {
      id
    }
    latestVersion {
      id
      version
      state
      updatedAt
      updatedBy {
        id
        email
      }
    }
    number
    created
  }
`;

export const FRAGMENT_PRODUCT_LIST_DETAIL = gql`
  fragment productListDetail on ProductListType {
    ...productList
    version
    versionNotes
    versions {
      id
      version
    }
    state
    shape
    ballDiameter
    cubeX
    cubeY
    cubeZ
    cylindricalDiameter
    cylindricalHeight
    flatX
    flatY
    sizeNotes
    weight
    weightNotes
    unitWeight
    pantone
    printingMethod
    firstArtworkNumber
    plasticPackagingWeight

    age
    moq
    warnings {
      id
    }

    innerBoxMaterial
    innerBoxX
    innerBoxY
    innerBoxZ

    displayBoxMaterial
    displayBoxX
    displayBoxY
    displayBoxZ

    cartonX
    cartonY
    cartonZ

    qtyPerDisplayBox
    qtyPerCarton

    cartonNetWeight
    cartonGrossWeight

    packagingRemark
    packagingRemarkImages {
      id
      thumb: url(size: "400x400")
      url
    }

    sampleImages {
      id
      thumb: url(size: "400x400")
      url
    }
    attachments {
      id
      type
      url
    }
    materials {
      id
      material {
        id
        name
      }
      weight
      percentage
      remark
    }
    packagings {
      id
      name
      material {
        id
        name
      }
      sizeX
      sizeY
      sizeZ
      weight
      plasticWeight
      notes
    }
    testReports {
      id
      standard {
        id
        name
      }
      fileList
      createdAt
      expiredAt
    }
    priceItems {
      id
      name
      supplier {
        id
        name
      }
      currency
      itemPrices {
        id
        minQty
        value
      }
      notes
    }
    priceNotes
    labTestManager {
      id
      username
    }
    labTestResult
    labTestCreatedAt
    labTestStandards {
      id
      name
    }

    projectManager {
      id
      firstName
    }
    projectManagerUpdatedAt

    productDesigner {
      id
      firstName
    }
    productDesignerUpdatedAt

    packagingDesigner {
      id
      firstName
    }
    packagingDesignerUpdatedAt

    productionManager {
      id
      firstName
    }
    productionManagerUpdatedAt

    merchandiser {
      id
      firstName
    }
    merchandiserUpdatedAt
  }
  ${FRAGMENT_PRODUCT_LIST}
`;

export const CREATE_PRODUCT_LIST = gql`
  mutation CREATE_PRODUCT_LIST($name: String!) {
    createProductList(name: $name) {
      productList {
        ...productList
      }
    }
  }
  ${FRAGMENT_PRODUCT_LIST}
`;

export const FETCH_PRODUCT_LISTS = gql`
  query FETCH_PRODUCT_LISTS {
    allProductLists {
      ...productList
    }
  }
  ${FRAGMENT_PRODUCT_LIST}
`;

export const FETCH_PRODUCT_LIST_DETAIL = gql`
  query FETCH_PRODUCT_LIST_DETAIL($id: ID!) {
    productList(id: $id) {
      ...productListDetail
    }
    allMaterials {
      id
      name
    }
    allFactories {
      id
      nickName
      status
    }
    allLabTestStandards {
      id
      name
    }
    allPriceItems {
      id
      name
      itemPrices {
        id
        minQty
        value
      }
      currency
      supplier {
        id
        name
      }
    }
    allProductWarnings {
      id
      name
    }
  }
  ${FRAGMENT_PRODUCT_LIST_DETAIL}
`;

export const SAVE_PRODUCT_LIST = gql`
  mutation SAVE_PRODUCT_LIST($productListInput: ProductListInputType!) {
    saveProductList(productListInput: $productListInput) {
      productList {
        ...productListDetail
      }
    }
  }
  ${FRAGMENT_PRODUCT_LIST_DETAIL}
`;

export const CREATE_PRODUCT_ITEM_FROM_PRODUCT_LIST = gql`
  mutation CREATE_PRODUCT_ITEM_FROM_PRODUCT_LIST(
    $productListId: ID!
    $name: String!
  ) {
    createProductItemFromProductList(
      productListId: $productListId
      name: $name
    ) {
      productList {
        ...productListDetail
      }
      product {
        ...productBaseInfo
      }
    }
  }
  ${FRAGMENT_PRODUCT_LIST_DETAIL}
  ${PRODUCT_BASE_INFO_FRAGMENT}
`;

export const UPDATE_PRODUCT_ITEMS_FOR_PRODUCT_LIST = gql`
  mutation UPDATE_PRODUCT_ITEMS_FOR_PRODUCT_LIST($productListId: ID!) {
    updateProductItemsFromProductList(productListId: $productListId) {
      products {
        id
        ...productBaseInfo
      }
    }
  }
  ${PRODUCT_BASE_INFO_FRAGMENT}
`;

export const DUPLICATE_PRODUCT_LIST = gql`
  mutation DUPLICATE_PRODUCT_LIST($productListId: ID!, $name: String!) {
    duplicateProductList(productListId: $productListId, name: $name) {
      productList {
        ...productListDetail
      }
    }
  }
  ${FRAGMENT_PRODUCT_LIST_DETAIL}
`;

export const CREATE_PRODUCT_LIST_VERSION = gql`
  mutation CREATE_PRODUCT_LIST_VERSION($productListId: ID!, $version: String!) {
    createProductListVersion(productListId: $productListId, version: $version) {
      productList {
        ...productListDetail
      }
    }
  }
  ${FRAGMENT_PRODUCT_LIST_DETAIL}
`;

export const DELETE_PRODUCT_LIST = gql`
  mutation DELETE_PRODUCT_LIST($productListId: ID!) {
    deleteProductList(productListId: $productListId) {
      deleted
    }
  }
`;
