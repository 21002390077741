import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { FETCH_FORECAST_DOC } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import ForecastDoc from "./ForecastDoc";

const ForecastDocPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_FORECAST_DOC, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (!data) return null;

  return <ForecastDoc originDoc={data.incForecastDoc} />;
};

export default ForecastDocPage;
