import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { Link } from "react-router-dom";
import { FETCH_FORECAST_DOCS } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import NewForecastForm from "./NewForecastForm";
import moment from "moment";

const ForecastPage = () => {
  const { loading, error, data } = useQuery(FETCH_FORECAST_DOCS);
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  function newHandler() {
    modal.present({
      title: "New Forecast",
      children: <NewForecastForm onCreated={onCreated} />,
    });
  }

  function onCreated(res) {
    modal.hide();
    console.log("res", res);
  }

  return (
    <Page title="INC Inventory">
      <div className="m-6">
        <div>
          <CharlesButton onClick={newHandler}>+ New Forecast</CharlesButton>
        </div>

        <div className="card mt-4 p-0 py-2">
          <div className=" whitespace-nowrap">
            <table>
              <tbody className="divide-y dark:divide-gray-700">
                {data.incForecastDocs.map((i) => (
                  <tr key={i.id}>
                    <td className="px-6 w-full">
                      <Link to={i.id}>{i.name}</Link>
                    </td>

                    <td className="px-6 text-right">
                      {moment(i.createdAt).format("YYYY-MM-DD")}
                    </td>
                    <td className="px-6 text-right">{i.user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ForecastPage;
