import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Input, Select } from "components/Form";
import { useState } from "react";
import { INC_CREATE_FORECAST_DOC } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const warehouseOptions = ["AWD"];

function prepareNewData(data) {
  console.log("data", data);
  let res = {};
  for (let key in data) {
    res[key] = { sku: data[key].sku };
    Array.from({ length: 12 }, (_, i) => {
      const month = i + 1;
      res[key][month] = {
        begin_inv: { qty: "" },
        end_inv: { qty: "" },
        forecast: { qty: "" },
        inbound: { qty: "" },
        sales: { qty: "" },
      };
      if (month === 1) {
        res[key][month].begin_inv.qty = data[key][12].endInv;
      }
    });
  }
  return JSON.stringify(res);
}

const DuplicateDocView = ({ sourceDoc, computedData, hide }) => {
  const [doc, setDoc] = useState({
    name: `Copy of ${sourceDoc.name}`,
    warehouse: sourceDoc.warehouse,
    year: sourceDoc.year,
    data: sourceDoc.data,
  });

  const [useAsNew, setUseAsNew] = useState(false);

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 3 }, (_, i) => currentYear + i);

  console.log("prepareNewData(computedData)", prepareNewData(computedData));

  const [save, saveRes] = useMutation(INC_CREATE_FORECAST_DOC, {
    variables: {
      ...doc,
      data: useAsNew ? prepareNewData(computedData) : doc.data,
    },
    onCompleted(res) {
      Alert("success", "New forecast created successfully");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div className=" opacity-70">
        Duplicate from <strong>{sourceDoc.name}</strong>.
      </div>
      <div className="-mx-2 mt-4">
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="">Warehouse: </label>
              </td>
              <td className="w-full">
                <Select
                  value={doc.warehouse}
                  onChange={(e) =>
                    setDoc({ ...doc, warehouse: e.target.value })
                  }
                >
                  {warehouseOptions.map((warehouse) => (
                    <option key={warehouse} value={warehouse}>
                      {warehouse}
                    </option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Name: </label>
              </td>
              <td className="w-full">
                <Input
                  className="w-full"
                  value={doc.name}
                  onChange={(e) => setDoc({ ...doc, name: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="">Year: </label>
              </td>
              <td>
                <Select
                  value={doc.year}
                  onChange={(e) => setDoc({ ...doc, year: e.target.value })}
                >
                  {yearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="flex items-center space-x-2">
                  <label htmlFor="useAsNew" className=" cursor-pointer">
                    Use the final `End Inventory` as the new `Start Inventory`.
                  </label>
                  <input
                    id="useAsNew"
                    type="checkbox"
                    checked={useAsNew}
                    onChange={() => setUseAsNew(!useAsNew)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={save} loading={saveRes.loading}>
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default DuplicateDocView;
