import { gql } from "@apollo/client";

export const FETCH_PRODUCT_GROUPS = gql`
  query FETCH_PRODUCT_GROUPS {
    allProductAreas {
      id
      name
    }
  }
`;

export const FETCH_PRODUCTS_BY_GROUP = gql`
  query FETCH_PRODUCTS_BY_GROUP($groupId: ID!) {
    products(
      productAreas: [$groupId]
      isActive: true
      isSalable: true
      hasOdooId: true
    ) {
      total
      results {
        id
        odooId
        number
        name
        quantityPerCarton
        cbm: outerCartonCbm
        images: computedImages(size: "300x300") {
          id
          url
        }
      }
    }
  }
`;
