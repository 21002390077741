import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { formatDate } from "react-day-picker/moment";
import gql from "graphql-tag";
import { IoIosCloseCircle } from "react-icons/io";
import { Button, Tag } from "components/base";
import Image from "components/Image";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import BackButton from "components/BackButton";
import ShipmentErrorComponent from "components/ShipmentError";
import CustomerSelector from "components/CustomerSelector";
import ShipmentDelay from "components/ShipmentDelay.js";
import { Alert } from "components/Toast";
import InvoiceForm from "components/InvoiceForm";

import { FRAGMENT_SHIPMENT_ERROR } from "../graphql";
import Modal from "components/Modal";
import Page from "components/Page";

const CREATE_SHIPMENT = gql`
  mutation CREATE_SHIPMENT(
    $customerId: ID!
    $invoices: [CreateShipmentInvoiceInputType]!
  ) {
    createShipment(invoices: $invoices, customerId: $customerId) {
      shipment {
        id
      }
    }
  }
`;

const FETCH_CUSTOMER_ERRORS = gql`
  query FETCH_CUSTOMER_ERRORS($id: ID!) {
    customer(id: $id) {
      id
      shipmentErrors {
        ...shipmentError
      }
    }
  }
  ${FRAGMENT_SHIPMENT_ERROR}
`;

export const ShipmentErrorWithDetails = ({ shipmentStatusError }) => (
  <div>
    <div>
      <div className="lg:flex justify-between items-start">
        <div>
          <div className="text-gray-600">
            {formatDate(shipmentStatusError.created * 1000, "YYYY-MM-DD")}
          </div>
          <ShipmentErrorComponent
            prefix="Error "
            className="mt-2"
            code={shipmentStatusError.error.code}
            description={shipmentStatusError.error.description}
          />
        </div>
      </div>

      {shipmentStatusError.notes ? (
        <div className="whitespace-pre-wrap mt-2">
          <label htmlFor="">Notes: </label>
          {shipmentStatusError.notes}
        </div>
      ) : null}
    </div>

    {shipmentStatusError.hasDelay ? (
      <div className="mt-2">
        <ShipmentDelay days={shipmentStatusError.delayDays} />
      </div>
    ) : null}

    {shipmentStatusError.shipmentStatusErrorFactories.length > 0 && (
      <div className="mt-1 lg:mt-4 flex flex-wrap">
        {shipmentStatusError.shipmentStatusErrorFactories.map((i) => (
          <Tag
            className="text-xs mr-2 mb-2"
            title={i.factory.nickName}
            key={i.id}
          />
        ))}
      </div>
    )}

    {shipmentStatusError.shipmentStatusErrorProductRows.length > 0 ? (
      <div className="pt-2 lg:px-4 mt-2 lg:mt-4 bg-gray-200 dark:bg-gray-800 rounded-lg">
        <div className="text-base font-bold">Products</div>
        <div className="mt-1 lg:mt-2">
          {shipmentStatusError.shipmentStatusErrorProductRows.map((row) => (
            <div className="border-t dark:border-gray-700 py-2" key={row.id}>
              <div className="lg:flex justify-between text-xs">
                <div className="flex items-center">
                  <div className="flex items-center">
                    {row.shipmentInvoiceRow.product.images.map((i, index) => (
                      <Image
                        size="h-12"
                        className="mr-2"
                        src={i.thumb}
                        key={index}
                      />
                    ))}
                  </div>
                  <div>{row.shipmentInvoiceRow.product.name}</div>
                </div>
                <div>&times; {row.shipmentInvoiceRow.qty}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : null}
  </div>
);

let intervalId = null;
const COUNT_DOWN_VALUE = process.env.NODE_ENV === "development" ? 1 : 10;

function CreateShipmentFormWithCustomer({ customer }) {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(FETCH_CUSTOMER_ERRORS, {
    variables: { id: customer.id },
  });
  const [createShipment, createShipmentRes] = useMutation(CREATE_SHIPMENT, {
    onCompleted: (data) =>
      navigate(`/shipment/shipments/${data.createShipment.shipment.id}`),
    onError: (error) => Alert("error", error.message),
  });
  const [countDown, setCountDown] = useState(COUNT_DOWN_VALUE);
  const [hasReadNotesAndErrors, setHasReadNotesAndErrors] = useState(false);
  const [shipmentInvoices, setShipmentInvoices] = useState([
    {
      so: "",
      pos: [""],
    },
  ]);

  useEffect(() => {
    if (data) {
      setCountDown(COUNT_DOWN_VALUE);
      if (intervalId) clearInterval(intervalId);
      if (customer.notes || data.customer.shipmentErrors.length > 0) {
        intervalId = setInterval(() => {
          setCountDown((prev) => {
            if (prev > 0) return prev - 1;
            return 0;
          });
        }, 1000);
      } else {
        setCountDown(0);
        setHasReadNotesAndErrors(true);
      }
    }
    return () => {
      if (intervalId) clearInterval(intervalId);

      // clear fade container disable scroll for force read notes
      document.body.style.overflow = "unset";
    };
  }, [customer.notes, data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="p-4 lg:p-6 flex-1 flex flex-col">
      <Modal
        center={false}
        show={!hasReadNotesAndErrors}
        title="Customer Notes"
        subtitle="Customer notes and history errors."
      >
        <div className="p-4 lg:p-6">
          <h4 className="mb-4 lg:mb-6">
            Please read this notes before you create any new shipemnts.
          </h4>
          {customer.notes && (
            <div className="whitespace-pre-wrap p-6 bg-white dark:bg-gray-900 rounded-lg text-base leading-loose mb-4 lg:mb-6">
              {customer.notes}
            </div>
          )}
          {data.customer.shipmentErrors.length > 0 ? (
            <div className="rounded-xl p-4 lg:p-6 bg-white dark:bg-gray-900">
              <h4>Shipment Error History</h4>
              <div className="mt-2 space-y-4">
                {data.customer.shipmentErrors.map((e) => (
                  <div key={e.id} className="border-b border-gray-200">
                    <ShipmentErrorWithDetails shipmentStatusError={e} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          <div className="py-6">
            <Button
              title={
                countDown > 0
                  ? `You are allow to continue in ${countDown} seconds.`
                  : "OK, I know!"
              }
              disabled={countDown > 0}
              color="blue"
              border
              onClick={() => setHasReadNotesAndErrors(true)}
            ></Button>
          </div>
        </div>
      </Modal>

      <div>
        {shipmentInvoices.map((_, index) => (
          <div
            key={index}
            className="p-6 mb-6 bg-white rounded-lg border-gray-200 relative"
          >
            <div className="flex items-start justify-between">
              <label>Shipment Invoice {index + 1}</label>
              <div
                className="text-red-500 hover:text-red-600 active:text-red-700 cursor-pointer"
                onClick={() =>
                  setShipmentInvoices((prev) =>
                    prev.filter((_, prevIndex) => prevIndex !== index),
                  )
                }
              >
                <IoIosCloseCircle size={21} />
              </div>
            </div>
            <InvoiceForm
              className="mt-4"
              customerId={customer.id}
              hideControls={true}
              onChangeSoPos={(so, pos) =>
                setShipmentInvoices((prev) =>
                  prev.map((prevItem, prevItemIndex) => {
                    if (prevItemIndex !== index) return prevItem;
                    return { so, pos };
                  }),
                )
              }
            />
          </div>
        ))}

        <div className="px-2">
          <Button
            title="+ add shipment invoice"
            onClick={() =>
              setShipmentInvoices((prev) => [...prev, { so: "", pos: [""] }])
            }
          ></Button>
        </div>
      </div>

      <div className="flex mt-6">
        <Button
          title={
            countDown > 0
              ? `You are allow to Create Shipment in ${countDown} seconds.`
              : createShipmentRes.loading
                ? `Creating Shipment...`
                : "Create Shipment"
          }
          disabled={countDown > 0 || createShipmentRes.loading}
          color="blue"
          border
          onClick={(_) => {
            createShipment({
              variables: {
                customerId: customer.id,
                invoices: shipmentInvoices,
              },
            });
          }}
        />
      </div>
    </div>
  );
}

function CreateShipment() {
  const [customer, setCustomer] = useState(null);

  return (
    <Page
      className="lg:h-screen"
      title="Create Shipment"
      backButton={
        customer ? (
          <BackButton onClick={(_) => setCustomer(null)} />
        ) : (
          <BackButton to="/shipment/shipments" />
        )
      }
    >
      <div className="flex-1 flex flex-col">
        {!customer ? (
          <CustomerSelector
            onSelectCustomer={(customer) => setCustomer(customer)}
          />
        ) : (
          <CreateShipmentFormWithCustomer customer={customer} />
        )}
      </div>
    </Page>
  );
}

export default CreateShipment;
