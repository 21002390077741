import CharlesButton from "components/charles/base";
import { Input, NativeDatePicker, Select } from "components/Form";
import { useModals } from "ModalProvider";
import { useState } from "react";
import ChooseProductView from "./ChooseProductView";
import { BsTrash } from "react-icons/bs";
import { useMutation, useQuery } from "@apollo/client";
import {
  FETCH_INC_WAREHOUSE_PRODUCTS,
  INC_DELETE_SHIPMENT,
  INC_SAVE_SHIPMENT,
} from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import ChooseShipmentView from "./ChooseShipmentView";
import Spinner from "components/Spinner";
import Errors from "components/Errors";

const ShipmentForm = ({ originShipment, warehouse, hide }) => {
  console.log("originShipment", originShipment);
  const [shipment, setShipment] = useState(
    originShipment
      ? {
          name: originShipment.name,
          date: originShipment.date,
          lines: originShipment.lines.map((line) => ({
            product: line.product,
            qty: line.qty,
          })),
          state: originShipment.state,
        }
      : { name: "", date: "", state: "DRAFT", lines: [] },
  );
  const { loading, error, data } = useQuery(FETCH_INC_WAREHOUSE_PRODUCTS, {
    variables: { warehouse },
  });

  const [saveShipment, saveShipmentRes] = useMutation(INC_SAVE_SHIPMENT, {
    onCompleted: (res) => {
      Alert("success", "Shipment saved.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_INC_SHIPMENT_LINES"],
    awaitRefetchQueries: true,
  });
  const [deleteShipment, deleteShipmentRes] = useMutation(INC_DELETE_SHIPMENT, {
    onCompleted(res) {
      Alert("success", "Inbound deleted successfully");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
    refetchQueries: ["FETCH_INC_SHIPMENT_LINES"],
    awaitRefetchQueries: true,
  });

  function saveHandler() {
    saveShipment({
      variables: {
        id: originShipment?.id,
        ...shipment,
        warehouse: warehouse,
        lines: shipment.lines.map((line) => ({
          productId: line.product.id,
          qty: line.qty,
        })),
      },
    });
  }

  const chooseProductModal = useModals();
  const shipmentsModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const products = data.incProducts;

  function chooseProduct() {
    chooseProductModal.present({
      title: "Choose Product",
      children: (
        <ChooseProductView
          products={products}
          onSelect={onChooseProduct}
          hide={chooseProductModal.hide}
        />
      ),
    });
  }

  function onChooseProduct(product) {
    chooseProductModal.hide();
    setShipment((prev) => ({
      ...prev,
      lines: [...prev.lines, { product, qty: 0 }],
    }));
  }

  function showShipments() {
    shipmentsModal.present({
      title: "Choose Shipment",
      subtitle: "Choose a shipment to Waboba INC.",
      children: (
        <ChooseShipmentView
          onSelect={onChooseShipment}
          hide={shipmentsModal.hide}
        />
      ),
    });
  }

  function onChooseShipment(shipment) {
    shipmentsModal.hide();
    const rows = shipment.shipmentInvoices.flatMap(
      (invoice) => invoice.shipmentInvoiceRows,
    );
    let lines = [];
    rows.forEach((row) => {
      const product = products.find((i) => i.sku === row.product.number);
      if (product) lines.push({ product: product, qty: row.qty });
    });

    setShipment((prev) => ({
      ...prev,
      name: shipment.invoiceNumber,
      date: shipment.eta,
      shipmentType: "INBOUND",
      lines,
    }));
  }

  function tryDeleteShipment(id) {
    if (window.confirm("Are you sure you want to delete this shipment?")) {
      deleteShipment({ variables: { id } });
    }
  }

  return (
    <div>
      <div className="card p-4 relative text-sm">
        <div className="flex justify-end text-sm absolute right-6 top-4">
          <CharlesButton onClick={showShipments}>
            Import Shipment from China
          </CharlesButton>
        </div>

        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="">Name:</label>
                </td>
                <td className="w-full">
                  <Input
                    value={shipment.name}
                    onChange={(e) =>
                      setShipment({ ...shipment, name: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="">Type:</label>
                </td>
                <td className="w-full">
                  <Select
                    value={shipment.shipmentType}
                    onChange={(e) =>
                      setShipment((prev) => ({
                        ...prev,
                        shipmentType: e.target.value,
                      }))
                    }
                  >
                    <option value="INBOUND">Inbound</option>
                    {/* <option value="OUTBOUND">Outbound</option> */}
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="">State:</label>
                </td>
                <td className="w-full">
                  <Select
                    value={shipment.state}
                    onChange={(e) =>
                      setShipment((prev) => ({
                        ...prev,
                        state: e.target.value,
                      }))
                    }
                  >
                    <option value="DRAFT">Draft</option>
                    <option value="COMPLETED">Completed</option>
                  </Select>
                </td>
              </tr>
              <tr>
                <td>
                  <label htmlFor="">Date</label>
                </td>
                <td>
                  <NativeDatePicker
                    value={shipment.date}
                    onChange={(e) =>
                      setShipment({ ...shipment, date: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr className="my-4" />

        <div className="px-2">
          <div className="mt-2 -mx-2">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th className="text-right">QTY</th>
                </tr>
              </thead>
              <tbody>
                {shipment.lines.map((line, index) => (
                  <tr key={index} className="border-y dark:border-gray-700">
                    <td>
                      <div className="flex items-center space-x-2">
                        <CharlesButton
                          danger
                          onClick={() => {
                            setShipment((prev) => {
                              const newLines = prev.lines.filter(
                                (_, i) => i !== index,
                              );
                              return { ...prev, lines: newLines };
                            });
                          }}
                        >
                          <BsTrash />
                        </CharlesButton>
                        <div>{line.product.sku}</div>
                      </div>
                    </td>
                    <td className="text-right">
                      <Input
                        className="text-right"
                        value={line.qty}
                        onChange={(e) => {
                          setShipment((prev) => {
                            const newLines = prev.lines.map((s, i) =>
                              i === index ? { ...s, qty: e.target.value } : s,
                            );
                            return { ...prev, lines: newLines };
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4">
            <CharlesButton onClick={chooseProduct}>+ Product</CharlesButton>
          </div>
        </div>
      </div>

      <div className="mt-6 mx-2 flex justify-between">
        <CharlesButton
          primary
          onClick={saveHandler}
          loading={saveShipmentRes.loading}
        >
          Save
        </CharlesButton>

        {originShipment ? (
          <CharlesButton
            danger
            onClick={() => tryDeleteShipment(originShipment.id)}
            loading={deleteShipmentRes.loading}
          >
            Delete Shipment
          </CharlesButton>
        ) : null}
      </div>
    </div>
  );
};

export default ShipmentForm;
