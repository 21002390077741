import { useMutation, useQuery } from "@apollo/client";
import { FETCH_INC_SHIPMENT_LINES, INC_SAVE_FORECAST_DOC } from "./graphql";
import CharlesButton from "components/charles/base";
import { NavigationBar } from "components/base";
import ForecastView from "./ForecastView";
import { useModals } from "ModalProvider";
import ImportFromWarehouseView from "./ImportFromWarehouseView";
import Excel from "exceljs";
import { FileSelector } from "components/Form";
import { Alert } from "components/Toast";
import { useRef, useState } from "react";
import { computedForecastData, prepareData } from "./utils";
import { parseError } from "apollo";
import ImportView from "./ImportView";
import ShipmentForm from "./shipments/ShipmentForm";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import DuplicateDocView from "./DuplicateDocView";

const ForecastDoc = ({ originDoc }) => {
  const importType = useRef(null);
  const modal = useModals();
  const [products, setProducts] = useState(
    originDoc.data ? JSON.parse(originDoc.data) : {},
  );

  const { loading, error, data } = useQuery(FETCH_INC_SHIPMENT_LINES, {
    variables: {
      productIds: Object.keys(products),
      year: originDoc.year,
    },
  });

  const [save, saveRes] = useMutation(INC_SAVE_FORECAST_DOC, {
    variables: { id: originDoc.id, data: JSON.stringify(products) },
    onCompleted() {
      Alert("success", "Saved successfully");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const xlsxReader = new FileReader();
  const wb = new Excel.Workbook();

  xlsxReader.onload = function (e) {
    wb.xlsx
      .load(e.target.result)
      .then(handlLoadXlsx)
      .catch((error) => {
        Alert("error", `Fail to read xlsx file. Error: ${error}`);
      });
  };

  function handlLoadXlsx(wb) {
    const ws = wb.worksheets[0];
    if (importType.current === "actualSales") {
      getActualSales(ws);
    } else if (importType.current === "canyonwallSales") {
      const ws_1 = wb.worksheets[1];
      getCanyonwallSales(ws_1);
    } else if (importType.current === "beginInventory") {
      getBeginInventory(ws);
    }
  }

  function getActualSales(ws) {
    let salesItems = [];
    for (var i = 3; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      const item = {
        sku: row.getCell("A").value,
        qty: parseInt(row.getCell("B").value),
      };
      salesItems.push(item);
    }

    modal.present({
      title: "Import Actual Sales",
      children: (
        <ImportView
          items={salesItems}
          hide={modal.hide}
          confirmHandler={(month) =>
            confirmImportHandler(month, salesItems, "sales")
          }
        />
      ),
    });
  }

  function getCanyonwallSales(ws) {
    let salesItems = [];
    for (var i = 9; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      const item = {
        sku: row.getCell("A").value,
        qty: parseInt(row.getCell("O").value),
      };
      salesItems.push(item);
    }

    modal.present({
      title: "Import Canyonwall Sales",
      children: (
        <ImportView
          items={salesItems}
          hide={modal.hide}
          confirmHandler={(month) =>
            confirmImportHandler(month, salesItems, "sales")
          }
        />
      ),
    });
  }

  function confirmImportHandler(month, items, qtyType) {
    console.log("confirmImportHandler", items);
    modal.hide();
    setProducts((prev) => {
      const newProducts = { ...prev };
      items.forEach((item) => {
        const product = Object.values(newProducts).find(
          (p) => p.sku === item.sku,
        );
        if (product) {
          product[month][qtyType].qty = item.qty;
        }
      });
      return newProducts;
    });
  }

  function getBeginInventory(ws) {
    let items = [];
    for (var i = 3; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      const item = {
        sku: row.getCell("A").value,
        qty: parseInt(row.getCell("B").value),
      };
      items.push(item);
    }
    modal.present({
      title: "Import Begin Invenory",
      children: (
        <ImportView
          items={items}
          hide={modal.hide}
          confirmHandler={(month) =>
            confirmImportHandler(month, items, "begin_inv")
          }
        />
      ),
    });
  }

  function tryImportWarehouseProducts() {
    modal.present({
      title: "Import Products",
      children: (
        <ImportFromWarehouseView
          forecastDocId={originDoc.id}
          onImportedProducts={onImportedProducts}
        />
      ),
    });
  }

  function onImportedProducts(res) {
    const newProducts = prepareData(
      res.incFcImportWarehouseProducts.forecastDoc.data,
    );
    setProducts(newProducts);
    save({
      variables: { id: originDoc.id, data: JSON.stringify(newProducts) },
    });
    modal.hide();
  }

  function addShipment() {
    modal.present({
      title: "Add Shipment",
      children: (
        <ShipmentForm warehouse={originDoc.warehouse} hide={modal.hide} />
      ),
    });
  }

  function duplicateHandler() {
    modal.present({
      title: "Duplicate Forecast Document",
      children: (
        <DuplicateDocView
          sourceDoc={originDoc}
          computedData={computedForecastData(products, data.incShipmentLines)}
          hide={modal.hide}
        />
      ),
    });
  }

  console.log("products", products);

  return (
    <div className="flex flex-col h-screen">
      <NavigationBar
        title={originDoc.name}
        backTo="./.."
        rightButtons={
          <div className="flex space-x-4">
            <CharlesButton>Add Product</CharlesButton>
            <CharlesButton onClick={tryImportWarehouseProducts}>
              Import Warehouse Products
            </CharlesButton>
            <FileSelector
              accept=".xlsx,.xls"
              onChange={(e) => {
                importType.current = "beginInventory";
                const file = e.target.files[0];
                xlsxReader.readAsArrayBuffer(file);
                e.target.value = null;
              }}
              title="Import Begin Inventory"
            />
            <FileSelector
              accept=".xlsx,.xls"
              onChange={(e) => {
                importType.current = "actualSales";
                const file = e.target.files[0];
                xlsxReader.readAsArrayBuffer(file);
                e.target.value = null;
              }}
              title="Import Sales"
            />
            <FileSelector
              accept=".xlsx,.xls"
              onChange={(e) => {
                importType.current = "canyonwallSales";
                const file = e.target.files[0];
                xlsxReader.readAsArrayBuffer(file);
                e.target.value = null;
              }}
              title="Import Canyonwall Sales"
            />
            <CharlesButton onClick={addShipment}>Add Shipment</CharlesButton>
            <CharlesButton onClick={duplicateHandler}>Duplicate</CharlesButton>
            <CharlesButton>Export</CharlesButton>
            <CharlesButton onClick={save} loading={saveRes.loading}>
              Save
            </CharlesButton>
          </div>
        }
      />

      <ForecastView
        warehouse={originDoc.warehouse}
        products={products}
        setProducts={setProducts}
        shipmentLines={data.incShipmentLines}
      />
    </div>
  );
};

export default ForecastDoc;
