import { createContext, useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import Page from "components/Page";
import { Button, Tag } from "components/base";
import { Alert } from "components/Toast.js";
import { Text } from "components/Form";
import ItemSizeInput from "components/ItemSizeInput";
import {
  SAVE_PRODUCT_LIST,
  DUPLICATE_PRODUCT_LIST,
  DELETE_PRODUCT_LIST,
  FETCH_PRODUCT_LISTS,
} from "./graphql";
import PriceBreakDown from "./PriceBreakDown";
import FileUploader from "./FileUploader";
import { byName } from "utils/sort";
import CharlesButton from "components/charles/base";
import { Link, useNavigate } from "react-router-dom";
import ResponsibilityView from "./ResponsibilityView";
import Images from "./Images";
import PLInput from "./PLInput";
import Packaging from "./Packaging";
import MaterialsView from "./MaterialsView";
import LabTestStandards from "./LabTestStandards";
import { parseError } from "apollo";
import PackingInstructions from "./packingInstructions";
import { ProductListStateChain } from "components/Status";
import { useModals } from "ModalProvider";
import ConfirmProductListView from "./ConfirmProductListView";
import CreatenewVersionView from "./CreatenewVersionView";
import ResetToDraftView from "./ResetToDraftView";

const previewImage = (f) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (even) => resolve(even.target.result);
    reader.readAsDataURL(f);
  });

export const ProductListContext = createContext({});

const Form = ({
  productListId,
  originalProductList,
  allMaterials,
  allFactories,
  allLabTestStandards,
  allProductWarnings,
}) => {
  allFactories.sort((a, b) => (a.nickName > b.nickName ? 1 : -1));

  const [data, setData] = useState({
    ...originalProductList,
    versionNotes: originalProductList.versionNotes,
    cartonNetWeight: originalProductList.cartonNetWeight
      ? originalProductList.cartonNetWeight
      : 0,
    cartonGrossWeight: originalProductList.cartonGrossWeight
      ? originalProductList.cartonGrossWeight
      : 0,
    updatedWarnings: originalProductList.warnings.map((i) => i.id),
    itemSize: {
      shape: originalProductList.shape,
      ballDiameter: originalProductList.ballDiameter,
      cubeX: originalProductList.cubeX,
      cubeY: originalProductList.cubeY,
      cubeZ: originalProductList.cubeZ,
      cylindricalDiameter: originalProductList.cylindricalDiameter,
      cylindricalHeight: originalProductList.cylindricalHeight,
      flatX: originalProductList.flatX,
      flatY: originalProductList.flatY,
    },
    priceItems: [
      ...originalProductList.priceItems.map((priceItem) => ({
        id: priceItem.id,
        name: priceItem.name,
        notes: priceItem.notes,
        currency: priceItem.currency,
        supplierId: priceItem.supplier ? priceItem.supplier.id : 0,
        itemPrices: priceItem.itemPrices.map((i) => ({
          minQty: i.minQty,
          value: i.value,
        })),
      })),
    ],
    updatedLabTestStandards: originalProductList.labTestStandards.map(
      (i) => i.id,
    ),
    materials: originalProductList.materials.map((i) => ({
      name: i.material.name,
      weight: i.weight,
      remark: i.remark,
    })),
    packagings: originalProductList.packagings.map((i) => ({
      name: i.name,
      materialName: i.material?.name,
      size: { x: i.sizeX, y: i.sizeY, z: i.sizeZ },
      weight: i.weight,
      plasticWeight: i.plasticWeight,
    })),
    updatedTestReports: originalProductList.testReports.map((i) => ({
      standardId: i.standard ? i.standard.id : null,
      createdAt: new Date(i.createdAt),
      fileList: i.fileList,
    })),
    newSampleImages: [],
    newAttachments: [],
    newPackagingRemarkImages: [],
    projectManagerId: originalProductList.projectManager
      ? originalProductList.projectManager.id
      : null,
    productDesignerId: originalProductList.productDesigner
      ? originalProductList.productDesigner.id
      : null,
    packagingDesignerId: originalProductList.packagingDesigner
      ? originalProductList.packagingDesigner.id
      : null,
    productionManagerId: originalProductList.productionManager
      ? originalProductList.productionManager.id
      : null,
    merchandiserId: originalProductList.merchandiser
      ? originalProductList.merchandiser.id
      : null,
  });

  const [saveProductList, saveProductListRes] = useMutation(SAVE_PRODUCT_LIST, {
    onError: (error) => Alert("error", parseError(error)),
  });

  const doSaveProductList = useCallback(
    (data, alert = true) => {
      console.log("data", data);
      let productListInput = {
        id: data.id,
        name: data.name,
        versionNotes: data.versionNotes,

        ...data.itemSize,
        sizeNotes: data.sizeNotes,
        weight: data.weight,
        unitWeight: data.unitWeight,
        weightNotes: data.weightNotes,
        pantone: data.pantone,
        printingMethod: data.printingMethod,
        priceNotes: data.priceNotes,

        age: data.age,
        updatedWarnings: data.updatedWarnings,
        moq: data.moq,

        innerBoxMaterial: data.innerBoxMaterial,
        innerBoxX: data.innerBoxX,
        innerBoxY: data.innerBoxY,
        innerBoxZ: data.innerBoxZ,
        plasticPackagingWeight: data.plasticPackagingWeight,
        displayBoxMaterial: data.displayBoxMaterial,
        displayBoxX: data.displayBoxX,
        displayBoxY: data.displayBoxY,
        displayBoxZ: data.displayBoxZ,
        cartonX: data.cartonX,
        cartonY: data.cartonY,
        cartonZ: data.cartonZ,
        qtyPerDisplayBox: data.qtyPerDisplayBox,
        qtyPerCarton: data.qtyPerCarton,
        packagingRemark: data.packagingRemark,

        cartonNetWeight: data.cartonNetWeight,
        cartonGrossWeight: data.cartonGrossWeight,

        labTestCreatedAt: data.labTestCreatedAt,
        updatedLabTestStandards: data.updatedLabTestStandards,
        projectManagerId: data.projectManagerId,
        productDesignerId: data.productDesignerId,
        packagingDesignerId: data.packagingDesignerId,
        productionManagerId: data.productionManagerId,
        merchandiserId: data.merchandiserId,

        updatedMaterials: data.materials.map((i) => ({
          name: i.name,
          weight: i.weight,
          percentage: data.weight > 0 ? (i.weight * 100) / data.weight : 0,
          remark: i.remark,
        })),
        packagings: data.packagings.map((i) => ({
          name: i.name,
          materialName: i.materialName,
          sizeX: i.size.x,
          sizeY: i.size.y,
          sizeZ: i.size.z,
          weight: i.weight,
          plasticWeight: i.plasticWeight,
        })),
        updatedTestReports: data.updatedTestReports
          .filter((i) => i.standardId)
          .map((i) => ({
            standardId: i.standardId,
            createdAt: i.createdAt,
            fileList: i.fileList,
          })),

        sampleImages: data.sampleImages.map((i) => i.id),
        newSampleImages: data.newSampleImages.map((f) => f.file),
        packagingRemarkImages: data.packagingRemarkImages.map((i) => i.id),
        newPackagingRemarkImages: data.newPackagingRemarkImages.map(
          (f) => f.file,
        ),
        updatedPriceItems: data.priceItems,

        attachments: data.attachments.map((i) => i.id),
        newAttachments: data.newAttachments,
      };

      saveProductList({
        variables: {
          productListInput,
        },
        onCompleted: (res) => {
          if (alert) {
            Alert("success", "Proudct List Saved.");
            const updatedProductList = res.saveProductList.productList;
            setData((prev) => ({
              ...prev,
              priceItems: updatedProductList.priceItems.map((priceItem) => ({
                id: priceItem.id,
                name: priceItem.name,
                notes: priceItem.notes,
                supplierId: priceItem.supplier ? priceItem.supplier.id : 0,
                itemPrices: priceItem.itemPrices.map((i) => ({
                  minQty: i.minQty,
                  value: i.value,
                })),
                currency: priceItem.currency,
              })),
              attachments: updatedProductList.attachments,
              newAttachments: [],
            }));
          }
        },
      });
    },
    [productListId],
  );

  const navigate = useNavigate();
  const modal = useModals();

  const [duplicateProductList, duplicateProductListRes] = useMutation(
    DUPLICATE_PRODUCT_LIST,
    {
      onCompleted: (data) => {
        Alert("sucess", "New Product List Created.");
        navigate(`/product-list/${data.duplicateProductList.productList.id}`);
      },
      onError: (error) => Alert("error", error.message),
      refetchQueries: [{ query: FETCH_PRODUCT_LISTS }],
    },
  );

  const [deleteProductList, deleteProductListRes] = useMutation(
    DELETE_PRODUCT_LIST,
    {
      variables: { productListId },
      onCompleted: () => {
        Alert("sucess", "Product List Deleted.");
        navigate("/product-list");
      },
      onError: (error) => Alert("error", error.message),
      refetchQueries: [{ query: FETCH_PRODUCT_LISTS }],
    },
  );

  const onAddNewImages = async (e, type) => {
    let files = [];
    const newImages = [];
    for (const file of e.target.files) {
      files.push(file);
    }
    e.target.value = null;
    for (const file of files) {
      const url = await previewImage(file);
      newImages.push({ file, url });
    }
    setData({
      ...data,
      [type]: [...data[type], ...newImages],
    });
  };

  const removeImage = (id, type) =>
    setData({
      ...data,
      [type]: data[type].filter((i) => i.id !== id),
    });

  const removeNewImage = (index, type) => {
    let updatedImages = [...data[type]];
    updatedImages.splice(index, 1);
    setData({ ...data, [type]: updatedImages });
  };

  function setFloatValue(e, field) {
    let value = parseFloat(e.target.value);
    if (isNaN(value)) value = 0;
    setData((prev) => ({ ...prev, [field]: value }));
  }

  function setIntValue(e, field) {
    let value = parseInt(e.target.value);
    if (isNaN(value)) value = 0;
    setData((prev) => ({ ...prev, [field]: value }));
  }

  const testStandards = [...allLabTestStandards].sort(byName);

  function tryCreateVersion() {
    modal.present({
      title: "Create New Version",
      center: true,
      maxWidth: "max-w-3xl",
      children: <CreatenewVersionView productListId={originalProductList.id} />,
    });
  }

  function confirmHandler() {
    modal.present({
      title: "Confirm Product List",
      center: true,
      maxWidth: "max-w-3xl",
      children: (
        <ConfirmProductListView
          productList={originalProductList}
          hide={modal.hide}
        />
      ),
    });
  }

  function resetToDraft() {
    modal.present({
      title: "Reset to Draft",
      subtitle: originalProductList.name,
      center: true,
      maxWidth: "max-w-3xl",
      children: (
        <ResetToDraftView productList={originalProductList} hide={modal.hide} />
      ),
    });
  }

  return (
    <ProductListContext.Provider
      value={{
        data,
        setData,
        allMaterials,
        testStandards,
        onAddNewImages,
        removeImage,
        removeNewImage,
        originalProductList,
      }}
    >
      <Page
        title={
          <div className="flex items-baseline space-x-2">
            <div>{originalProductList.name}</div>
            <div className="text-base opacity-70 font-normal">
              version {originalProductList.version}
            </div>
          </div>
        }
        backTo="./.."
        leftButtons={
          <div className="flex items-center space-x-4">
            {originalProductList.versions.length > 1 ? (
              <div className="space-x-4 pl-6">
                {originalProductList.versions.map((child) => (
                  <Link key={child.id} to={`./../${child.id}`}>
                    Version {child.version}
                  </Link>
                ))}
              </div>
            ) : null}
            <CharlesButton onClick={tryCreateVersion}>
              + New Version
            </CharlesButton>
          </div>
        }
        rightButtons={
          <div className="flex space-x-4">
            <CharlesButton
              loading={saveProductListRes.loading}
              onClick={() => doSaveProductList(data)}
            >
              Save
            </CharlesButton>

            <CharlesButton
              loading={duplicateProductListRes.loading}
              onClick={(_) => {
                const name = window.prompt(
                  "Input the new product list name and continue.",
                  "",
                );
                if (name)
                  duplicateProductList({ variables: { productListId, name } });
              }}
            >
              Duplicate
            </CharlesButton>

            {originalProductList.state === "DRAFT" ? (
              <CharlesButton onClick={confirmHandler}>Confirm</CharlesButton>
            ) : (
              <CharlesButton onClick={resetToDraft} danger>
                Reset to Draft
              </CharlesButton>
            )}

            <Button
              title="Delete"
              color="red"
              loading={deleteProductListRes.loading}
              disabled={deleteProductListRes.disabled}
              onClick={(_) => {
                if (window.confirm("You are sure to delete this Product List?"))
                  deleteProductList();
              }}
            />
          </div>
        }
      >
        <div className="m-6 mb-0">
          <ProductListStateChain status={originalProductList.state} />
        </div>

        <div className="m-6 xl:grid xl:grid-cols-12 gap-6">
          <div className="xl:col-span-8 space-y-6">
            <div className="card">
              <h5>What's New in This Version</h5>
              <div className="mt-2">
                <Text
                  value={data.versionNotes}
                  onChange={(e) =>
                    setData({ ...data, versionNotes: e.target.value })
                  }
                  className=" rounded"
                />
              </div>
            </div>

            <div className="card">
              <h5>Product Specification</h5>
              <div className="mt-4">
                <PLInput
                  label="Name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />

                <div className="flex items-center mb-4">
                  <label className="w-40 inline-block pr-4">Size :</label>
                  <div className="ml-4">
                    <ItemSizeInput
                      value={data.itemSize}
                      onChange={(itemSize) =>
                        setData((prev) => ({ ...prev, itemSize }))
                      }
                    />
                  </div>
                </div>

                <PLInput
                  placeholder="If there is any special notes for the size, for example, ball without air."
                  label="Size Notes"
                  value={data.sizeNotes}
                  onChange={(e) =>
                    setData({ ...data, sizeNotes: e.target.value })
                  }
                />

                <PLInput
                  label="Weight(item weight)"
                  value={data.weight}
                  type="number"
                  step="0.001"
                  onChange={(e) => setFloatValue(e, "weight")}
                  trailingLabel="g"
                />

                <PLInput
                  label="Sale Unit Weight"
                  value={data.unitWeight}
                  type="number"
                  step="0.001"
                  onChange={(e) => setFloatValue(e, "unitWeight")}
                  trailingLabel="g"
                />

                <PLInput
                  placeholder="For example +/- 0.5g"
                  label="Weight Notes"
                  value={data.weightNotes}
                  onChange={(e) =>
                    setData({ ...data, weightNotes: e.target.value })
                  }
                />

                <PLInput
                  label="MOQ"
                  type="number"
                  value={data.moq}
                  onChange={(e) => setIntValue(e, "moq")}
                />

                <PLInput
                  label="Age Grading"
                  value={data.age}
                  onChange={(e) => {
                    let age = parseInt(e.target.value);
                    if (isNaN(age)) age = 0;
                    setData({ ...data, age });
                  }}
                />

                <div className="flex items-baseline mb-4">
                  <label className="w-40 inline-block whitespace-normal flex-shrink-0">
                    Warnings:
                  </label>
                  <div className="ml-4 flex flex-wrap">
                    {allProductWarnings.map((warning, index) => {
                      const selected = data.updatedWarnings.includes(
                        warning.id,
                      );
                      return (
                        <Tag
                          selected={selected}
                          onClick={(_) => {
                            if (selected) {
                              setData((prev) => ({
                                ...prev,
                                updatedWarnings: prev.updatedWarnings.filter(
                                  (i) => i !== warning.id,
                                ),
                              }));
                            } else {
                              setData((prev) => ({
                                ...prev,
                                updatedWarnings: [
                                  ...prev.updatedWarnings,
                                  warning.id,
                                ],
                              }));
                            }
                          }}
                          className="mr-4 mb-2"
                          title={warning.name}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>

                <PLInput
                  label="Product Pantone Color"
                  value={data.pantone}
                  onChange={(e) =>
                    setData({ ...data, pantone: e.target.value })
                  }
                  inputType="textArea"
                />

                <PLInput
                  label="Printing Method"
                  value={data.printingMethod}
                  onChange={(e) =>
                    setData({ ...data, printingMethod: e.target.value })
                  }
                />
              </div>
            </div>

            <MaterialsView />

            <Packaging />

            <LabTestStandards />

            <div className="card">
              <h5>Price Breakdown</h5>
              <div className="mt-4">
                <PriceBreakDown
                  productListId={data.id}
                  productLine={data.productLine}
                  priceItems={data.priceItems}
                  onChangePriceItems={(priceItems) => {
                    setData((prev) => ({ ...prev, priceItems }));
                  }}
                  allFactories={allFactories}
                />

                <hr />

                <FileUploader data={data} setData={setData} />
              </div>
            </div>
          </div>

          <div className="xl:col-span-4 space-y-6">
            <div className="card px-6 py-4">
              <Images
                title="Sample Images"
                buttonText="Upload"
                type="sampleImages"
                newType="newSampleImages"
                data={data}
                onAddNewImages={onAddNewImages}
                removeImage={removeImage}
                removeNewImage={removeNewImage}
              />
            </div>

            <PackingInstructions productListId={originalProductList.id} />

            <ResponsibilityView data={originalProductList} />
          </div>
        </div>
      </Page>
    </ProductListContext.Provider>
  );
};

export default Form;
