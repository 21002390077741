import { useQuery } from "@apollo/client";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { NavigationBar } from "components/base";
import { useParams, useSearchParams } from "react-router-dom";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import { FETCH_PRODUCT_SUPPLIER_STOCK } from "./graphql";
import SupplierStockDeliveryForm from "./SupplierStockDeliveryForm";
import ProductStocksTableView from "./ProductStocksTableView";

const ProductStock = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT_SUPPLIER_STOCK, {
    variables: { id },
  });
  const modal = useModals();
  const [searchParams] = useSearchParams({ filterSupplierId: "ALL" });
  const filterSupplierId = searchParams.get("filterSupplierId") || "ALL";

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  function createStockDelivery() {
    let supplierId = null;
    if (data.product.supplierStocks.length > 0) {
      supplierId =
        data.product.supplierStocks[data.product.supplierStocks.length - 1]
          .supplier.id;
    }
    modal.present({
      title: "Create Stock Delivery",
      children: (
        <SupplierStockDeliveryForm
          initData={{ supplierId, product: data.product }}
          complete={modal.hide}
        />
      ),
    });
  }

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <NavigationBar
        backTo={`./..?filterSupplierId=${filterSupplierId}`}
        title={`[${data.product.number}] ${data.product.name}`}
        rightButtons={
          <CharlesButton onClick={createStockDelivery}>
            + Create Stock Delivery
          </CharlesButton>
        }
      />
      <div className="flex-1 overflow-auto bg-white whitespace-nowrap">
        <ProductStocksTableView product={data.product} />
      </div>
    </div>
  );
};

export default ProductStock;
