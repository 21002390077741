import { useQuery } from "@apollo/client";
import { Select } from "components/Form";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { useState } from "react";
import { searchByProp } from "utils/search";
import { useModals } from "ModalProvider";
import ImagesPreview from "./ImagesPreview";
import { FETCH_COLLECTION } from "./graphql";
import ProductLineView from "./ProductLineView";

const PRODUCT_CATEGORIES = [
  "Catalog 2025",
  "Catalog 2024",
  "Category 2023",
  "2022 category",
];

const BizBeautyshots = () => {
  const [category, setCategory] = useState(PRODUCT_CATEGORIES[0]);
  const { loading, error, data } = useQuery(FETCH_COLLECTION, {
    variables: { name: category },
  });
  const [searchText, setSearchText] = useState("");

  const previewModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const lines = data.anyCollection.descendants
    .flatMap((i) => i.productLines)
    .filter((i) => i.activeForBiz && searchByProp(i, ["name"], searchText));

  return (
    <div>
      <div className="p-6">
        <div className="flex space-x-6 items-center">
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {PRODUCT_CATEGORIES.map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
          <div className="flex-1">
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              placeholder="Search by Name."
            />
          </div>
        </div>
      </div>
      <div className="px-6 opacity-70 leading-relaxed">
        <p>This page is for managing beautyshots for BIZ.</p>
        <p>
          We store our initial files in One Drive, a shred folder{" "}
          <a
            href="https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/wis/beautyshots/biz/"
            target="_blank"
            rel="noreferer"
          >
            Waboba / Documents / wis / beautyshots / biz
          </a>
          .
        </p>
        <p>
          The images list in this page are the images in wis. These images will
          be shown in BIZ product pages.
        </p>
        <p>
          You can sync One Drive images to wis simply by clicking the `Sync from
          One Drive to WIS` button.
        </p>
        <p>You can drap and drop to sort the images in a percific order. </p>
        <p>
          You can also double click an image to mark as a primary image, which
          it shows as the product page right side from the description text. In
          most case you set a package image as the primary one.
        </p>
      </div>
      <div>
        {lines.map((i, index) => (
          <ProductLineView productLine={i} key={index} stage={"biz"} />
        ))}
      </div>
    </div>
  );
};

export default BizBeautyshots;
