import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { Select } from "components/Form";
import ImageLoader from "components/Image";
import Spinner from "components/Spinner";
import { useState } from "react";
import { byName } from "utils/sort";

const FETCH_ALL_PRODUCT_IMAGES = gql`
  query FETCH_ALL_PRODUCT_IMAGES {
    allProducts(isActive: true, productType: "normal", hasProductLine: true) {
      id
      number
      name
      productLine {
        id
        name
        activeForWis
        newImages {
          id
        }
      }

      areas {
        id
        name
      }
      images: computedImages(size: "300x300") {
        id
        url
      }
    }
  }
`;

const WisProductImages = () => {
  const { loading, error, data } = useQuery(FETCH_ALL_PRODUCT_IMAGES);
  const [filter, setFilter] = useState("no");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const productLines = data.allProducts
    .reduce((res, product) => {
      if (res.find((l) => l.id === product.productLine.id)) {
        return res;
      } else {
        return [...res, product.productLine];
      }
    }, [])
    .filter((line) => line.activeForWis)
    .map((line) => ({
      ...line,
      products: data.allProducts.filter((i) => i.productLine.id === line.id),
      hasImages: line.newImages.length > 0,
    }))
    .sort(byName)
    .filter((i) => (filter === "no" ? !i.hasImages : true));

  const numberOfProductLineWithoutImages = productLines.filter(
    (i) => !i.hasImages,
  ).length;

  return (
    <div>
      <div className="px-6 py-3 flex justify-between items-center">
        <div className="opacity-70 italic">
          These images are use in the main products page in WIS and custom form.{" "}
          {numberOfProductLineWithoutImages > 0 ? (
            <b className="text-red-600">
              {numberOfProductLineWithoutImages} product line(s) has no images.
            </b>
          ) : (
            <b className="text-green-600">Nice, all products has images!</b>
          )}
        </div>

        <div className="flex items-center space-x-2">
          <label htmlFor="">Filter: </label>
          <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="no">Products without Images</option>
            <option value="all">All Products</option>
          </Select>
        </div>
      </div>

      {productLines.map((line) => (
        <div
          key={line.id}
          className={`py-4 border-b px-6
                ${line.hasImages ? "" : " bg-pink-300"}
            `}
        >
          <h4>{line.name}</h4>
          <div className="mt-2 hidden">
            {line.products.map((product) => (
              <div key={product.id} className="py-2">
                <div>
                  [{product.number}] {product.name}
                </div>
                <div className="flex overflow-auto mt-2">
                  {product.images.map((i) => (
                    <div key={i.id}>
                      <ImageLoader size="w-20 h-20" src={i.url} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WisProductImages;
