import { useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Status from "components/Status";

const ProductShipmentsView = ({ shipments, editShipment }) => {
  return (
    <div>
      <div className="-mx-2 whitespace-nowrap">
        <table>
          <tbody>
            {shipments.map((i, index) => (
              <tr key={index} className="border-y dark:border-gray-700">
                <td className="w-full">
                  <CharlesButton onClick={() => editShipment(i)}>
                    {i.name}
                  </CharlesButton>
                </td>
                <td className="text-right ">
                  <Status status={i.state} />
                </td>
                <td className="text-right">{i.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ProductShipmentsView;
