import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import { Text } from "../../../../components/Form.js";
import { Button } from "../../../../components/base.js";
import { SEND_SHIPMENT_ERROR_CUSTOMER_MESSAGE } from "../../graphql";
import ShipmentContext from "../ShipmentContext";

const createMessage = (shipment, shipmentStatusError) => `Dear Customer,

We are sorry to inform you that your current order(${shipment.shipmentInvoices
  .map((i) => i.number)
  .join(" / ")}) will be delayed for xx days.
Original ETD=${formatDate(shipment.etd * 1000, "YYYY-MM-DD")}, ETA=${formatDate(
  shipment.eta * 1000,
  "YYYY-MM-DD",
)}
New ETD=${formatDate(
  shipmentStatusError.newEtd * 1000,
  "YYYY-MM-DD",
)}, ETA=${formatDate(shipmentStatusError.newEta * 1000, "YYYY-MM-DD")}

If you want to know more details, please contact sharon@waboba.com.`;

function NotifyCustomerErrorForm({
  shipmentStatusId,
  shipmentStatusError,
  hideForm,
}) {
  const { state, dispatch } = useContext(ShipmentContext);

  const [message, setMessage] = useState(
    createMessage(state, shipmentStatusError),
  );

  const [sendShipmentErrorCustomerMessage, { loading }] = useMutation(
    SEND_SHIPMENT_ERROR_CUSTOMER_MESSAGE,
    {
      onCompleted: ({ sendShipmentErrorCustomerMessage }) => {
        dispatch({
          type: "sendShipmentErrorCustomerMessage",
          payload: {
            shipmentStatusId,
            shipmentStatusErrorId: shipmentStatusError.id,
            newShipmentStatusErrorCustomerMessage:
              sendShipmentErrorCustomerMessage.shipmentErrorCustomerMessage,
          },
        });
        hideForm();
      },
    },
  );

  return (
    <React.Fragment>
      <Text
        autoFocus
        placeholder="Message for customer"
        rows={3}
        async={true}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="mt-2 lg:mt-4 lg:flex">
        <Button
          size="sm"
          title={
            loading ? "Sending notification..." : "Send an Email Notification"
          }
          border
          color="blue"
          disabled={loading}
          loading={loading}
          onClick={(_) => {
            sendShipmentErrorCustomerMessage({
              variables: {
                shipmentErrorId: shipmentStatusError.id,
                message: message,
              },
            });
          }}
        />
        {!loading && (
          <Button
            size="sm"
            title="Cancel"
            className="mt-2 lg:mt-0 lg:ml-4"
            border
            onClick={hideForm}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default NotifyCustomerErrorForm;
