import { useMutation, useQuery } from "@apollo/client";
import { CharlesButton } from "components/charles/base";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { BsStarFill } from "react-icons/bs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useModals } from "ModalProvider";
import { parseError } from "apollo";
import {
  SET_PRODUCT_LINE_IMAGE_PRIMARY,
  SORT_PRODUCT_LINE_IMAGES,
  SYNC_PRODUCT_LINE_IMAGES,
} from "./graphql";

const ProductLineView = ({ productLine, stage }) => {
  const images = productLine.newImages.filter((i) => i.stage === stage);

  const [setPrimary, setPrimaryRes] = useMutation(
    SET_PRODUCT_LINE_IMAGE_PRIMARY,
    {
      onCompleted() {
        Alert("success", "New Primary Image Set.");
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );

  const [sortImages, sortImagesRes] = useMutation(SORT_PRODUCT_LINE_IMAGES, {
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const [syncImages, syncImagesRes] = useMutation(SYNC_PRODUCT_LINE_IMAGES, {
    variables: { slug: productLine.slug },
    onCompleted() {
      Alert("success", "Images Synced.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const ordering = reorder(
      images,
      result.source.index,
      result.destination.index,
    ).map((i, index) => ({ id: i.id, ordering: index + 1 }));
    sortImages({ variables: { ordering } });
  }

  return (
    <div className="border-b dark:border-gray-700 pb-4">
      <h4 className="p-6">{productLine.name}</h4>

      {images.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided) => (
              <div
                className="flex ml-6 mr-2 relative overflow-auto"
                ref={provided.innerRef}
              >
                {images.map((i, index) => (
                  <Draggable key={i.id} draggableId={i.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="relative"
                        onDoubleClick={() =>
                          setPrimary({
                            variables: {
                              productLineId: productLine.id,
                              productLineImageId: i.id,
                              stage,
                            },
                          })
                        }
                      >
                        {i.isPrimary ? (
                          <div className="absolute text-yellow-500 text-3xl top-2 left-2">
                            <BsStarFill />
                          </div>
                        ) : null}
                        <div className="w-36 mr-4 mb-4">
                          <img src={i.thumb} alt={i.name} />

                          <div className="break-all w-full text-xs text-center mt-2">
                            {i.name}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}

                {setPrimaryRes.loading || sortImagesRes.loading ? (
                  <Spinner text="Processing..." />
                ) : null}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className="px-6 opacity-70">No beautyshot for this item yet.</div>
      )}

      <div className="px-6 mt-4 opacity-70">
        One Drive folder name should be: {productLine.slug}.
      </div>
      <div className="p-6 pb-0 flex space-x-6">
        <a
          rel="noreferer"
          target="_blank"
          href={`https://wabobaadmin.sharepoint.com/sites/Waboba/Shared%20Documents/wis/beautyshots/${stage}/${productLine.slug}`}
        >
          Open One Drive Folder
        </a>
        <CharlesButton
          onClick={() => {
            if (
              window.confirm(
                "Are you sure to sync images from One Drive to WIS?",
              )
            ) {
              syncImages();
            }
          }}
          loading={syncImagesRes.loading}
        >
          Sync from One Drive to WIS
        </CharlesButton>
      </div>
    </div>
  );
};

export default ProductLineView;
